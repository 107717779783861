import Vue from 'vue'
import VueRouter from 'vue-router'
import Report2g from '../views/Report2g.vue';
import Report4g from '../views/Report4g.vue';
import Report7Date from '../views/Report7Date.vue';
import Report30Date from '../views/Report30Date.vue';
import Notice from '../views/Notice.vue';
import ArticleList from '../views/Article.vue';
import Xueya from '../views/Xueya.vue';
import Xuetang from '../views/Xuetang.vue';
import Hongwai from '../views/Hongwai.vue';
import Xikailu from '../views/Xikailu.vue';
import Report14Date from '../views/Report14Date.vue';

import Manxing from "../views/mb.vue";
import Immunity from "../views/Immunity.vue";
import Feeling from "../views/Feeling.vue";
import Infection from "../views/Infection.vue";


import Sleep from "../views/sleep.vue";
import SleepDetail from "../views/SleepDetail.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/report2g',
    name: 'Report2GDay',
    component: Report2g,
    meta: {
      title: ''
    }
  },
  {
    path: '/notice',
    name: 'Notice',
    component: Notice,
    meta: {
      title: ''
    }
  },
  {
    path: '/report4g',
    name: 'Report4GDay',
    component: Report4g,
    meta: {
      title: ''
    }
  },
  {
    path: '/sleep',
    name: 'Sleep',
    component: Sleep,
    meta: {
      title: ''
    }
  },
  {
    path: '/sleepDetail',
    name: 'SleepDetail',
    component: SleepDetail,
    meta: {
      title: ''
    }
  },
  {
    path: '/mb',
    name: 'Manxing',
    component: Manxing,
    meta: {
      title: ''
    }
  },
  {
    path: '/Immunity',
    name: 'Immunity',
    component: Immunity,
    meta: {
      title: ''
    }
  },
  {
    path: '/feeling',
    name: 'Feeling',
    component: Feeling,
    meta: {
      title: ''
    }
  },
  {
    path: '/infection',
    name: 'Infection',
    component: Infection,
    meta: {
      title: ''
    }
  },
  
  {
    path: '/report14',
    name: 'Report14Date',
    component: Report14Date,
    meta: {
      title: ''
    }
  },
  {
    path: '/xueya',
    name: 'Xueya',
    component: Xueya,
    meta: {
      title: ''
    }
  },
  {
    path: '/xuetang',
    name: 'Xuetang',
    component: Xuetang,
    meta: {
      title: ''
    }
  },
  {
    path: '/hongwai',
    name: 'Hongwai',
    component: Hongwai,
    meta: {
      title: ''
    }
  },
  {
    path: '/xkl',
    name: 'Xikailu',
    component: Xikailu,
    meta: {
      title: ''
    }
  },
  {
    path: '/report-weekly',
    name: 'Report7Date',
    component: Report7Date,
    meta: {
      title: ''
    }
  },
  {
    path: '/report-month',
    name: 'Report30Date',
    component: Report30Date,
    meta: {
      title: ''
    }
  },
  {
    path: '/article',
    name: 'ArticleList',
    component: ArticleList,
    meta: {
      title: '健康辞典'
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  window.document.title = to.meta.title == undefined ? '' : to.meta.title;
  next();
})



export default router
