export function whereStr(code, xmwhere, dic, fn) {
  var field = code
  var dicvalue = dic[field]
  if ('sleepValue' == field) {
    dicvalue = Math.round(dicvalue)
  }
  if (!dicvalue) {
    var fields = field.match(/\w+/g)
    let fieldValue = field
    fields.forEach(f => {
      var qdicValue = dic[f]
      fieldValue = fieldValue.replace(f, qdicValue)
    })
    dicvalue = eval(fieldValue)
  }
  var qbds = xmwhere
    .replace(/\[/, 'x>=')
    .replace(',', '&&')
    .replace(')', '>x')
    .replace(']', '>=x')
    .replace(/x/g, dicvalue)
    .replace('∞>', `${dicvalue + 1}>`)
    .replace('∞=', `${dicvalue}=`)
    .replace(field, dicvalue)

  try {
    var bo = eval(qbds)
    if (bo) {
      fn(dicvalue)
    }
  } catch (e) { }
}

export function quickSort(arr, k) {
  if (arr.length <= 1) {
    return arr
  }
  var pivotIndex = Math.floor(arr.length / 2)
  var pivot = arr.splice(pivotIndex, 1)[0]
  var left = []
  var right = []
  for (var i = 0; i < arr.length; i++) {
    if (Number(arr[i][k]) < Number(pivot[k])) {
      left.push(arr[i])
    } else {
      right.push(arr[i])
    }
  }
  return quickSort(left, k).concat([pivot], quickSort(right, k))
}


export function Sc_tst(x) {
  var sm = (x / 60 + '').split('.')[0]
  var fen = ((x % 60) + '').split('.')[0]
  return `${sm}h${fen}min`
}


export function param2Obj(a) {
  var url = a ? a : location.href
  var sssplit = url.split('?')
  if (!sssplit) {
    return {}
  }
  var d = {}
  for (var i = 0; i < sssplit.length; i++) {
    var ss = sssplit[i]
    var s = ss.split('&')
    s.forEach(u => {
      var p = u.split('=');
      if(p.length==2){
        d[p[0]] = p[1]
      }
      
    })
  }
  return d
}
