import Vue from 'vue'
import App from './App.vue'
import router from './router'

import echarts from 'echarts'
import $ from 'jquery'

import axios from 'axios'
Vue.prototype.$axios = axios

import 'amfe-flexible'

// indexedDB数据库
import indexedDB from '@/utils/indexedDB.js';

Vue.prototype.$indexedDB = indexedDB;

//数据库 ，数据表 ，版本
indexedDB.openDB('report', 'myReport', 1).then((res) => {
  window.$db = res;
  // 页面单独请求使用
  Vue.prototype.$db = res;
});


Vue.prototype.$echarts = echarts


Vue.config.productionTip = false

import '@/assets/css/common.css';


setTimeout(() => {
  new Vue({
    router,
    render: h => h(App)
  }).$mount('#app')
}, 0)

