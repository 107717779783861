<template>
  <div>
    <div class="heart-beat_content">
      <div v-if="d.hrv === '1'" class="item" @click="handleClickDetail('心脏总能量')">
        <h4>心脏总能量</h4>
        <h3 :style="{ color: UserBaseInfoCof.color }">
          {{ d.TP }}<span>{{ UserBaseInfoCof.text }}</span>
        </h3>
        <p>参考范围{{ HeartIndexpar }}</p>
      </div>
      <div v-if="d.hrv === '0'" class="item" @click="handleClickDetail('心脏总能量')">
        <h4>心脏总能量</h4>
        <h3>- -</h3>
        <p>参考范围{{ HeartIndexpar }}</p>
      </div>
      <div class="item" @click="handleClickDetail('当次基准心率与长期基准心率')">
        <h4>基准心率</h4>
        <h3 :style="{ color: ReportOfHeartBeatCof.color }">
          {{ d.hr }}<span>{{ ReportOfHeartBeatTextCof.text }}</span>
        </h3>
        <p>
          长期基准心率<span :style="{ color: ReportOfHeartBeatLCof.color }">{{
            d.lhr
          }}</span>
        </p>
      </div>
    </div>
    <van-action-sheet v-model="isActionShow" :title="actionTitle">
      <div class="action-content" v-html="actionText"></div>
    </van-action-sheet>
  </div>
</template>

<script>
import baseConfig from "@/base";
import Vue from "vue";
import { Toast, ActionSheet } from "vant";

Vue.use(Toast);
Vue.use(ActionSheet);
export default {
  name: "TPValue",
  data() {
    return {
      isActionShow: false,
      actionTitle: "",
      actionText: "",
      d: {},
      HeartIndexpar: "", //心脏总能量范围
      UserBaseInfoCof: {},
      ReportOfHeartBeatCof: {},
      ReportOfHeartBeatLCof: {},
      ReportOfHeartBeatTextCof: {},
    };
  },
  methods: {
    //初始化 d为报告信息
    init(d) {
      this.d = d;
      if (d.reportCount > 0) {
        var ch = baseConfig.reportConfig.tpwhere.replace(
          "reportCount",
          d.reportCount
        );
        var chbo = eval(ch);
        if (chbo) {
          this.HeartIndexpar = `${(d.BaseTP * 0.75).toFixed(0)}-${(
            d.BaseTP * 1.5
          ).toFixed(0)}`;
        } else {
          this.HeartIndexpar = baseConfig.reportConfig.tpdefault;
        }
        var tpText = baseConfig.reportConfig.tpText;
        tpText.forEach((x) => {
          var tcbo = x.where.replace(/reportCount/g, d.reportCount);
          if (eval(tcbo)) {
            x.table.forEach((t) => {
              var tbo = t.where
                .replace(/BaseTP/g, d.BaseTP)
                .replace(/TP/g, d.TP);
              if (eval(tbo)) {
                this.UserBaseInfoCof = t;
              }
            });
          }
        });
      }
      var hrText = baseConfig.reportConfig.hrText;
      hrText.forEach((x) => {
        var Benchmark = x.where.replace(/hr/g, d.hr);
        if (eval(Benchmark)) {
          this.ReportOfHeartBeatCof = x;
        }
      });

      var lhrText = baseConfig.reportConfig.lhrText;
      lhrText.forEach((x) => {
        var Benchmark = x.where.replace(/lhr/g, d.lhr);
        if (eval(Benchmark)) {
          this.ReportOfHeartBeatLCof = x;
        }
      });
      var hrlhrText = baseConfig.reportConfig.hrlhrText;
      hrlhrText.forEach((x) => {
        let Benchmark = x.where.replace(/lhr/g, d.lhr).replace(/hr/g, d.hr);
        if (eval(Benchmark)) {
          this.ReportOfHeartBeatTextCof = x;
        }
      });
    },
    //action-sheet 
    handleClickDetail(title) {
      this.actionTitle = title;
      this.isActionShow = true;
      this.actionText = baseConfig.baseConfig[title].text;
    },
  },
};
</script>

<style lang="less" scoped>
.heart-beat_content {
  margin-top: 6px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
  .item {
    padding: 10px 6px;
    text-align: center;
    background: #fff;
    border-radius: 10px;
    h4 {
      font-size: 14px;
      color: #343433;
    }
    h3 {
      padding: 2px 0;
      font-size: 18px;
      color: #178355;
      > span {
        font-weight: 400;
        font-size: 14px;
      }
    }
    > p {
      font-weight: 400;
      color: #579e7b;
      font-size: 12px;
    }
  }
}
</style>