<template>
  <div class="health-circle">
    <!-- 进度条 -->
    <van-circle
      v-model="healthCount"
      :rate="healthCount"
      :color="gradientColor"
      stroke-width="100"
      size="120px"
      :speed="100"
    />
  </div>
</template>

<script>
import Vue from "vue";
import { Circle } from "vant";

Vue.use(Circle);
export default {
  name: "HealthCircle",
  data() {
    return {};
  },
  props: {
    healthCount: {
        type: Number,
        default: 0
    },
    gradientColor: {
      type: Object,
      default: {
        "0%": "rgba(145, 255, 97, .8)",
        "100%": "#7DF018",
      }
    }
  }
};
</script>