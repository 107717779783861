<template>
  <div class="main-container">
    <div class="banner-wrapper">
      <h2 class="title">健康报告评估</h2>
      <p class="report-time">Health Assessment Report</p>
      <div class="user-info">
        <div class="avatar-img">
          <img :src="require('@/assets/images/logo.png')" alt="" />
        </div>
        <p class="user-name">{{userName}}</p>
      </div>
    </div>
    <div class="report-wrapper">
      <div class="health-guide">-本次功能评估报告仅对本次的数字信息负责-</div>
      <!-- 基本信息 -->
      <div class="report-detail">
        <!-- 血常规数据 -->
        <div class="item">
          <h3>
            血常规
            <span class="score" v-if="scoreList.length">{{scoreList[0].TypeHealthScore }}分</span>
          </h3>
          <table class="data-table">
            <tr>
              <th class="params">参数</th>
              <th class="standard">标准</th>
              <th>数值</th>
            </tr>
            <tr v-for="(item, index) in sliceReportList1" :key="item.id">
              <td>{{ item.Title }}</td>
              <td>{{ userSex ? item.NormalNv : item.Normal }}</td>
              <td :class="`status${item.Status}`" @click="handleExpendContent(item.Title,item.ReportItemID,item.Status)">{{ item.ProjectValue }}</td>
            </tr>
          </table>
        </div>
        <!-- 水及电解质代谢 -->
        <div class="item">
          <h3>
            水及电解质代谢
            <span class="score" v-if="scoreList.length">{{scoreList[1].TypeHealthScore }}分</span>
          </h3>
          <table class="data-table">
            <tr>
              <th class="params">参数</th>
              <th class="standard">标准</th>
              <th>数值</th>
            </tr>
            <tr v-for="(item, index) in sliceReportList2" :key="item.id">
              <td>{{ item.Title }}</td>
              <td>{{ userSex ? item.NormalNv : item.Normal }}</td>
              <td :class="`status${item.Status}`" @click="handleExpendContent(item.Title,item.ReportItemID,item.Status)">{{ item.ProjectValue }}</td>
            </tr>
          </table>
        </div>
        <!-- 血液凝集系统 -->
        <div class="item">
          <h3>
            血液凝集系统
            <span class="score" v-if="scoreList.length">{{scoreList[2].TypeHealthScore }}分</span>
          </h3>
          <table class="data-table">
            <tr>
              <th class="params">参数</th>
              <th class="standard">标准</th>
              <th>数值</th>
            </tr>
            <tr v-for="(item, index) in sliceReportList3" :key="item.id">
              <td>{{ item.Title }}</td>
              <td>{{ userSex ? item.NormalNv : item.Normal }}</td>
              <td :class="`status${item.Status}`" @click="handleExpendContent(item.Title,item.ReportItemID,item.Status)">{{ item.ProjectValue }}</td>
            </tr>
          </table>
        </div>
        <!-- 肺等相关指标 -->
        <div class="item">
          <h3>
            肺等相关指标
            <span class="score" v-if="scoreList.length">{{scoreList[3].TypeHealthScore }}分</span>
          </h3>
          <table class="data-table">
            <tr>
              <th class="params">参数</th>
              <th class="standard">标准</th>
              <th>数值</th>
            </tr>
            <tr v-for="(item, index) in sliceReportList4" :key="item.id">
              <td>{{ item.Title }}</td>
              <td>{{ userSex ? item.NormalNv : item.Normal }}</td>
              <td :class="`status${item.Status}`" @click="handleExpendContent(item.Title,item.ReportItemID,item.Status)">{{ item.ProjectValue }}</td>
            </tr>
          </table>
        </div>
        <!-- 氧气转运及利用 -->
        <div class="item">
          <h3>
            氧气转运及利用
            <span class="score" v-if="scoreList.length">{{scoreList[4].TypeHealthScore }}分</span>
          </h3>
          <table class="data-table">
            <tr>
              <th class="params">参数</th>
              <th class="standard">标准</th>
              <th>数值</th>
            </tr>
            <tr v-for="(item, index) in sliceReportList5" :key="item.id">
              <td>{{ item.Title }}</td>
              <td>{{ userSex ? item.NormalNv : item.Normal }}</td>
              <td :class="`status${item.Status}`" @click="handleExpendContent(item.Title,item.ReportItemID,item.Status)">{{ item.ProjectValue }}</td>
            </tr>
          </table>
        </div>
        <!-- 心脏及其动力学指标 -->
        <div class="item">
          <h3>
            心脏及其动力学指标
            <span class="score" v-if="scoreList.length">{{scoreList[5].TypeHealthScore }}分</span>
          </h3>
          <table class="data-table">
            <tr>
              <th class="params">参数</th>
              <th class="standard">标准</th>
              <th>数值</th>
            </tr>
            <tr v-for="(item, index) in sliceReportList6" :key="item.id">
              <td>{{ item.Title }}</td>
              <td>{{ userSex ? item.NormalNv : item.Normal }}</td>
              <td :class="`status${item.Status}`" @click="handleExpendContent(item.Title,item.ReportItemID,item.Status)">{{ item.ProjectValue }}</td>
            </tr>
          </table>
        </div>
        <!-- 肝、胆、胃等相关指标 -->
        <div class="item">
          <h3>
            肝、胆、胃等相关指标
            <span class="score" v-if="scoreList.length">{{scoreList[6].TypeHealthScore }}分</span>
          </h3>
          <table class="data-table">
            <tr>
              <th class="params">参数</th>
              <th class="standard">标准</th>
              <th>数值</th>
            </tr>
            <tr v-for="(item, index) in sliceReportList7" :key="item.id">
              <td>{{ item.Title }}</td>
              <td>{{ userSex ? item.NormalNv : item.Normal }}</td>
              <td :class="`status${item.Status}`" @click="handleExpendContent(item.Title,item.ReportItemID,item.Status)">{{ item.ProjectValue }}</td>
            </tr>
          </table>
        </div>
        <!-- 肾脏相关指标 -->
        <div class="item">
          <h3>
            肾脏相关指标
            <span class="score" v-if="scoreList.length">{{scoreList[7].TypeHealthScore }}分</span>
          </h3>
          <table class="data-table">
            <tr>
              <th class="params">参数</th>
              <th class="standard">标准</th>
              <th>数值</th>
            </tr>
            <tr v-for="(item, index) in sliceReportList8" :key="item.id">
              <td>{{ item.Title }}</td>
              <td>{{ userSex ? item.NormalNv : item.Normal }}</td>
              <td :class="`status${item.Status}`" @click="handleExpendContent(item.Title,item.ReportItemID,item.Status)">{{ item.ProjectValue }}</td>
            </tr>
          </table>
        </div>
        <!-- 生殖系统 -->
        <div class="item">
          <h3>
            生殖系统
            <span class="score" v-if="scoreList.length">{{scoreList[8].TypeHealthScore }}分</span>
          </h3>
          <table class="data-table">
            <tr>
              <th class="params">参数</th>
              <th class="standard">标准</th>
              <th>数值</th>
            </tr>
            <tr v-for="(item, index) in sliceReportList9" :key="item.id">
              <td>{{ item.Title }}</td>
              <td>{{ userSex ? item.NormalNv : item.Normal }}</td>
              <td :class="`status${item.Status}`" @click="handleExpendContent(item.Title,item.ReportItemID,item.Status)">{{ item.ProjectValue }}</td>
            </tr>
          </table>
        </div>
        <!-- 内分泌系统 -->
        <div class="item">
          <h3>
            内分泌系统
            <span class="score" v-if="scoreList.length">{{scoreList[9].TypeHealthScore }}分</span>
          </h3>
          <table class="data-table">
            <tr>
              <th class="params">参数</th>
              <th class="standard">标准</th>
              <th>数值</th>
            </tr>
            <tr v-for="(item, index) in sliceReportList10" :key="item.id">
              <td>{{ item.Title }}</td>
              <td>{{ userSex ? item.NormalNv : item.Normal }}</td>
              <td :class="`status${item.Status}`" @click="handleExpendContent(item.Title,item.ReportItemID,item.Status)">{{ item.ProjectValue }}</td>
            </tr>
          </table>
        </div>
        <!-- 糖代谢 -->
        <div class="item">
          <h3>
            糖代谢
            <span class="score" v-if="scoreList.length">{{scoreList[10].TypeHealthScore }}分</span>
          </h3>
          <table class="data-table">
            <tr>
              <th class="params">参数</th>
              <th class="standard">标准</th>
              <th>数值</th>
            </tr>
            <tr v-for="(item, index) in sliceReportList11" :key="item.id">
              <td>{{ item.Title }}</td>
              <td>{{ userSex ? item.NormalNv : item.Normal }}</td>
              <td :class="`status${item.Status}`" @click="handleExpendContent(item.Title,item.ReportItemID,item.Status)">{{ item.ProjectValue }}</td>
            </tr>
          </table>
        </div>
        <!-- 脂代谢 -->
        <div class="item">
          <h3>
            脂代谢
            <span class="score" v-if="scoreList.length">{{scoreList[11].TypeHealthScore }}分</span>
          </h3>
          <table class="data-table">
            <tr>
              <th class="params">参数</th>
              <th class="standard">标准</th>
              <th>数值</th>
            </tr>
            <tr v-for="(item, index) in sliceReportList12" :key="item.id">
              <td>{{ item.Title }}</td>
              <td>{{ userSex ? item.NormalNv : item.Normal }}</td>
              <td :class="`status${item.Status}`" @click="handleExpendContent(item.Title,item.ReportItemID,item.Status)">{{ item.ProjectValue }}</td>
            </tr>
          </table>
        </div>
        <!-- 情绪指数（快乐指数） -->
        <div class="item">
          <h3>
            情绪指数（快乐指数）
            <span class="score" v-if="scoreList.length">{{scoreList[12].TypeHealthScore }}分</span>
          </h3>
          <table class="data-table">
            <tr>
              <th class="params">参数</th>
              <th class="standard">标准</th>
              <th>数值</th>
            </tr>
            <tr v-for="(item, index) in sliceReportList13" :key="item.id">
              <td>{{ item.Title }}</td>
              <td>{{ userSex ? item.NormalNv : item.Normal }}</td>
              <td :class="`status${item.Status}`" @click="handleExpendContent(item.Title,item.ReportItemID,item.Status)">{{ item.ProjectValue }}</td>
            </tr>
          </table>
        </div>
        <!-- 大脑及其相关指标 -->
        <div class="item">
          <h3>
            大脑及其相关指标
            <span class="score" v-if="scoreList.length">{{scoreList[13].TypeHealthScore }}分</span>
          </h3>
          <table class="data-table">
            <tr>
              <th class="params">参数</th>
              <th class="standard">标准</th>
              <th>数值</th>
            </tr>
            <tr v-for="(item, index) in sliceReportList14" :key="item.id">
              <td>{{ item.Title }}</td>
              <td>{{ userSex ? item.NormalNv : item.Normal }}</td>
              <td :class="`status${item.Status}`" @click="handleExpendContent(item.Title,item.ReportItemID,item.Status)">{{ item.ProjectValue }}</td>
            </tr>
          </table>
        </div>
        <!-- 运动指标 -->
        <div class="item">
          <h3>
            运动指标
            <span class="score" v-if="scoreList.length">{{scoreList[14].TypeHealthScore }}分</span>
          </h3>
          <table class="data-table">
            <tr>
              <th class="params">参数</th>
              <th class="standard">标准</th>
              <th>数值</th>
            </tr>
            <tr v-for="(item, index) in sliceReportList15" :key="item.id">
              <td>{{ item.Title }}</td>
              <td>{{ userSex ? item.NormalNv : item.Normal }}</td>
              <td :class="`status${item.Status}`" @click="handleExpendContent(item.Title,item.ReportItemID,item.Status)">{{ item.ProjectValue }}</td>
            </tr>
          </table>
        </div>
        <!-- 免疫力活性指标 -->
        <div class="item">
          <h3>
            免疫力活性指标
            <span class="score" v-if="scoreList.length">{{scoreList[15].TypeHealthScore }}分</span>
          </h3>
          <table class="data-table">
            <tr>
              <th class="params">参数</th>
              <th class="standard">标准</th>
              <th>数值</th>
            </tr>
            <tr v-for="(item, index) in sliceReportList16" :key="item.id">
              <td>{{ item.Title }}</td>
              <td>{{ userSex ? item.NormalNv : item.Normal }}</td>
              <td :class="`status${item.Status}`" @click="handleExpendContent(item.Title,item.ReportItemID,item.Status)">{{ item.ProjectValue }}</td>
            </tr>
          </table>
        </div>
        <!-- 皮肤测试指标 -->
        <div class="item">
          <h3>
            皮肤测试指标
            <span class="score" v-if="scoreList.length">{{scoreList[16].TypeHealthScore }}分</span>
          </h3>
          <table class="data-table">
            <tr>
              <th class="params">参数</th>
              <th class="standard">标准</th>
              <th>数值</th>
            </tr>
            <tr v-for="(item, index) in sliceReportList17" :key="item.id">
              <td>{{ item.Title }}</td>
              <td>{{ userSex ? item.NormalNv : item.Normal }}</td>
              <td :class="`status${item.Status}`" @click="handleExpendContent(item.Title,item.ReportItemID,item.Status)">{{ item.ProjectValue }}</td>
            </tr>
          </table>
        </div>
        <!-- 健康透支指标 -->
        <div class="item">
          <h3>
            健康透支指标
            <span class="score" v-if="scoreList.length">{{scoreList[17].TypeHealthScore }}分</span>
          </h3>
          <table class="data-table">
            <tr>
              <th class="params">参数</th>
              <th class="standard">标准</th>
              <th>数值</th>
            </tr>
            <tr v-for="(item, index) in sliceReportList18" :key="item.id">
              <td>{{ item.Title }}</td>
              <td>{{ userSex ? item.NormalNv : item.Normal }}</td>
              <td :class="`status${item.Status}`" @click="handleExpendContent(item.Title,item.ReportItemID,item.Status)">{{ item.ProjectValue }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <van-action-sheet v-model="isActionShow" :title="actionTitle">
      <div class="action-content" v-html="actionText"></div>
    </van-action-sheet>
  </div>
</template>

<script>
import dayjs from "dayjs";
import Vue from "vue";
import { Icon, Toast, ActionSheet } from "vant";
Vue.use(Icon);
Vue.use(Toast);
Vue.use(ActionSheet);
export default {
  name: "ReportDetail",
  data() {
    return {
      isActionShow: false,
      actionTitle: "",
      actionText: "",
      reportTime: "",
      userName: "",
      userSex: 0, //默认男
      reportDetail: {
        info: {},
        list: [],
      },
      scoreList: []
    };
  },
  computed: {
    // 血常规
    sliceReportList1() {
      return this.reportDetail.list.slice(11, 26);
    },
    // 水及电解质代谢
    sliceReportList2() {
      return this.reportDetail.list.slice(26, 34);
    },
    // 血液凝集系统
    sliceReportList3() {
      return this.reportDetail.list.slice(34, 38);
    },
    // 肺等相关指标
    sliceReportList4() {
      return this.reportDetail.list.slice(38, 48);
    },
    // 氧气转运及利用
    sliceReportList5() {
      return this.reportDetail.list.slice(48, 56);
    },
    // 心脏及其动力学指标
    sliceReportList6() {
      return this.reportDetail.list.slice(56, 73);
    },
    // 肝、胆、胃等相关指标
    sliceReportList7() {
      return this.reportDetail.list.slice(73, 87);
    },
    // 肾脏相关指标
    sliceReportList8() {
      return this.reportDetail.list.slice(87, 95);
    },
    // 生殖系统
    sliceReportList9() {
      return this.reportDetail.list.slice(95, 99);
    },
    // 内分泌系统
    sliceReportList10() {
      return this.reportDetail.list.slice(99, 102);
    },
    // 糖代谢
    sliceReportList11() {
      return this.reportDetail.list.slice(102, 106);
    },
    // 脂代谢
    sliceReportList12() {
      return this.reportDetail.list.slice(106, 114);
    },
    // 情绪指数（快乐指数）
    sliceReportList13() {
      return this.reportDetail.list.slice(114, 115);
    },
    // 大脑及其相关指标
    sliceReportList14() {
      return this.reportDetail.list.slice(115, 124);
    },
    // 运动指标
    sliceReportList15() {
      return this.reportDetail.list.slice(124, 129);
    },
    // 免疫力活性指标
    sliceReportList16() {
      return this.reportDetail.list.slice(129, 131);
    },
    // 皮肤测试指标
    sliceReportList17() {
      return this.reportDetail.list.slice(131, 133);
    },
    // 健康透支指标
    sliceReportList18() {
      return this.reportDetail.list.slice(133, 136);
    },
  },
  methods: {
    //获取喜开路报告详情
    getXKLReportDetail() {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      this.$axios
        .get(
          "https://health.ruinetworks.com/api/xkl/report/detail",
          {
            params: {
              id: this.$route.query.id,
            },
            headers: {
              user_token: this.$route.query.token,
            },
          }
        )
        .then((res) => {
          Toast.clear();
          if (res.data.success == "true") {
            this.userName = res.data.data.report.userName;
            const reportDetail = JSON.parse(res.data.data.report.reportDetail);
            this.reportDetail.info = reportDetail[0];
            this.userSex = this.reportDetail.info.Sex == '男' ? 0 : 1;
            this.reportDetail.list = reportDetail[1];
            this.scoreList = reportDetail[2];
          } else {
            Toast(res.data.data.msg);
          }
        });
    },
    //获取扩展信息
    handleExpendContent(title,id,status) {
      this.$axios
        .get(
          "https://health.ruinetworks.com/api/xkl/reportinfo",
          {
            params: {
              ReportItemID: id,
              Status: status
            },
            headers: {
              user_token: this.$route.query.token,
            },
          }
        )
        .then((res) => {
          Toast.clear();
          if (res.data.success == "true") {
            this.actionTitle = title;
            this.actionText = res.data.data.description;
            this.isActionShow = true;
          } else {
            Toast(res.data.data.msg);
          }
        });
    }
  },
  mounted() {
    //获取报告详情
    this.getXKLReportDetail();
  },
};
</script>

<style lang="less" scoped>
.main-container {
  .banner-wrapper {
    position: relative;
    height: 120px;
    background: linear-gradient(180deg, #0cc2a9 0%, #49cdab 100%);
    border-radius: 0 0 30px 30px;
    padding: 10px 24px;
    .title {
      color: #fff;
      font-size: 22px;
      font-weight: 500;
    }
    .report-time {
      font-size: 14px;
      color: #fff;
      font-weight: 400;
      line-height: 36px;
    }
    .user-info {
      position: absolute;
      right: 20px;
      top: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .avatar-img {
        width: 38px;
        height: 38px;
        border-radius: 8px;
        background: #f2f2f2;
        overflow: hidden;
        > img {
          width: 100%;
          height: 100%;
        }
      }
      .user-name {
        margin-top: 6px;
        text-align: center;
        color: #fff;
        font-size: 18px;
        font-weight: 500;
      }
    }
  }
  .report-wrapper {
    position: relative;
    margin-top: -80px;
    padding: 15px;
    .health-guide {
      text-align: center;
      font-size: 12px;
      line-height: 36px;
      color: #fff;
    }
    .report-detail {
      padding: 10px;
      background: #fff;
      box-shadow: 0px 4px 12px 0px rgba(229, 229, 229, 0.5);
      border-radius: 12px;
      margin-bottom: 16px;
      .title {
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 16px;
        .title-icon {
          width: 24px;
          margin-right: 10px;
        }
        .health-score {
          position: absolute;
          right: 0;
          font-weight: 600;
          color: #3d854d;
        }
      }
      .item {
        background: #f8f8f8;
        margin-bottom: 16px;
        border-radius: 10px;
        padding: 12px 8px 4px;
        &:last-child {
          margin-bottom: 0;
        }
        h3 {
          font-size: 18px;
          color: #272c47;
          font-weight: 500;
          line-height: 38px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 2px solid #afeaee;
          .score {
            color: #3d854d;
            font-weight: 600;
          }
        }
        .data-table {
          margin-top: 4px;
          width: 100%;
          table-layout: fixed;
          font-size: 16px;
          > tr {
            > th {
              text-align: left;
              &.params {
                width: 45%;
              }
              &.standard {
                width: 30%;
              }
            }
            > td {
              color: #272c47;
              padding: 2px 0;
              border-bottom: 1px solid #fff;
              &:last-child {
                color: #3d854d;
                font-weight: 500;
                text-decoration: underline;
                cursor: pointer;
                &.status1 {
                  color: #2d2de8;
                }
                &.status2 {
                  color: red;
                }
              }
            }
          }
        }
      }
    }
  }
}
.mr4 {
  margin-right: 4px;
}
.action-content {
  font-size: 14px;
  padding: 10px;
  text-indent: 2em;
  /deep/ span {
    white-space: normal !important;
  }
}
</style>
