<template>
  <div>
    <van-nav-bar
      class="top-navbar"
      left-arrow
      @click-left="onClickLeft"
      title="感染风险"
    />
    <div id="grfx" class="panel__wrapper">
      <div class="am_panel">
        <p v-if="manbingDetailRedLength > 0" class="warning-tips level1">
          {{ manbingDetailRedLength }}项特别注意指标
        </p>
        <p v-if="manbingDetailYellowLength > 0" class="warning-tips level2">
          {{ manbingDetailYellowLength }}项需要注意指标
        </p>
        <div
          v-for="(m, i) in manbingDetail"
          :key="'manbingDetail' + i"
          :class="manbingDetail.length - 1 > i ? `aammm` : ``"
        >
          <slider :Immunity="m.im" ref="gr" />
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import slider from "./slider.vue";
import dayjs from "dayjs";
import Vue from "vue";
import { Icon, NavBar, Toast } from "vant";
import { whereStr, quickSort } from "@/utils/index";

Vue.use(Icon);
Vue.use(NavBar);
Vue.use(Toast);
export default {
  data() {
    return {
      reportId: "",
      manbingDetailRedLength: 0,
      manbingDetailYellowLength: 0,
      manbingDetail: [],
      createTable: "AnswerIMAD",
      serachTable: "IMAD",
      noTagTable: "IMADBET",
    };
  },
  components: { slider },
  methods: {
    //返回
    onClickLeft() {
      this.$router.go(-1);
    },
    init() {
      this.get4G7ListReport();
    },
    //获取7条报告数据
    async get4G7ListReport() {
      let { result } = await this.$indexedDB.getSingle(
        "myReport",
        this.reportId
      );

      let reportPar = result.reportPar;
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      this.$axios
        .get(
          "https://health.ruinetworks.com/report14/health/lite4g/devReportData",
          {
            params: {
              // id: "109339e2-c7b6-4ba7-9019-a6e8f3ebfabd",
              remark: "7",
              id: this.$route.query.id,
            },
            headers: {
              user_token: this.$route.query.token,
            },
          }
        )
        .then((res) => {
          Toast.clear();
          if (res.data.success == "true") {
            const d = reportPar;
            var hutudata = res.data.data;

            var pu = [];

            for (let di = 0; di < d.length; di++) {
              var x = d[di];

              if (["感染风险"].indexOf(x.type) == -1) {
                continue;
              }

              var code = x.code;
              if (code == "slr") {
              }

              if (this[code]) {
                x.v2 = this[code](x.v, hutudata[0]);
              }

              var im = {};
              im.title = x.title;
              let _score = x.v2 ? x.v2 : x.v;
              im.score = Math.floor(_score);
              im.text = x.text;
              im.color = x.color;
              im.notInit = "sleep2DetailInit";
              im.leftIco = true;
              im.showRightIco = true;
              im.canvas = true;
              im.code = code;
              im.data = [];

              var canvasData = {
                writeLeftbiao: false,
                writebiao: true,
                biao: x.baseVal ? x.baseVal : 30,
                biaoColor: "#4391ec",
                footColor: "#000000",
                leftbiao: [],
                text: [],
              };
              im.canvasData = canvasData;

              x.m = x.m.reverse();
              x.m.forEach((xm, xmi) => {
                var wher = xm.where.replace(")", "").replace("[", "");
                var mks = wher.split(",");
                var combet = wher.split(",");
                var imdata = {};

                if (xmi === 0) {
                  imdata.num = mks[0];

                  imdata.num2 = mks[1];

                  canvasData.leftbiao.push(imdata.num);
                  canvasData.leftbiao.push(imdata.num2);
                } else {
                  imdata.num = "";
                  imdata.num2 = mks[1];
                  canvasData.leftbiao.push(imdata.num2);
                }

                if ("∞" == combet[1]) {
                  var fis = x.m.filter((filt) => filt.level == "3")[0];
                  var ch2combet = fis.where
                    .replace(")", "")
                    .replace("[", "")
                    .split(",");
                  if (x.code == "remr") {
                  }
                  combet[1] =
                    Number(combet[0]) +
                    Number(ch2combet[1]) -
                    Number(ch2combet[0]);
                }

                var left =
                  ((x.v - combet[0]) / (combet[1] - combet[0])) * 100 + "%";

                imdata.left = left;
                imdata.color = xm.color;
                imdata.text = xm.text;
                imdata.level = xm.level;
                im.data.push(imdata);

                hutudata.forEach((dic) => {
                  let date2 = dayjs(dic.FinishTime).format("MM-DD");

                  // if (dic.reportCount > 7) {
                  whereStr(code, xm.where, dic, (dicvalue) => {
                    var numText = "";
                    if (this[code]) {
                      numText = this[code](dicvalue, dic);
                    }

                    let date = dayjs(dic.FinishTime).format("MM-DD");

                    canvasData.text.push({
                      FinishTime: dic.FinishTime,
                      color: xm.color,
                      date: date,
                      num: dicvalue,
                      numText: numText,
                    });
                  });
                  // }
                });
              });
              im.canvasData.text = quickSort(im.canvasData.text, "FinishTime");
              x.im = im;

              pu.push(x);
            }

            //.filter(u =>{return (u.type == 'hc' || u.type=='mc'||u.type=='tc') })

            this.manbingDetail = pu;
            this.manbingDetailRedLength = this.manbingDetail.filter(
              (u) => u.level == "1"
            ).length;
            this.manbingDetailYellowLength = this.manbingDetail.filter(
              (u) => u.level == "2"
            ).length;
          } else {
            Toast(res.data.data.msg);
          }
        });
    },
  },
  created() {
    this.reportId = this.$route.query.id;
  },
  mounted() {
    this.init();
  },
};
</script>
  
  
<style>
#grfx #click_show_li_show {
  display: none;
  padding-top: 10px;
}
</style>
  <style lang="less" scoped>
.top-navbar {
  background: #307b62;
  /deep/ .van-icon {
    color: #fff;
  }
  /deep/ .van-nav-bar__title {
    color: #fff;
  }
}
.panel__wrapper {
  padding: 10px;
  background: #307b62;
  .am_panel {
    background: #fff;
    padding: 10px;
    min-height: 50px;
    border-radius: 12px;
    .warning-tips {
      font-size: 12px;
      &.level1 {
        color: #de4f46;
      }
      &.level2 {
        color: #f6a717;
      }
    }
  }
  .wi150 {
    width: 110px;
  }

  .fontsize26 {
    font-size: 16px;
  }

  .width24 {
    display: flex;
    justify-content: flex-end;
  }

  .van-icon {
    color: #c7c7c7;
  }

  .aammm {
    border-bottom: 1px solid #efefef;
  }

  .red {
    color: red;
  }

  .yellow {
    color: rgb(248, 178, 96);
  }

  .am_panel {
    background: #fff;
    padding: 15px;
    border-radius: 12px;
  }

  .imdabaicolor {
    color: #f1f1f1;
    font-weight: 300;
  }
}
</style>
    