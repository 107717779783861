<template>
  <div>
    <van-nav-bar
      class="top-navbar"
      left-arrow
      @click-left="onClickLeft"
      title="睡眠"
    />
    <div id="sleep2">
      <div class="refsleep2Huitui">
        <sleep2Huitui ref="refsleep2Huitui" />
      </div>

      <div class="refsleep2Detail">
        <sleep2Detail ref="refsleep2Detail" />
      </div>
    </div>
  </div>
</template>
  
<script>
import Vue from "vue";
import { Icon, NavBar, Toast } from "vant";
Vue.use(Icon);
Vue.use(NavBar);
Vue.use(Toast);
import sleep2Huitui from "./sleep2Huitui";
import sleep2Detail from "./sleep2Detail";
export default {
  components: {
    sleep2Huitui,
    sleep2Detail,
  },
  data() {
    return {};
  },
  mounted() {
    //获取4g健康报告详情
    this.get4GReportDetail();
  },
  methods: {
    //获取4g健康报告详情
    get4GReportDetail() {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      this.$axios
        .get(
          // "https://health.ruinetworks.com/api/health/lite4g/reportSenvivData",
          "https://health.ruinetworks.com/report14/health/lite4g/devReportData",
          {
            params: {
              // id: "109339e2-c7b6-4ba7-9019-a6e8f3ebfabd",
              id: this.$route.query.id,
            },
            headers: {
              user_token: this.$route.query.token,
            },
          }
        )
        .then((res) => {
          if (res.data.success == "true") {
            const reportDetail = res.data.data[0];
            this.reportData = reportDetail;
            //图表
            var refsleep2Huitui = this.$refs.refsleep2Huitui;
            if (refsleep2Huitui) {
              refsleep2Huitui.init(reportDetail);
            }
            //tab详情
            var refsleep2Detail = this.$refs.refsleep2Detail;
            if (refsleep2Detail) {
              refsleep2Detail.init(reportDetail);
            }


          } else {
            Toast(res.data.data.msg);
          }
        });
    },
    //返回上一页
    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>
<style  lang="less">
.top-navbar {
  background: #307b62;
  .van-icon {
    color: #fff;
  }
  .van-nav-bar__title {
    color: #fff;
  }
}
#sleep2 {
  background: #f3f3f3fa;

  #stop_click_span {
    background: #e3f6ff;
    color: #006beb;
    font-size: 16px;
    padding: 10px;
    padding-left: 45px;
    padding-right: 45px;
    border-radius: 5px;
    border: 1px solid #0e83ff;
  }

  .itemIco {
    width: 18px;
    height: 18px;
    display: inline-block;
    vertical-align: sub;
    margin-left: 5px;
    border-radius: 10px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(51, 51, 51);
    border-image: initial;
  }

  .deepsleep {
    background: rgb(66, 110, 237);
  }

  .shallowsleep {
    background: rgb(155, 223, 255);
  }

  .rem {
    background: rgb(249, 231, 255);
  }

  .qingx {
    background: rgb(216, 237, 255);
  }

  .lc {
    background: rgb(255, 223, 36);
  }

  .refsleep2Huitui {
    padding: 10px;

    .huitu {
      padding-bottom: 30px;
      background: #fff;
      border-radius: 12px;
    }
  }

  .refsleep2Detail {
    padding: 10px;
  }
}
</style>
  
  