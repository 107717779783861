<template>
  <div class="article-wrapper">
    <div
      class="item"
      :style="`background: url(${item.thumb_url}) center no-repeat`"
      v-for="(item, index) in list"
      :key="index"
      @click="getArticleDetail(item.url)"
    >
      <h2>{{ item.title }}</h2>
      <p>{{ item.digest }}</p>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Toast } from "vant";
Vue.use(Toast);
export default {
  name: "ArticleList",
  data() {
    return {
      list: [],
    };
  },
  methods: {
    //根据code获取微信openid
    getWxOpenid(code) {
      this.$axios
        .get("https://wechat.ruinetworks.com/api/wechatMp/openid", {
          params: {
            code: code,
            appId: 'wx8bc182e9df3483f6' 
          },
        })
        .then((res) => {
          const detail = res.data;
          if (detail.success == "true") {
            window.localStorage.setItem("unionid", detail.data.unionid);
            this.getArticleList();
          } else {
            Toast("获取微信用户失败");
          }
        });
    },
    //获取文章列表
    getArticleList() {
      this.$axios
        .get("https://wechat.ruinetworks.com/api/channel/info", {
          params: {
            page: 1,
            page_size: 30,
            appId: 'wx8bc182e9df3483f6',
            typeId: "1c8e1964-b782-4a71-af35-e756f84cc62d",
          }
        })
        .then((res) => {
          const detail = res.data;
          if (detail.success == "true") {
            const articleList = detail.data.list;
            articleList.forEach((el) => {
              const articleDetail = JSON.parse(el.content)[0];
              this.list.push({
                title: articleDetail.title,
                digest: articleDetail.digest,
                url: articleDetail.url,
                thumb_url: articleDetail.thumb_url,
              });
            });
          } else {
            Toast("查询失败");
          }
        });
    },
    //查看文章详情
    getArticleDetail(url) {
      window.location.href = url;
    },
  },
  created() {
    //判断是否含有unionid
    // if (localStorage.getItem("unionid") == null) {
    //   //如果参数直接有openid
    //   if (this.$route.query.unionid) {
    //     window.localStorage.setItem("unionid", this.$route.query.unionid);
    //   } else {
    //     //没有则通过code来获取
    //     if (this.$route.query.code) {
    //       this.getWxOpenid(this.$route.query.code);
    //     }
    //   }
    // } else {
    //   //获取文章列表
    //   this.getArticleList();
    // }
    //获取文章列表
    this.getArticleList();
  },
};
</script>

<style lang="less" scoped>
.article-wrapper {
  padding: 10px;
  .item {
    height: 120px;
    background-size: cover !important;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    h2 {
      color: #fff;
      font-size: 18px;
      padding-left: 20px;
      line-height: 40px;
    }
    p {
      color: #fff;
      font-size: 14px;
      padding-left: 20px;
    }
  }
}
</style>
