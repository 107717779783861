<template>
  <div class="card-item" @click="handleManxing">
    <h3>慢性防控<img :src="require('@/assets/images/day/mx.png')" /></h3>
    <div class="circle-box">
      <circular ref="mangbingref" />
    </div>
    <p class="info">{{ mangbingShowText }}</p>
  </div>
</template>

<script>
import baseConfig from "@/base";
import Circular from "./Circular.vue";
export default {
  data() {
    return {
      hrv: true,
      rl: 0, //报告数量
      mangbingShowText: "",
      mangbing: {},
    };
  },
  components: { Circular },
  methods: {
    //慢性病
    handleManxing() {
      this.$router.push('/mb?id=' + this.$route.query.id + "&token=" + this.$route.query.token);
    },
    //初始化
    init(d, reportPar) {
      if (d.hrv === "1") {
        this.hrv = true;
      } else {
        this.hrv = false; //hrv
      }
      var rl = d.reportCount; //报告数量
      this.rl = rl;
      var mangbingref = this.$refs.mangbingref;

      var mangbingText = baseConfig.reportConfig.mangbing.mangbingText;

      var pu = [];
      var js = {
        hc: "高血压",
        mc: "冠心病",
        tc: "糖尿病",
      };
      function ac(t, n) {
        t.forEach((element) => {
          var vobo = element.where.replace(/A/g, d[n]);
          if (eval(vobo)) {
            var rame = element.rame.replace(/XXX/g, js[n]);
            element.xrame = rame;

            element.score = d[n];
            pu.push(element);
          }
        });
      }

      mangbingref.soreText = "";
      if (this.hrv && rl > 7) {
        if (d.mbs) {
          mangbingref.score = d.mbs.toFixed(1);
        }

        var jsmb = {};

        for (let di = 0; di < reportPar.length; di++) {
          var x = reportPar[di];

          if (x.code == "mbs") {
          }

          if (["慢病管理"].indexOf(x.type) == -1) {
            continue;
          }
          var code = x.code;
          jsmb[code] = x;

          if (code == "hc") {
            if (d.gxy != "1") {
              continue;
            }
          }
          if (code == "hcNot") {
            if (d.gxy == "1") {
              continue;
            }
          }

          if (code == "mc") {
            if (d.gxb != "1") {
              continue;
            }
          }

          if (code == "mcNot") {
            if (d.gxb == "1") {
              continue;
            }
          }

          if (code == "tc") {
            if (d.tnb != "1") {
              continue;
            }
          }

          if (code == "tcNot") {
            if (d.tnb == "1") {
              continue;
            }
          }
        }

        var a1 = `高血压`;
        var a2 = `冠心病`;
        var a3 = `糖尿病`;

        var b1 = `血压调节`;
        var b2 = `冠状动脉`;
        var b3 = `血糖平衡`;

        //  d.gxy = '1'

        var qq = `${d.gxy}${d.gxb}${d.tnb}`; //100 101 001 110 101 011 000 111
        // console.log(jsmb)
        var pusore = 0;
        var puLevel = [];
        
        if (d.gxy == "1") {
          puLevel.push(jsmb.hc.level);
          pusore += jsmb.hc.v;
        } else {
          puLevel.push(jsmb.hcNot.level);
          pusore += jsmb.hcNot.v;
        }
        if (d.gxb == "1") {
          puLevel.push(jsmb.mc.level);
          pusore += jsmb.mc.v;
        } else {
          puLevel.push(jsmb.mcNot.level);
          pusore += jsmb.mcNot.v;
        }
        if (d.tnb == "1") {
          puLevel.push(jsmb.tc.level);
          pusore += jsmb.tc.v;
        } else {
          puLevel.push(jsmb.tcNot.level);
          pusore += jsmb.tcNot.v;
        }

        var ke2 = puLevel.join("");
        if (ke2 == "333") {
          var pusoreResult = pusore / 3;
          if (pusoreResult < 77) {
            pusoreResult = 77;
          } else {
            pusoreResult = Math.floor(pusoreResult);
          }
          mangbingref.score = pusoreResult;
        } else if (ke2 == "111") {
          mangbingref.score = Math.floor((pusore / 3) * 0.73);
        } else {
          mangbingref.score = Math.floor((pusore / 3) * 0.85);
        }
        if (mangbingref.score >= 0 && mangbingref.score < 51) {
          mangbingref.level = 1;
        }
        if (mangbingref.score >= 51 && mangbingref.score < 77) {
          mangbingref.level = 2;
        }
        if (mangbingref.score >= 77 && mangbingref.score < 101) {
          mangbingref.level = 3;
        }

        //mangbingref.level=Number(x.level);
        mangbingref.init();

        //3正常 2密切关切 1值得关注
        //3
        var jsonResult = {
          //一种疾病，两种机能
          100: [
            //33
            {
              where: `333`,
              text: `您的${a1}管控好,${b2}、${b3}机能较好，继续加油`,
            },
            {
              where: `332`,
              text: `您的${b3}机能一般，请关注`,
            },
            {
              where: `331`,
              text: `您的${b3}机能较差，请密切关注`,
            },

            {
              where: `323`,
              text: `您的${b2}机能一般，请关注`,
            },

            {
              where: `322`,
              text: `您的${b2}、${b3}机能一般，请关注`,
            },

            {
              where: `321`,
              text: `您的${b2}机能一般、${b3}机能较差，请密切关注`,
            },
            {
              where: `313`,
              text: `您的${b2}机能较差，请密切关注`,
            },

            {
              where: `312`,
              text: `您的${b2}机能较差，${b3}机能一般，请密切关注`,
            },
            {
              where: `311`,
              text: `您的${b2}机能较差，${b3}机能较差，请密切关注`,
            },
            //33
            //22
            {
              where: `233`,
              text: `您的${a1}管控一般请关注`,
            },
            {
              where: `232`,
              text: `您的${a1}管控一般，${b3}机能一般，请关注`,
            },
            {
              where: `231`,
              text: `您的${a1}管控一般，${b3}机能较差，请密切关注`,
            },

            {
              where: `223`,
              text: `您的${a1}管控一般，${b2}机能一般，请关注`,
            },

            {
              where: `222`,
              text: `您的${a1}管控一般，${b2}、${b3}机能一般，请关注`,
            },

            {
              where: `221`,
              text: `您的${a1}管控一般，${b2}机能一般、${b3}机能较差，请密切关注`,
            },
            {
              where: `213`,
              text: `您的${a1}管控一般，${b2}机能较差，请密切关注`,
            },

            {
              where: `212`,
              text: `您的${a1}管控一般，${b2}机能较差，${b3}机能一般，请密切关注`,
            },
            {
              where: `211`,
              text: `您的${a1}管控一般，${b2}机能较差，${b3}机能较差，请密切关注`,
            },
            //22
            //11
            {
              where: `133`,
              text: `您的${a1}管控较差请关注`,
            },
            {
              where: `132`,
              text: `您的${a1}管控较差，${b3}机能一般，请关注`,
            },
            {
              where: `131`,
              text: `您的${a1}管控较差，${b3}机能较差，请密切关注`,
            },

            {
              where: `123`,
              text: `您的${a1}管控较差，${b2}机能一般，请关注`,
            },

            {
              where: `122`,
              text: `您的${a1}管控较差，${b2}、${b3}机能一般，请关注`,
            },

            {
              where: `121`,
              text: `您的${a1}管控较差，${b2}机能一般、${b3}机能较差，请密切关注`,
            },
            {
              where: `113`,
              text: `您的${a1}管控较差，${b2}机能较差，请密切关注`,
            },
            {
              where: `112`,
              text: `您的${a1}管控较差，${b2}机能较差，${b3}机能一般，请密切关注`,
            },
            {
              where: `111`,
              text: `您的${a1}管控较差，${b2}机能较差，${b3}机能较差，请密切关注`,
            },
            //11
          ],

          "010": [
            //33
            {
              where: `333`,
              text: `您的${a2}管控好,${b1}、${b3}机能较好，继续加油`,
            },
            {
              where: `332`,
              text: `您的${b3}机能一般，请关注`,
            },
            {
              where: `331`,
              text: `您的${b3}机能较差，请密切关注`,
            },

            {
              where: `323`,
              text: `您的${a2}管控一般，请关注`,
            },

            {
              where: `322`,
              text: `您的${a2}管控一般,${b3}机能一般，请关注`,
            },

            {
              where: `321`,
              text: `您的${a2}管控一般,${b3}机能较差，请密切关注`,
            },
            {
              where: `313`,
              text: `您的${a2}管控较差，请密切关注`,
            },

            {
              where: `312`,
              text: `您的${a2}管控较差，${b3}机能一般，请密切关注`,
            },
            {
              where: `311`,
              text: `您的${a2}管控较差，${b3}机能较差，请密切关注`,
            },
            //33
            //22
            {
              where: `233`,
              text: `您的${b1}机能一般请关注`,
            },
            {
              where: `232`,
              text: `您的${b1}机能一般，${b3}机能一般，请关注`,
            },
            {
              where: `231`,
              text: `您的${b1}机能一般，${b3}机能较差，请密切关注`,
            },

            {
              where: `223`,
              text: `您的${b1}机能一般，${a2}管控一般，请关注`,
            },

            {
              where: `222`,
              text: `您的${b1}机能一般，${a2}管控一般、${b3}机能一般，请关注`,
            },

            {
              where: `221`,
              text: `您的${b1}机能一般，${a2}管控一般、${b3}机能较差，请密切关注`,
            },
            {
              where: `213`,
              text: `您的${b1}机能一般，${a2}管控较差，请密切关注`,
            },

            {
              where: `212`,
              text: `您的${b1}机能较差，${a2}管控一般，${b3}机能一般，请密切关注`,
            },
            {
              where: `211`,
              text: `您的${b1}机能较差，${a2}管控一般，${b3}机能较差，请密切关注`,
            },
            //22
            //11
            {
              where: `133`,
              text: `您的${b1}机能较差请关注`,
            },
            {
              where: `132`,
              text: `您的${b1}机能较差，${b3}机能一般，请关注`,
            },
            {
              where: `131`,
              text: `您的${b1}机能较差，${b3}机能较差，请密切关注`,
            },

            {
              where: `123`,
              text: `您的${b1}机能较差，${b2}机能一般，请关注`,
            },

            {
              where: `122`,
              text: `您的${b1}机能较差，${a2}管控一般,${b3}机能一般，请密切关注`,
            },

            {
              where: `121`,
              text: `您的${b1}机能较差，${a2}管控一般、${b3}机能较差，请密切关注`,
            },
            {
              where: `113`,
              text: `您的${b1}机能较差，${a2}管控较差，请密切关注`,
            },
            {
              where: `112`,
              text: `您的${b1}机能较差，${a2}管控较差，${b3}机能一般，请密切关注`,
            },
            {
              where: `111`,
              text: `您的${b1}机能较差，${a2}管控较差，${b3}机能较差，请密切关注`,
            },
            //11
          ],

          "001": [
            //33
            {
              where: `333`,
              text: `您的${a3}管控好,${b1}、${b2}机能较好，继续加油`,
            },
            {
              where: `332`,
              text: `您的${a3}管控一般，请关注`,
            },
            {
              where: `331`,
              text: `您的${a3}管控较差，请密切关注`,
            },

            {
              where: `323`,
              text: `您的${b2}机能一般，请关注`,
            },

            {
              where: `322`,
              text: `您的${a3}管控一般,${b2}机能一般，请关注`,
            },

            {
              where: `321`,
              text: `您的${a3}管控较差,${b2}机能较差，请密切关注`,
            },
            {
              where: `313`,
              text: `您的${b2}机能较差，请密切关注`,
            },

            {
              where: `312`,
              text: `您的${a3}管控一般，${b2}机能一般，请密切关注`,
            },
            {
              where: `311`,
              text: `您的${a3}管控较差，${b2}机能较差，请密切关注`,
            },
            //33
            //22
            {
              where: `233`,
              text: `您的${b1}机能一般请关注`,
            },
            {
              where: `232`,
              text: `您的${b1}机能一般，${a3}管控一般，请关注`,
            },
            {
              where: `231`,
              text: `您的${b1}机能一般，${a3}管控较差，请密切关注`,
            },

            {
              where: `223`,
              text: `您的${b1}机能一般，${b2}机能一般，请关注`,
            },

            {
              where: `222`,
              text: `您的${b1}机能一般，${b2}机能一般、${a3}管控一般，请关注`,
            },

            {
              where: `221`,
              text: `您的${b1}机能一般，${b2}机能一般、${a3}管控较差，请密切关注`,
            },
            {
              where: `213`,
              text: `您的${b1}机能一般，${b2}机能较差，请密切关注`,
            },

            {
              where: `212`,
              text: `您的${b1}机能较差，${b2}机能一般，${a3}管制一般，请密切关注`,
            },
            {
              where: `211`,
              text: `您的${b1}机能较差，${b2}机能一般，${a3}管控较差，请密切关注`,
            },
            //22
            //11
            {
              where: `133`,
              text: `您的${b1}机能较差请关注`,
            },
            {
              where: `132`,
              text: `您的${b1}机能较差，${a3}管控一般，请关注`,
            },
            {
              where: `131`,
              text: `您的${b1}机能较差，${a3}管控较差，请密切关注`,
            },

            {
              where: `123`,
              text: `您的${b1}机能较差，${b2}机能一般，请关注`,
            },

            {
              where: `122`,
              text: `您的${b1}机能较差，${b2}机能一般,${a3}管控一般，请密切关注`,
            },

            {
              where: `121`,
              text: `您的${b1}机能较差，${b2}机能一般、${a3}机能较差，请密切关注`,
            },
            {
              where: `113`,
              text: `您的${b1}机能较差，${b2}机能较差，请密切关注`,
            },
            {
              where: `112`,
              text: `您的${b1}机能较差，${b2}机能较差，${a3}管控一般，请密切关注`,
            },
            {
              where: `111`,
              text: `您的${b1}机能较差，${b2}管控较差，${a3}机能较差，请密切关注`,
            },
            //11
          ],

          110: [],
          101: [],
          "011": [],

          111: [],
          "000": [],
        };

        var qingkuang = [
          "333",
          "332",
          "331",
          "323",
          "322",
          "321",
          "313",
          "312",
          "311",

          "233",
          "232",
          "231",
          "223",
          "222",
          "221",
          "213",
          "212",
          "211",

          "133",
          "132",
          "131",
          "123",
          "122",
          "121",
          "113",
          "112",
          "111",
        ];

        var a110 = function () {
          qingkuang.forEach((item) => {
            var str = `您的`;
            var str_zuihou = ``;
            if (item[0] == "2") {
              str += `${a1}管控一般，`;
              if (str_zuihou == "") {
                str_zuihou = `请关注`;
              }
            }
            if (item[0] == "1") {
              str += `${a1}管控较差，`;
              str_zuihou = `请密切关注`;
            }

            if (item[1] == "2") {
              str += `${a2}管控一般，`;
              if (str_zuihou == "") {
                str_zuihou = `请关注`;
              }
            }
            if (item[1] == "1") {
              str += `${a2}管控较差，`;
              str_zuihou = `请密切关注`;
            }

            if (item[2] == "2") {
              str += `${b3}机能一般，`;
              if (str_zuihou == "") {
                str_zuihou = `请关注`;
              }
            }
            if (item[2] == "1") {
              str += `${b3}机能较差，`;
              str_zuihou = `请密切关注`;
            }

            if (str_zuihou == "") {
              str += `慢病管控好,继续加油`;
            } else {
              str += str_zuihou;
            }
            var data = {};
            data.where = item;
            data.text = str;
            jsonResult["110"].push(data);
          });
        };

        var a101 = function () {
          qingkuang.forEach((item) => {
            var str = `您的`;
            var str_zuihou = ``;
            if (item[0] == "2") {
              str += `${a1}管控一般，`;
              if (str_zuihou == "") {
                str_zuihou = `请关注`;
              }
            }
            if (item[0] == "1") {
              str += `${a1}管控较差，`;
              str_zuihou = `请密切关注`;
            }

            if (item[1] == "2") {
              str += `${b2}机能一般，`;
              if (str_zuihou == "") {
                str_zuihou = `请关注`;
              }
            }
            if (item[1] == "1") {
              str += `${b2}机能较差，`;
              str_zuihou = `请密切关注`;
            }

            if (item[2] == "2") {
              str += `${a3}管控一般，`;
              if (str_zuihou == "") {
                str_zuihou = `请关注`;
              }
            }
            if (item[2] == "1") {
              str += `${a3}管控较差，`;
              str_zuihou = `请密切关注`;
            }

            if (str_zuihou == "") {
              str += `慢病管控好,继续加油`;
            } else {
              str += str_zuihou;
            }

            str += str_zuihou;
            var data = {};
            data.where = item;
            data.text = str;
            jsonResult["101"].push(data);
          });
        };

        var a011 = function () {
          qingkuang.forEach((item) => {
            var str = `您的`;
            var str_zuihou = ``;
            if (item[0] == "2") {
              str += `${b1}机能一般，`;
              if (str_zuihou == "") {
                str_zuihou = `请关注`;
              }
            }
            if (item[0] == "1") {
              str += `${b1}机能较差，`;
              str_zuihou = `请密切关注`;
            }

            if (item[1] == "2") {
              str += `${a2}管控一般，`;
              if (str_zuihou == "") {
                str_zuihou = `请关注`;
              }
            }
            if (item[1] == "1") {
              str += `${a2}管控较差，`;
              str_zuihou = `请密切关注`;
            }

            if (item[2] == "2") {
              str += `${a3}管控一般，`;
              if (str_zuihou == "") {
                str_zuihou = `请关注`;
              }
            }
            if (item[2] == "1") {
              str += `${a3}管控较差，`;
              str_zuihou = `请密切关注`;
            }

            if (str_zuihou == "") {
              str += `${b1}机能好,${a2},${a3}管控好`;
            } else {
              str += str_zuihou;
            }

            str += str_zuihou;
            var data = {};
            data.where = item;
            data.text = str;
            jsonResult["011"].push(data);
          });
        };

        var a111 = function () {
          qingkuang.forEach((item) => {
            var str = `您的`;
            var str_zuihou = ``;
            if (item[0] == "2") {
              str += `${a1}管控一般，`;
              if (str_zuihou == "") {
                str_zuihou = `请关注`;
              }
            }
            if (item[0] == "1") {
              str += `${a1}管控较差，`;
              str_zuihou = `请密切关注`;
            }

            if (item[1] == "2") {
              str += `${a2}管控一般，`;
              if (str_zuihou == "") {
                str_zuihou = `请关注`;
              }
            }
            if (item[1] == "1") {
              str += `${a2}管控较差，`;
              str_zuihou = `请密切关注`;
            }

            if (item[2] == "2") {
              str += `${a3}管控一般，`;
              if (str_zuihou == "") {
                str_zuihou = `请关注`;
              }
            }
            if (item[2] == "1") {
              str += `${a3}管控较差，`;
              str_zuihou = `请密切关注`;
            }
            if (str_zuihou == "") {
              str += `${b1},${b2},${b3}慢病管控较好,继续加油`;
            } else {
              str += str_zuihou;
            }
            var data = {};
            data.where = item;
            data.text = str;
            jsonResult["111"].push(data);
          });
        };
        var a000 = function () {
          qingkuang.forEach((item) => {
            var str = `您的`;
            var str_zuihou = ``;
            if (item[0] == "2") {
              str += `${b1}机能一般，`;
              if (str_zuihou == "") {
                str_zuihou = `请关注`;
              }
            }
            if (item[0] == "1") {
              str += `${b1}机能较差，`;
              str_zuihou = `请密切关注`;
            }

            if (item[1] == "2") {
              str += `${b2}机能一般，`;
              if (str_zuihou == "") {
                str_zuihou = `请关注`;
              }
            }
            if (item[1] == "1") {
              str += `${b2}机能较差，`;
              str_zuihou = `请密切关注`;
            }

            if (item[2] == "2") {
              str += `${b3}机能一般，`;
              if (str_zuihou == "") {
                str_zuihou = `请关注`;
              }
            }
            if (item[2] == "1") {
              str += `${b3}机能较差，`;
              str_zuihou = `请密切关注`;
            }
            if (str_zuihou == "") {
              str += `${b1},${b2},${b3}机能较好`;
            } else {
              str += str_zuihou;
            }

            var data = {};
            data.where = item;
            data.text = str;
            jsonResult["000"].push(data);
          });
        };

        a110();
        a101();
        a011();
        a111();
        a000();

        if (jsonResult[qq]) {
          var mkqq = jsonResult[qq];

          var notbook = "";
          for (var m = 0; m < mkqq.length; m++) {
            var mkqqdata = mkqq[m];
            if (mkqqdata.where == ke2) {
              notbook = mkqqdata.text;
              break;
            }
          }
          if (notbook != "") {
            pu.push(notbook);
          }

          // console.log(mkqq)
        }
      } else {
        mangbingref.scoreText = "--";
        mangbingref.init();
      }

      this.mangbing = baseConfig.reportConfig.mangbing;
      if (pu.length > 0) {
        this.mangbingShowText = pu[pu.length - 1];
      } else {
        if (rl > 7) {
        } else {
          if (this.hrv) {
            this.mangbingShowText = baseConfig.reportConfig.mangbing.h7;
          } else {
            this.mangbingShowText = baseConfig.reportConfig.mangbing.h4;
          }
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.card-item {
  background: #fff;
  box-shadow: 2px 3px 0px 2px #d8ebe1;
  border-radius: 12px;
  padding: 10px;
  h3 {
    font-size: 16px;
    font-weight: 500;
    > img {
      width: 16px;
      vertical-align: middle;
      padding-left: 6px;
    }
  }
  .circle-box {
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .info {
    color: #2e3142;
    font-size: 12px;
    font-weight: 500;
  }
}
</style>