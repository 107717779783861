<template>
  <div class="blood-wrapper">
    <div class="banner-wrapper">
      <h2 class="title">血压数据</h2>
      <p class="report-time">{{ reportTime }}</p>
      <div class="user-info">
        <div class="avatar-img">
          <img :src="userAvatar" alt="" />
        </div>
        <p class="user-name">{{ userName }}</p>
      </div>
    </div>
    <div class="report-wrapper">
      <div class="item">
        <h3 class="van-hairline--bottom">
          最近一次血压记录
        </h3>
        <div class="report-data">
          <div>
            <p style="color: #0d7fe8">{{ xueyaData.sysBloodPressure }}</p>
            <p>mmHg</p>
            <p>收缩压</p>
          </div>
          <div>
            <p style="color: #049778">{{ xueyaData.diasBloodPressure }}</p>
            <p>mmHg</p>
            <p>舒张压</p>
          </div>
          <div>
            <p style="color: #db9200">{{ xueyaData.heartRate }}</p>
            <p>次/分</p>
            <p>心率</p>
          </div>
        </div>
      </div>
      <div class="item">
        <h3 class="van-hairline--bottom">
          血压趋势图
          <span class="select-time" @click="handleSelectDate"
            >{{ nowDate == null ? "选择日期" : nowDate
            }}<van-icon class="date-icon" name="arrow" />
          </span>
        </h3>
        <div class="report-echart">
          <div class="echart-wrapper" id="lineChart"></div>
        </div>
      </div>
    </div>
    <van-action-sheet :close-on-click-overlay="false" v-model="isShow" title="">
      <div class="content">
        <van-datetime-picker
          v-model="currentDate"
          type="date"
          title="选择年月日"
          :min-date="minDate"
          :max-date="maxDate"
          @confirm="dateConfirm"
          @cancel="dateCancel"
        />
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import Vue from "vue";
import { Icon, DatetimePicker, ActionSheet, Toast } from "vant";
Vue.use(Icon);
Vue.use(DatetimePicker);
Vue.use(ActionSheet);
Vue.use(Toast);
export default {
  name: "BloodReport",
  data() {
    return {
      isShow: false,
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2025, 12, 31),
      currentDate: new Date(),
      nowDate: null,
      reportTime: '',
      userName: '',
      userAvatar: '',
      xueyaData: {
        sysBloodPressure: 0, //收缩压
        diasBloodPressure: 0, //舒张压
        heartRate: 0, //心率
        time: undefined,
      },
    };
  },
  methods: {
    //时间选择
    handleSelectDate() {
      this.isShow = true;
    },
    //时间选择器确定操作
    dateConfirm(value) {
      const selectDate = this.formatDateTime(value);
      this.nowDate = selectDate;
      this.getXueyaChart();
      this.isShow = false;
    },
    formatDateTime(date) {
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return y + "-" + m + "-" + d;
    },
    //时间选择框关闭
    dateCancel() {
      this.isShow = false;
    },
    //血压趋势
    initBloodPressure(timer, data) {
      let myChart = this.$echarts.init(document.getElementById("lineChart"));
      const that = this;
      let option = {
        grid: {
          top: 10,
          left: 20,
          right: 20,
          bottom: 50,
          containLabel: true,
        },
        legend: {
          show: true,
          bottom: 15,
        },
        tooltip: {
          show: true,
          trigger: "axis",
        },
        xAxis: {
          show: true,
          type: "category",
          data: timer,
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: true,
            formatter: function (params) {
              const dateTimerArr = params.split(" ");
              return dateTimerArr[1];
            },
          },
          axisLine: {
            lineStyle: {
              color: "#5D6175",
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: "#E9E9EC",
            },
          },
        },
        yAxis: {
          type: "value",
          axisTick: {
            show: false,
          },
          axisLabel: {
            color: "#5D6175",
          },
          axisLine: {
            lineStyle: {
              color: "#5D6175",
            },
          },
          splitLine: {
            lineStyle: {
              type: "dashed",
              color: "#E9E9EC",
            },
          },
        },
        series: data,
      };
      myChart.setOption(option);
    },
    //获取最新的血压测量数据
    getData() {
      this.$axios
        .get("https://health.ruinetworks.com/api/health/dev/bp/news", {
          params: {
            extUserId: this.$route.query.id
          },
          headers: {
            user_token: this.$route.query.token,
          },
        })
        .then((res) => {
          const dataDetail = res.data;
          if (dataDetail.success == "true") {
            this.xueyaData = dataDetail.data.reportData;
            this.reportTime = dataDetail.data.createTime;
            this.userAvatar = dataDetail.data.wechatAvatarUrl;
            this.userName = dataDetail.data.userName;
          } else {
            Toast(dataDetail.data.msg);
          }
        });
    },
    //获取用户的血压趋势
    getXueyaChart() {
      this.$axios
        .get("https://health.ruinetworks.com/api/health/dev/bp/chart", {
          params: {
            extUserId: this.$route.query.id,
            time: this.nowDate,
          },
          headers: {
            user_token: this.$route.query.token,
          },
        })
        .then((res) => {
          const dataDetail = res.data;
          if (dataDetail.success == "true") {
            const data = dataDetail.data;
            let lineData = [
              {
                name: "舒张压",
                type: "line",
                smooth: true,
                data: data.dbp,
                symbolSize: 6,
                itemStyle: {
                  color: "#049778",
                },
              },
              {
                name: "收缩压",
                type: "line",
                smooth: true,
                data: data.sbp,
                symbolSize: 6,
                itemStyle: {
                  color: "#0d7fe8",
                },
              },
              {
                name: "心率",
                type: "line",
                smooth: true,
                symbolSize: 6,
                data: data.heartRate,
                itemStyle: {
                  color: "#db9200",
                },
              },
            ];
            //获取用户的血压趋势
            this.initBloodPressure(data.time, lineData);
          }
        });
    },
  },
  created() {
    //获取当前年月日
    const date = new Date();
    const timer =
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1) +
      "-" +
      (date.getDate() < 10 ? "0" + date.getDate() : date.getDate());
    this.nowDate = timer;
  },
  mounted() {
    //获取最近一次血压的数据信息
    this.getData();
    this.getXueyaChart();
  },
};
</script>

<style lang="less" scoped>
.blood-wrapper {
  .banner-wrapper {
    position: relative;
    height: 120px;
    background: linear-gradient(180deg, #0cc2a9 0%, #49cdab 100%);
    border-radius: 0 0 30px 30px;
    padding: 10px 24px;
    .title {
      color: #fff;
      font-size: 22px;
      font-weight: 500;
    }
    .report-time {
      font-size: 14px;
      color: #fff;
      font-weight: 400;
      line-height: 36px;
    }
    .user-info {
      position: absolute;
      right: 20px;
      top: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .avatar-img {
        width: 38px;
        height: 38px;
        border-radius: 8px;
        background: #f2f2f2;
        overflow: hidden;
        > img {
          width: 100%;
          height: 100%;
        }
      }
      .user-name {
        margin-top: 6px;
        text-align: center;
        color: #fff;
        font-size: 18px;
        font-weight: 500;
      }
    }
  }
  .report-wrapper {
    position: relative;
    margin-top: -65px;
    padding: 15px;
    .item {
      margin-bottom: 12px;
      border-radius: 16px;
      background: #fff;
      h3 {
        line-height: 48px;
        position: relative;
        font-size: 18px;
        padding-left: 30px;
        color: #272c47;
        &:before {
          position: absolute;
          content: "";
          width: 8px;
          height: 8px;
          background: #fff;
          border-radius: 50%;
          border: 3px solid #1890ff;
          left: 10px;
          top: 50%;
          transform: translateY(-50%);
        }
        .time {
          position: absolute;
          right: 10px;
          font-size: 16px;
          color: #9395a3;
          font-weight: 400;
        }
        .select-time {
          position: absolute;
          right: 10px;
          font-size: 16px;
          font-weight: 400;
          color: #9395a3;
          .date-icon {
            font-size: 18px;
            vertical-align: text-bottom;
            margin-left: 5px;
          }
        }
      }
      .report-data {
        display: flex;
        padding: 20px 10px;
        > div {
          width: 33%;
          > p {
            text-align: center;
            &:first-child {
              font-size: 40px;
            }
            &:nth-child(2) {
              font-size: 14px;
              color: #5d6175;
            }
            &:last-child {
              font-size: 16px;
              font-weight: 700;
              color: #272c47;
              line-height: 40px;
            }
          }
        }
      }
      .report-echart {
        position: relative;
        overflow: hidden;
        .echart-wrapper {
          margin-top: 20px;
          height: 260px;
        }
      }
    }
  }
}
</style>