import { array } from 'js-md5'

var JieShi = {
  getwidth: function() {
    var winWidth = 0
    winWidth = $(window).width()
    return winWidth
  },
  red: '#ed6869',
  yellow: '#f8b260',
  green: '#4391ec'
}

var _Canvas_Chat = function(a, data, h) {
  var y10 = 15 //距离 底部的 高度多少
  var paddingTop = 30
  var y10h = 10 //坚的高度
  var level = 1

  //画点
  var radius = 10 // 圆弧半径
  var startAngle = 0 // 开始点
  var endAngle = Math.PI * 2 // + (Math.PI * 2) / 2; // 结束点
  var anticlockwise = true // 顺时针或逆时针

  var max = -1
  data.text.forEach(x => {
    if (Number(x.num) > max) {
      max = Number(x.num)
    }

    if (x.base) {
      if (x.base > max) {
        max = x.base
      }
    }
  })

  if ('Sc_tst' == a) {
    for (var lb = 0; lb < data.leftbiaoattr.length; lb++) {
      var lbdata = data.leftbiaoattr[lb]

      lbdata.text = `${Math.ceil(lbdata.value / 60)}h`

      //if(lbdata.value>=max)
      //{
      //max=lbdata.value;
      //  break;
      // }
    }
  }

  if (data.leftbiaoattr) {
    for (var lb = 0; lb < data.leftbiaoattr.length; lb++) {
      var lbdata = data.leftbiaoattr[lb]
      if (lbdata.value >= max) {
        //max=lbdata.value;
        break
      }
    }
  }

  if (max > 0) {
    level = (h - y10) / max
  }

  var c
  if (typeof a == 'string') {
    var qp = $(`#${a}`).parent()
    $(`#${a}`).remove()
    qp.html(`<canvas id="${a}" height=${h}></canvas>`)
    c = document.getElementById(a)
  } else {
    var mid = a.id
    //var qp = $(a).parent();
    //$(a).remove();
    //qp.html(`<canvas id="${mid}" height=${h}></canvas>`);
    c = a
  }

  var ctx = c.getContext('2d')
  //  var canvas = document.getElementById("canvas"),
  //      context = canvas.getContext("2d");
  // 屏幕的设备像素比
  var devicePixelRatio = window.devicePixelRatio || 1
  // 浏览器在渲染canvas之前存储画布信息的像素比
  var backingStoreRatio =
    ctx.webkitBackingStorePixelRatio ||
    ctx.mozBackingStorePixelRatio ||
    ctx.msBackingStorePixelRatio ||
    ctx.oBackingStorePixelRatio ||
    ctx.backingStorePixelRatio ||
    1
  // canvas的实际渲染倍率
  var ratio = devicePixelRatio / backingStoreRatio

  ratio = ratio == 1 ? 1.5 : ratio

  function getEventPosition(ev) {
    var x, y
    if (ev.layerX || ev.layerX == 0) {
      x = ev.layerX
      y = ev.layerY
    } else if (ev.offsetX || ev.offsetX == 0) {
      // Opera
      x = ev.offsetX
      y = ev.offsetY
    }
    return { x: x, y: y }
  }
  function ParamEllipse(context, x, y, a, b) {
    //max是等于1除以长轴值a和b中的较大者
    //i每次循环增加1/max，表示度数的增加
    //这样可以使得每次循环所绘制的路径（弧线）接近1像素
    var step = a > b ? 1 / a : 1 / b
    context.beginPath()
    context.moveTo(x + a, y) //从椭圆的左端点开始绘制
    for (var i = 0; i < 2 * Math.PI; i += step) {
      //参数方程为x = a * cos(i), y = b * sin(i)，
      //参数为i，表示度数（弧度）
      context.lineTo(x + a * Math.cos(i), y + b * Math.sin(i))
    }

    context.strokeStyle = '#ffffff' //填充边框颜色
    context.closePath()
    context.stroke()
  }

  /**该方法用来绘制一个有填充色的圆角矩形
   * cxt:canvas的上下文环境
   * x:左上角x轴坐标
   * y:左上角y轴坐标
   * width:矩形的宽度
   * height:矩形的高度
   * radius:圆的半径
   * fillColor:填充颜色
   **/
  function fillRoundRect(cxt, x, y, width, height, radius, /*optional*/ fillColor) {
    //圆的直径必然要小于矩形的宽高
    if (2 * radius > width || 2 * radius > height) {
      return false
    }

    cxt.save()
    cxt.translate(x, y)
    //绘制圆角矩形的各个边
    drawRoundRectPath(cxt, width, height, radius)
    cxt.fillStyle = fillColor || '#000' //若是给定了值就用给定的值否则给予默认值
    cxt.fill()
    cxt.restore()
  }

  /**该方法用来绘制圆角矩形
   * cxt:canvas的上下文环境
   * x:左上角x轴坐标
   * y:左上角y轴坐标
   * width:矩形的宽度
   * height:矩形的高度
   * radius:圆的半径
   * lineWidth:线条粗细
   * strokeColor:线条颜色
   **/
  function strokeRoundRect(cxt, x, y, width, height, radius, /*optional*/ lineWidth, /*optional*/ strokeColor) {
    //圆的直径必然要小于矩形的宽高
    if (2 * radius > width || 2 * radius > height) {
      return false
    }

    cxt.save()
    cxt.translate(x, y)
    //绘制圆角矩形的各个边
    drawRoundRectPath(cxt, width, height, radius)
    cxt.lineWidth = lineWidth || 2 //若是给定了值就用给定的值否则给予默认值2
    cxt.strokeStyle = strokeColor || '#000'
    cxt.stroke()
    cxt.restore()
  }

  function drawRoundRectPath(cxt, width, height, radius) {
    cxt.beginPath(0)
    //从右下角顺时针绘制，弧度从0到1/2PI
    cxt.arc(width - radius, height - radius, radius, 0, Math.PI / 2)

    //矩形下边线
    cxt.lineTo(radius, height)

    //左下角圆弧，弧度从1/2PI到PI
    cxt.arc(radius, height - radius, radius, Math.PI / 2, Math.PI)

    //矩形左边线
    cxt.lineTo(0, radius)

    //左上角圆弧，弧度从PI到3/2PI
    cxt.arc(radius, radius, radius, Math.PI, (Math.PI * 3) / 2)

    //上边线
    cxt.lineTo(width - radius, 0)

    //右上角圆弧
    cxt.arc(width - radius, radius, radius, (Math.PI * 3) / 2, Math.PI * 2)

    //右边线
    cxt.lineTo(width, height - radius)
    cxt.closePath()
  }

  c.onclick = function(e) {
    var bbox = c.getBoundingClientRect()
    var x = parseInt(e.clientX - bbox.left * (c.width / (bbox.width * ratio))) //鼠标点击canvas图像里面的X位置；
    var y = parseInt(e.clientY - bbox.top * (c.height / (bbox.height * ratio)))
    //为什么不是直接e.clientY - bbox.top呢
    x = x * ratio
    y = y * ratio

    for (var km = 0; km < c['_'].length; km++) {
      var dp = c['_'][km]
      var padin = 20
      if (dp.x - padin <= x && x < dp.x + padin) {
        if (data.writebiao) {
          ctx.beginPath()
          var dpy = dp.y + -25 * ratio // height - (dp.num + (65 * ratio));
          ctx.fillStyle = dp.color
          ctx.font = 14 * ratio + 'px serif '
          var numText = dp.numText ? dp.numText : dp.num / ratio
          var fontsizewidth = ctx.measureText(numText).width + 5 * ratio
          fillRoundRect(ctx, dp.x - 11 * ratio, dpy, fontsizewidth, 18 * ratio, 4 * ratio, '#fffff') //椭圆
          ctx.fill()
          ctx.closePath()
          ctx.fillStyle = '#ffffff'
          ctx.fillText(numText, dp.x - 8 * ratio, dpy + 15 * ratio)
        } else {
          ctx.beginPath()
          var dpy = height - (dp.num + 65 * ratio)
          ctx.fillStyle = dp.color
          fillRoundRect(ctx, dp.x - 15 * ratio, dpy, 30 * ratio, 16 * ratio, 4 * ratio, '#fffff') //椭圆
          ctx.fill()
          ctx.closePath()
          ctx.fillStyle = '#ffffff'
          ctx.font = 10 * ratio + 'px serif '
          var jtext = JieShi['焦虑情绪'].text_num[dp.num / ratio]
          ctx.fillText(jtext, dp.x - 10 * ratio, dpy + 12 * ratio)
        }
      }
    }
  }

  c.width = $(c)
    .parent()
    .width() // JieShi.getwidth();
  c.style.width = c.width + 'px'
  c.style.height = c.height + 'px'
  c.width = c.width * ratio
  c.height = c.height * ratio

  var height = c.height
  //bili=2

  y10 = y10 * ratio
  paddingTop = paddingTop * ratio
  if (max > 0) {
    level = (height - y10 - paddingTop) / max
  }

  var height2 = height - y10

  var bili = level
  var text = data.text //数据
  // data.biao = data.biao * ratio;
  for (var i = 0; i < text.length; i++) {
    text[i].num = text[i].num
  }

  var textLength = text.length //个数
  var paddingLeft = 30 * ratio //边距
  var cwidth = c.width - paddingLeft * 2
  var textEW = textLength > 1 ? Math.floor(cwidth / (textLength - 1)) : Math.floor(cwidth / 2) //步长

  ///绘制坚线线条
  ctx.beginPath()
  ctx.strokeStyle = 'gray'
  ctx.moveTo(paddingLeft, height - height)
  ctx.lineTo(paddingLeft, height - y10)
  ctx.stroke()
  ctx.closePath()

  var lastpu = ['HFBaseHF', 'LFHF', 'LFBaseLF', 'hv']
  var last = data.text[data.text.length - 1]

  var text = data.text

  var lastpubo = lastpu.indexOf(a) !== -1

  if (a == 'LFHF') {
    // alert(lastpubo);
    //prompt(JSON.stringify(data))
  }

  if (last && last.gb == '4g' && lastpubo) {
    var pu = []
    //ctx.beginPath()
    //ctx.strokeStyle = 'gray' //连线
    for (var i = 0; i < text.length; i++) {
      if (text[i].gb === last.gb) {
        var pu1 = []
        for (var i2 = i; i2 < text.length; i2++) {
          var hhc = text[i2 + 1]
          if (!hhc) {
            i = i2
            break
          }
          if (hhc.gb != last.gb) {
            i = i2
            break
          }
          i = i2

          let numx = text.length > 1 ? textEW * i + paddingLeft : textEW
          var numHeight = text[i].num
          var yll = height2 - numHeight * bili

          let numx2 = text.length > 1 ? textEW * (i2 + 1) + paddingLeft : textEW
          var numHeight2 = text[i2 + 1].num
          var yll2 = height2 - numHeight2 * bili

          pu1.push({
            i: i,
            x: numx,
            y: yll
          })

          pu1.push({
            i: i2 + 1,
            x: numx2,
            y: yll2
          })
        }

        pu.push(pu1)
      }
    }

    var texts = []

    pu.forEach(f => {
      var jsoni = {}
      var jsonipu = []
      ctx.beginPath()

      f.forEach(fx => {
        if (!jsoni[`_${fx.i}`]) {
          jsoni[`_${fx.i}`] = true
          jsonipu.push(fx)
        }
      })
      texts.push(jsonipu)
    })

    console.log(texts)
    console.log(`pu`)
    console.log(text)

    texts.forEach(f => {
      if (!Array.isArray(f)) {
        f = [f]
      }

      if (f.length > 0) {
        ctx.beginPath()
        ctx.strokeStyle = 'gray' //连线

        for (let fIndex = 0; fIndex < f.length; fIndex++) {
          let fx = f[fIndex]
          if (fIndex === 0) {
            ctx.moveTo(fx.x, fx.y)
          } else {
            ctx.lineTo(fx.x, fx.y)
          }
        }

        ctx.stroke()
        ctx.closePath()
      }
    })

    //ctx.stroke()
    //ctx.closePath()
  } else {
    ctx.beginPath()
    ctx.strokeStyle = 'gray' //连线
    for (let ivv = 0; ivv < text.length; ivv++) {
      let numx = text.length > 1 ? textEW * ivv + paddingLeft : textEW
      var numHeight = text[ivv].num
      var yll = height2 - numHeight * bili
      //ctx.stroke()
      if (ivv === 0) {
        ctx.moveTo(numx, yll)
      } else {
        ctx.lineTo(numx, yll)
        //  ctx.stroke()
      }
    }
    ctx.stroke()
    ctx.closePath()
  }
  //ctx.stroke();

  var isBase = text && text[0] && text[0].base

  if (isBase) {
    ctx.beginPath()
    var isLine = true
    ctx.strokeStyle = '#628DF2' //连线
    for (let i = 0; i < text.length; i++) {
      let numx = text.length > 1 ? textEW * i + paddingLeft : textEW
      var numHeight = text[i].base
      if (numHeight == -1) {
        continue
      }
      if (isLine) {
        isLine = false
        ctx.setLineDash([5])
      }

      var yll = height2 - numHeight * bili
      //ctx.stroke()
      if (i == 0) {
        ctx.moveTo(numx, yll)
      } else {
        ctx.lineTo(numx, yll)
        ctx.stroke()
      }
    }
    ctx.closePath()
  }

  ctx.setLineDash([0])

  if (last && last.gb && lastpubo && last.gb == '4g') {
    //打点 画圈
    for (var i = 0; i < text.length; i++) {
      //text[i].num=70;
      var x = text.length > 1 ? textEW * i + paddingLeft : textEW
      var y = height2 - text[i].num * bili
      var numText = text[i].numText ? text[i].numText : text[i].num

      if (text[i].gb == last.gb) {
        ctx.beginPath()
        ctx.arc(x, y, radius, startAngle, endAngle, anticlockwise)
        if (!c['_']) {
          c['_'] = []
        }
        c['_'].push({ x: x, y: y, num: text[i].num, numText: numText, color: text[i].color })
        ctx.fillStyle = text[i].color
        ctx.fill()
        ctx.closePath()
      } else {
      }
    }
  } else {
    //打点 画圈
    for (var i = 0; i < text.length; i++) {
      //text[i].num=70;
      var x = text.length > 1 ? textEW * i + paddingLeft : textEW
      var y = height2 - text[i].num * bili
      var numText = text[i].numText ? text[i].numText : text[i].num

      ctx.beginPath()
      ctx.arc(x, y, radius, startAngle, endAngle, anticlockwise)
      if (!c['_']) {
        c['_'] = []
      }
      c['_'].push({ x: x, y: y, num: text[i].num, numText: numText, color: text[i].color })
      ctx.fillStyle = text[i].color
      ctx.fill()
      ctx.closePath()
    }
  }

  //c.width - paddingLeft*2
  if (data.writebiao) {
    if (data.biao > 0) {
      ctx.beginPath()
      // 设置间距（参数为无限数组，虚线的样式会随数组循环）
      ctx.setLineDash([6, 6])
      // 移动画笔至坐标 x20 y20 的位置
      ctx.moveTo(paddingLeft, height2 - data.biao * bili)
      // 绘制到坐标 x20 y100 的位置
      ctx.lineTo(c.width - paddingLeft, height2 - data.biao * bili)

      ctx.fillStyle = data.footColor
      ctx.font = '30px serif '

      ctx.fillText(data.biao, c.width - 36, height2 - data.biao * bili + 10)
      ctx.fillStyle = data.biaoColor
      ctx.strokeStyle = data.biaoColor
      // 开始填充
      ctx.stroke()
      ctx.closePath()
      ctx.setLineDash([])
    }
  }

  // height = c.height;

  //日期竖线
  ctx.fillStyle = data.footColor
  ctx.strokeStyle = data.footColor
  for (var i = 0; i < text.length; i++) {
    var x = text.length > 1 ? textEW * i + paddingLeft : textEW
    ctx.beginPath()
    ctx.moveTo(x, height - y10)
    ctx.lineTo(x, height - y10 - y10h)

    ctx.closePath()
    ctx.stroke()
  }

  //横线
  ctx.beginPath()
  var x = paddingLeft
  ctx.lineWidth = 0.5
  ctx.moveTo(x, height - y10)
  ctx.lineTo(c.width - paddingLeft, height - y10)
  ctx.stroke()
  ctx.closePath()

  ctx.beginPath()

  var fontsizewidth = 10
  for (var i = 0; i < text.length; i++) {
    var fontText = text[i].date
    //fontsizewidth = ctx.measureText(fontText).width

    var xx = text.length > 1 ? textEW * i + paddingLeft : textEW
    var x = xx - fontsizewidth
    ctx.strokeStyle = data.footColor
    ctx.fillStyle = data.footColor
    ctx.font = '20px serif '
    var dateL = height //100

    ctx.fillText(fontText, x, dateL)
  }
  ctx.closePath()

  if (!data.writeLeftbiao) {
    for (var i = 0; i < data.leftbiao.length; i++) {
      var mk_data = data.leftbiao[i]
      var q = mk_data
      var x = 0
      // paddingLeft, height - (data.biao * bili + paddingLeft)
      var y = height2 - q * bili + 10
      ctx.font = '20px serif '
      ctx.fillText(q, x, y)
    }
  }
  if (data.leftbiaoattr) {
    for (var i = 0; i < data.leftbiaoattr.length; i++) {
      var mk_data = data.leftbiaoattr[i]
      var text = mk_data.text
      var value = mk_data.value
      if (value > max) {
        //continue;
      }

      var x = 0
      // paddingLeft, height - (data.biao * bili + paddingLeft)
      var y = height2 - value * bili + 10
      ctx.font = '20px serif '
      ctx.fillText(text, x, y)
      ctx.beginPath()
      ctx.strokeStyle = 'gray' //连线
      ctx.moveTo(x + paddingLeft, y)

      ctx.lineTo(x + paddingLeft + 10, y)
      ctx.stroke()
      ctx.closePath()
    }
  }

  ctx.closePath()

  //gb
}

export var Canvas_Chat = _Canvas_Chat

export var xinluTu = function(ele, first) {
  var ctx = ele.getContext('2d')
  var devicePixelRatio = window.devicePixelRatio || 1
  // 浏览器在渲染canvas之前存储画布信息的像素比
  var backingStoreRatio =
    ctx.webkitBackingStorePixelRatio ||
    ctx.mozBackingStorePixelRatio ||
    ctx.msBackingStorePixelRatio ||
    ctx.oBackingStorePixelRatio ||
    ctx.backingStorePixelRatio ||
    1
  // canvas的实际渲染倍率
  var ratio = devicePixelRatio / backingStoreRatio
}
