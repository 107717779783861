<template>
  <div>
    <div id="sleep" class="report-wrapper">
      <!-- top banner -->
      <div class="banner-wrapper">
        <!-- 健康值 -->
        <div class="health-count_box">
          <div class="circle-wrapper">
            <van-circle
              v-model="jcValue.currentRate"
              :rate="jcValue.currentRate"
              :color="jcValue.gradientColor"
              :stroke-width="85"
              :size="jcValue.size"
            />
            <div class="number-info">
              <h3>{{ report.sleepValue }}</h3>
              <p class="label">睡眠值</p>
            </div>
          </div>
          <div class="user-information">
            <h2>{{ userInfo.nick }}</h2>
            <p class="health--tips" v-if="report.hrv == 1">
              祝您健康每一天！
            </p>
            <p class="health--tips" v-else>
              您的睡眠时长不满4小时，无法为您提供更多完整数据，继续使用设备解锁更多吧~
            </p>
          </div>
        </div>
        <div v-if="load && report.hrv == '1'" class="sleep-chart_line">
          <slider ref="refslider" :Immunity="sleep" />
        </div>
      </div>
      <!-- 睡眠评价 -->
      <div class="evaluate-wrapper">
        <h4>睡眠评价</h4>
        <div class="sleep-evaluate" v-html="sleepEvaluationHTML"></div>
      </div>
      <!-- 睡眠监测 -->
      <div class="sleep-monitor">
        <h4><span>监测结果</span><span class="more" @click="handleGetMore">查看更多&nbsp;<van-icon name="arrow" /></span></h4>
        <div class="card-grid">
          <div class="item" v-for="(item,index) in sleepDetailpu" :key="index">
            <h5>{{item.title}}</h5>
            <p v-html="item.value"></p>
          </div>
        </div>
      </div>
    </div>
    <van-tabbar
      v-model="activeTab"
      @change="handleTabChange"
      active-color="#307B62"
      inactive-color="#a5a0a0"
    >
      <van-tabbar-item icon="wap-home">首页</van-tabbar-item>
      <van-tabbar-item icon="column">健康监测</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import slider from "./slider.vue";
import baseConfig from "@/base";
import dayjs from "dayjs";
import Vue from "vue";
import { whereStr, quickSort } from "@/utils/index.js";
import { Icon, Toast, Tabbar, TabbarItem } from "vant";
Vue.use(Icon);
Vue.use(Toast);
Vue.use(Tabbar);
Vue.use(TabbarItem);
export default {
  name: "Sleep",
  data() {
    return {
      activeTab: 1,
      report: {},
      userInfo: {
        nick: "",
      },
      load: false,
      jcValue: {
        //健康值
        currentRate: 0,
        gradientColor: {
          "0%": "#3fecff",
          "100%": "red",
        },
        text: `渐变颜色`,
        size: "120px",
      },
      answerSleepAD: "AnswerSleepAD",
      SleepAD: "SleepAD",
      noTagTable: "",
      sleepEvaluationHTML: ``,
      sleepDetailpu: [], //睡眠监测结果
      sleepDetail: [
        {
          hrv: "*",
          title: `睡眠总时长`,
          value: ``,
          code: `Sc_tst`,
        },
        {
          hrv: "*",
          title: `入睡需时`,
          value: ``,
          code: `st`,
        },
        {
          hrv: "*",
          title: `深睡眠`,
          value: ``,
          code: `dpr`,
        },

        {
          hrv: "1",
          title: `呼吸紊乱指数`,
          value: ``,
          code: `AHI`,
        },
        {
          hrv: "*",
          title: `基准心率`,
          value: ``,
          code: `hr`,
        },

        {
          hrv: "1",
          title: `心脏总能量`,
          value: ``,
          code: `TP`,
        },
      ],
    };
  },
  components: { slider },
  methods: {
    //底部导航切换
    handleTabChange(index) {
      if (index == 1) return;
      if (index == 0) {
        this.$router.push("/report4g?id=" + this.$route.query.id + "&token=" + this.$route.query.token);
      }
    },
    //点击查看更多
    handleGetMore() {
      this.$router.push("/sleepDetail?id=" + this.$route.query.id + "&token=" + this.$route.query.token);
    },
    //获取4g健康报告详情
    get4GReportDetail() {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      this.$axios
        .get(
          "https://health.ruinetworks.com/report14/health/lite4g/devReportData",
          {
            params: {
              id: this.$route.query.id,
            },
            headers: {
              user_token: this.$route.query.token,
            },
          }
        )
        .then((res) => {
          Toast.clear();
          if (res.data.success == "true") {
            const reportDetail = res.data.data[0];
            this.report = reportDetail;
            this.report.sleepValue = Math.round(reportDetail.sleepValue);
            // 姓名
            this.userInfo.nick = reportDetail.nick;

            //设置睡眠值
            this.jcValue.currentRate = reportDetail.sleepValue;
            this.setSleepSoreText(Math.round(reportDetail.sleepValue));

            if (reportDetail.hrv != "1") {
              this.load = true;
              return;
            }
            //获取当前7条最新数据
            this.get4G7ListReport();
          } else {
            Toast(res.data.data.msg);
          }
        });
    },
    //设置个人睡眠值
    setSleepSoreText(score) {
      var soreText = baseConfig.reportConfig.sleepValue;
      var TotalScore = score;
      soreText.forEach((u) => {
        var where = u.where.replace(/a/g, TotalScore);
        if (eval(where)) {
          this.jcValue = u;
          this.jcValue.text = TotalScore + "";
          this.jcValue.currentRate = TotalScore;
        }
      });
    },
    //睡眠评价
    sleepEvaluationFn(d) {
      //睡眠评价
      var th = this
      var sleepEvaluation = baseConfig.reportConfig.sleepEvaluation;
      var report = th.report;
      for (var i = 0; i < sleepEvaluation.length; i++) {
        var q = sleepEvaluation[i]

        var whereStr = q.where
        for (var k in report) {
          whereStr = whereStr.replace(`{${k}}`, report[k])
        }
        var bo = eval(whereStr)
        if (bo) {
          var text = q.text

          for (var k in report) {
            if (k == 'br') {
            }
            var replaceValue
            if (th[`M${k}`]) {
              replaceValue = th[`M${k}`](report[k], d)
            } else {
              replaceValue = report[k]
            }

            text = text.replace(`{${k}}`, replaceValue)
          }

          th.sleepEvaluationHTML = text
        }
      }
    },
    //睡眠监测结果
    sleepDetailFn(d) {
      var report = this.report;
      var sleepDetail = this.sleepDetail
      sleepDetail = JSON.parse(JSON.stringify(sleepDetail))
      var pu = []
      sleepDetail.forEach(x => {
        d.forEach(m => {
          if (x.hrv == '*' || x.hrv == report.hrv) {
            if (m.code == x.code || (x.code == 'TP' && m.code == 'TP/BaseTP')) {
              var q = this[`M${x.code}`]
              if (q) {
                m.v = q(m.v)
              }

              if (x.code == 'dpr') {
                var nm = Number(m.v.replace('%', ''))
                var drp = (report.Sc_tst / 100) * nm
                var ok = this.MSc_tst(drp)
                x.value = `${
                  m.arrow
                    ? `<div style="color:${m.color}"> ${ok} / ${m.v + ''} <span>${m.arrow}</span>  </div>`
                    : `${ok} / ${m.v + ''} `
                }`
                pu.push(x)
              } else if (x.code == 'TP' && m.code == 'TP/BaseTP') {
                x.value = `${
                  m.arrow
                    ? `<div style="color:${m.color}">${report[x.code] + ''}<span>${m.arrow}</span></div>`
                    : report[x.code]
                }`

                pu.push(x)
              } else if (x.code == 'hr') {
                x.value = `${
                  m.arrow
                    ? `<div style="color:${m.color}">${report[x.code] + '次/分钟'}<span>${m.arrow}</span></div>`
                    : `${report[x.code]}次/分钟`
                }`

                pu.push(x)
              } else {
                x.value = `${
                  m.arrow ? `<div style="color:${m.color}">${m.v + ''} <span>${m.arrow}</span>  </div>` : m.v
                }`
                pu.push(x)
              }
            }
          }
        })
      })
      this.sleepDetailpu = pu;
    },
    //获取当前最新的7条数据
    async get4G7ListReport() {
      let { result } = await this.$indexedDB.getSingle(
        "myReport",
        this.reportId
      );

      let reportPar = result.reportPar;
      //睡眠评价
      this.sleepEvaluationFn(reportPar)
      //睡眠监测结果
      this.sleepDetailFn(reportPar)

      this.$axios
        .get(
          "https://health.ruinetworks.com/report14/health/lite4g/devReportData",
          {
            params: {
              remark: "7",
              id: this.$route.query.id,
            },
            headers: {
              user_token: this.$route.query.token,
            },
          }
        )
        .then((res) => {
          Toast.clear();
          if (res.data.success == "true") {
            var hutudata = res.data.data;

            var pu = [];
            reportPar.forEach((x) => {
              var code = x.code;

              if (code == "sleepValue") {
                x.v = Math.round(x.v);
              }

              if (this[code]) {
                x.v2 = this[code](x.v);
              }

              if (x.type == "睡眠值") {
                var im = {};
                im.title = x.title;
                im.score = x.v2 ? x.v2 : x.v;
                im.text = x.text;
                im.color = x.color;
                im.notInit = "sleep2DetailInit";
                im.leftIco = true;
                im.showRightIco = true;
                im.showcanvas = true;
                im.canvas = true;

                im.rightclick = () => {};

                im.code = code;
                im.data = [];
                var canvasData = {
                  writeLeftbiao: false,
                  writebiao: true,
                  biao: 70,
                  biaoColor: "#4391ec",
                  footColor: "#000000",
                  leftbiao: [], //0, 30, 50, 70, 90, 100
                  text: [],
                };
                im.canvasData = canvasData;
                // 判定正常范围
                x.m.forEach((xm, xmi) => {
                  var wher = xm.where.replace(")", "").replace("[", "");
                  var mks = wher.split(",");
                  var combet = wher.split(",");
                  var imdata = {};
                  if (x.code == "Sc_tst") {
                    if (mks[0] > 0) {
                      mks[0] = mks[0] / 60;
                    }
                    if (mks[1] > 0) {
                      mks[1] = mks[1] / 60;
                    }
                  }
                  if (xmi === 0) {
                    imdata.num = mks[0];
                    imdata.num2 = mks[1];
                    canvasData.leftbiao.push(imdata.num);
                    canvasData.leftbiao.push(imdata.num2);
                  } else {
                    imdata.num = "";
                    imdata.num2 = mks[1];
                    canvasData.leftbiao.push(imdata.num2);
                  }

                  var left =
                    ((Number(x.v) - Number(combet[0])) /
                      (Number(combet[1]) - Number(combet[0]))) *
                      100 +
                    "%";
                  imdata.left = left;
                  imdata.color = xm.color;
                  imdata.text = xm.text;
                  imdata.level = xm.level;

                  im.data.push(imdata);

                  hutudata.forEach((dic) => {
                    whereStr(code, xm.where, dic, (dicvalue) => {
                      let date = dayjs(dic.FinishTime).format("MM-DD");
                      canvasData.text.push({
                        FinishTime: dic.FinishTime,
                        color: xm.color,
                        date: date,
                        num: dicvalue,
                      });
                    });
                  });
                });

                im.canvasData.text = quickSort(
                  im.canvasData.text,
                  "FinishTime"
                );
                x.im = im;

                pu.push(x);
                //pu.push(x)
              }
            });

            this.load = true;
            this.sleep = pu[0].im;
          } else {
            Toast(res.data.data.msg);
          }
        });
    },
    MStartTime(a) {
      return dayjs(a).format('HH:mm')
    },
    MFinishTime(a) {
      return dayjs(a).format('HH:mm')
    },
    Mrft_rst(x, d) {
      var sm = (x / 60 / 60 + '').split('.')[0]

      var ccc = x - Number(sm) * 60 * 60

      var fen = (ccc / 60 + '').split('.')[0]
      return `${sm}小时${fen}分钟`
    },
    MSc_tst(x, d) {
      var sm = (x / 60 + '').split('.')[0]
      var ch = Number(sm) * 60
      var fen = ((x % 60) + '').split('.')[0]
      return `${sm}小时${fen}分钟`
    },
    Msffcy2(x, d) {
      var s = null
      if (d) {
        var ss = d.filter(a => a.code == 'sffcy2')
        if (ss.length > 0) {
          s = ss[0]
        }
      }
      var q = Number(x + '').toFixed(2)
      var ch = q.split('.')
      if (ch.length > 1) {
        if (s) {
          return `${ch[1]}%(<span style="color:${s.color}">${s.text}</span>)`
        }
        return `${ch[1]}%`
      }
      if (s) {
        return `${q}(<span style="color:${s.color}">${s.text}</span>)`
      }
      return q
    },
    MSleepContinuity(x, d) {
      var s = null
      if (d) {
        var ss = d.filter(a => a.code == 'SleepContinuity')
        if (ss.length > 0) {
          s = ss[0]
        }
      }
      var q = parseInt(x * 100)
      if (s) {
        return `${q}%(<span style="color:${s.color}">${s.text}</span>)`
      }
      return q
    },

    Mslr(x, d) {
      var s = null
      if (d) {
        var ss = d.filter(a => a.code == 'slr')
        if (ss.length > 0) {
          s = ss[0]
        }
      }
      if (s) {
        return `${x}%(<span style="color:${s.color}">${s.text}</span>)`
      }

      return `${x}%`
    },
    Mdpr(x, d) {
      var s = null
      if (d) {
        var ss = d.filter(a => a.code == 'dpr')
        if (ss.length > 0) {
          s = ss[0]
        }
      }
      if (s) {
        return `${x}%(<span style="color:${s.color}">${s.text}</span>)`
      }

      return `${x}%`
    },

    MAwakeTimes(x, d) {
      var s = null
      if (d) {
        var ss = d.filter(a => a.code == 'AwakeTimes')
        if (ss.length > 0) {
          s = ss[0]
        }
      }
      if (s) {
        return `${x}次(<span style="color:${s.color}">${s.text}</span>)`
      }
      return `${x}次`
    },
    Mofbdc2(x, d) {
      var s = null
      if (d) {
        var ss = d.filter(a => a.code == 'ofbdc2')
        if (ss.length > 0) {
          s = ss[0]
        }
      }
      if (s) {
        return `${x}次(<span style="color:${s.color}">${s.text}</span>)`
      }
      return `${x}次`
    },
    Mbrz(x, d) {
      var s = null
      if (d) {
        var ss = d.filter(a => a.code == 'brz')
        if (ss.length > 0) {
          s = ss[0]
        }
      }
      if (s) {
        return `${x}次` //(<span style="color:${s.color}">${s.text}</span>)
      }
      return `${x}次`
    },
    MAHI(x, d) {
      var s = null
      if (d) {
        var ss = d.filter(a => a.code == 'AHI')
        if (ss.length > 0) {
          s = ss[0]
        }
      }
      if (s) {
        return `${x}(<span style="color:${s.color}">${s.text}</span>)`
      }
      return `${x}`
    },

    MTP(x, d) {
      var s = null
      if (d) {
        var ss = d.filter(a => a.code == 'TP/BaseTP')
        if (ss.length > 0) {
          s = ss[0]
        }
      }
      if (s) {
        return `${x}(<span style="color:${s.color}">${s.text}</span>)`
      }
      return `${x}`
    },

    MLF(x, d) {
      var s = null
      if (d) {
        var ss = d.filter(a => a.code == 'LF/BaseLF')
        if (ss.length > 0) {
          s = ss[0]
        }
      }
      if (s) {
        return `${x}(<span style="color:${s.color}">${s.text}</span>)`
      }
      return `${x}`
    },

    MLFHF(x, d) {
      var s = null
      if (d) {
        var ss = d.filter(a => a.code == 'LFHF')
        if (ss.length > 0) {
          s = ss[0]
        }
      }
      if (s) {
        return `${x}(<span style="color:${s.color}">${s.text}</span>)`
      }
      return `${x}`
    },

    Msdnn(x, d) {
      var s = null
      if (d) {
        var ss = d.filter(a => a.code == 'sdnn')
        if (ss.length > 0) {
          s = ss[0]
        }
      }
      if (s) {
        return `${x}(<span style="color:${s.color}">${s.text}</span>)`
      }
      return `${x}`
    },
    Mhr(x, d) {
      var s = null
      if (d) {
        var ss = d.filter(a => a.code == 'hr')
        if (ss.length > 0) {
          s = ss[0]
        }
      }
      if (s) {
        return `${x}次/分钟(<span style="color:${s.color}">${s.text}</span>)`
      } else {
        return x
      }
    },
    Mbr(x, d) {
      var s = null
      if (d) {
        var ss = d.filter(a => a.code == 'br')
        if (ss.length > 0) {
          s = ss[0]
        }
      }
      if (s) {
        return `${s.v}次/分钟(<span style="color:${s.color}">${s.text}</span>)`
      } else {
        return x
      }
    },
    Mst(x) {
      var sm = (x / 60 / 60 + '').split('.')[0]
      var fen = (x - Number(sm) * 60 * 60) / 60 + ''
      fen = Number(fen).toFixed(0)

      var miao = x - Number(sm) * 60 * 60 - fen * 60
      var miaoText = ``
      if (miao > 0) {
        miaoText = `${miao}秒`
      }

      return `${sm}小时${fen}分钟${miaoText}`
    },
  },
  mounted() {
    //记录报告id
    this.reportId = this.$route.query.id;
    this.get4GReportDetail();
  },
};
</script>

<style>

#sleep .right-operate .van-cell__right-icon {
  display: none;
}
</style>
<style lang="less" scoped>
.report-wrapper {
  box-sizing: border-box;
  padding-bottom: 50px;
  .banner-wrapper {
    position: relative;
    min-height: 300px;
    background: url("~@/assets/images/day/banner.png") no-repeat center / 100%
      100%;
    padding: 10px 16px;
    .health-count_box {
      background: rgba(229, 255, 242, 0.2);
      min-height: 100px;
      padding: 16px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      .circle-wrapper {
        position: relative;
        width: 120px;
        height: 120px;
        flex-shrink: 0;
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        .number-info {
          position: absolute;
          text-align: center;
          h3 {
            font-size: 34px;
            font-weight: 500;
            color: #fff;
          }
          .label {
            color: #fff;
            font-size: 14px;
          }
        }
      }
      .user-information {
        h2 {
          font-size: 22px;
          color: #fff;
          font-weight: 600;
          margin-bottom: 6px;
        }
        .health--tips {
          line-height: 21px;
          font-size: 14px;
          font-weight: 500;
          color: #fff;
        }
      }
    }
    .sleep-chart_line {
      margin-top: 20px;
      background: #fff;
      border-radius: 12px;
      padding: 10px;
      min-height: 50px;
    }
  }
  .evaluate-wrapper {
    padding:10px 16px;
    h4 {
      font-size: 16px;
      font-weight: 500;
    }
    .sleep-evaluate {
      background: #fff;
      border-radius: 12px;
      margin-top: 6px;
      font-size: 14px;
      padding: 10px;
    }
  }
  .sleep-monitor {
    padding: 10px 16px;
    h4 {
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      .more {
        color: #307B62;
      }
    }
    .card-grid {
      margin-top: 6px;
      display: grid;
      grid-template-columns: repeat(2,1fr);
      grid-gap: 16px;
      .item {
        padding: 10px;
        background: #fff;
        border-radius: 12px;
        > h5 {
          font-size: 14px;
          font-weight: 600;
        }
        > p {
          font-size: 14px;
        }
      }
    }
  }
}
</style>

