<template>
  <div>
    <div class="report-wrapper">
      <!-- top banner -->
      <div class="banner-wrapper">
        <!-- 日期 -->
        <p class="report-dateTime">
          <img :src="require('@/assets/images/day/date-icon.png')" alt="" />{{
            report.FinishTime
          }}
        </p>
        <!-- 健康值 -->
        <div class="health-count_box">
          <div class="circle-wrapper">
            <health-circle
              :health-count="jcValue.currentRate"
              :gradient-color="jcValue.gradientColor"
            ></health-circle>
            <div class="number-info">
              <h3>{{ report.TotalScore }}</h3>
              <p class="label">健康值</p>
            </div>
          </div>
          <div class="user-information">
            <h2>{{ userInfo.nick }}</h2>
            <p class="health--tips" v-if="report.hrvreport == 1">
              祝您健康每一天！
            </p>
            <p class="health--tips" v-else>
              您的睡眠时长不满4小时，无法为您提供更多完整数据，继续使用设备解锁更多吧~
            </p>
          </div>
        </div>
        <!-- 报告提示 -->
        <p class="report-tips">
          <span>本次检测结果不作为疾病的专业临床诊断依据。</span>
          <span @click="handleNotice"
            ><van-icon name="warning" size="16" />&nbsp;用户须知</span
          >
        </p>
        <!-- 心脏、心率 -->
        <tpValue ref="refTpValue"></tpValue>
        <!-- 睡眠情况 -->
        <LabelValue ref="refLabelValue" />
      </div>
      <!-- 卡片信息 -->
      <div class="card-wrapper">
        <chronic ref="refMangBingValue" />
        <div class="card-item" @click="handleMinYiPH">
          <h3>免疫平衡<img :src="require('@/assets/images/day/my.png')" /></h3>
          <div class="circle-box">
            <circular ref="minyiref" />
          </div>
          <p class="info">
            {{
              cardConfig.imText.text
                ? `您的免疫平衡${cardConfig.imText.text}`
                : ``
            }}
          </p>
        </div>
        <div class="card-item" @click="handleQingxuXL">
          <h3>情绪心理<img :src="require('@/assets/images/day/qx.png')" /></h3>
          <div class="circle-box">
            <circular ref="qingxuref" />
          </div>
          <p class="info">
            {{
              cardConfig.qxxlText.text
                ? `您的情绪状态${cardConfig.qxxlText.text}`
                : ``
            }}
          </p>
        </div>
        <div class="card-item" @click="handleGanranFX">
          <h3>感染风险<img :src="require('@/assets/images/day/gr.png')" /></h3>
          <div class="circle-box">
            <circular ref="gangranref" />
          </div>
          <p class="info">
            {{
              cardConfig.grText.text
                ? `您的感染风险${cardConfig.grText.text}`
                : ``
            }}
          </p>
        </div>
      </div>
      <!-- 精选文章 -->
      <div class="article-wrapper">
        <h4>精选文章</h4>
        <div class="article-list">
          <div class="article-item" @click="handleArticleDetail(item)" v-for="(item,index) in articleList" :key="index">
            <img :src="item.thumb_url" alt="">
            <h5>{{item.title}}</h5>
          </div>
        </div>
      </div>
    </div>
    <van-tabbar
      v-model="activeTab"
      @change="handleTabChange"
      active-color="#307B62"
      inactive-color="#a5a0a0"
    >
      <van-tabbar-item icon="wap-home">首页</van-tabbar-item>
      <van-tabbar-item icon="column">健康监测</van-tabbar-item>
    </van-tabbar>
  </div>
</template>


<script>
import HealthCircle from "./HealthCircle.vue";
import Circular from "./Circular.vue";
import tpValue from "./tpValue.vue";
import LabelValue from "./LabelValue.vue";
import chronic from "./Chronic.vue";
import dayjs from "dayjs";
import baseConfig from "@/base";
import Vue from "vue";
import { Icon, Toast, Tabbar, TabbarItem } from "vant";

Vue.use(Icon);
Vue.use(Toast);
Vue.use(Tabbar);
Vue.use(TabbarItem);
export default {
  name: "Report4G",
  data() {
    return {
      activeTab: 0,
      reportId: "",
      userInfo: {
        nick: "",
      },
      report: {
        hrvreport: 0,
        TotalScore: "",
        FinishTime: "",
        dic: {},
        reportCount: 0,
      },
      jcValue: {
        //健康值
        currentRate: 0,
        gradientColor: {
          "0%": "#3fecff",
          "100%": "red",
        },
        text: `渐变颜色`,
        size: "120px",
      },
      cardConfig: {
        imText: {}, //免疫平衡
        qxxlText: {}, //情绪心理
        grText: {}, //感染风险
      },
      articleList: []
    };
  },
  components: { HealthCircle, Circular, chronic, tpValue, LabelValue },
  methods: {
    //底部导航切换
    handleTabChange(index) {
      if (index == 0) return;
      if (index == 1) {
        this.$router.push("/sleep?id=" + this.$route.query.id + "&token=" + this.$route.query.token);
      }
    },
    //用户须知
    handleNotice() {
      this.$router.push("/notice");
    },
    //获取文章列表
    getArticleList() {
      this.articleList = [];
      this.$axios
        .get("https://wechat.ruinetworks.com/api/channel/info", {
          params: {
            page: 1,
            page_size: 10,
            appId: 'wx8bc182e9df3483f6',
            typeId: "ba2a592d-0c8b-4524-ade3-2b7114de1f08",
          }
        })
        .then((res) => {
          const detail = res.data;
          if (detail.success == "true") {
            const articleList = detail.data.list;
            articleList.forEach((el) => {
              const articleDetail = JSON.parse(el.content)[0];
              this.articleList.push({
                title: articleDetail.title,
                digest: articleDetail.digest,
                url: articleDetail.url,
                thumb_url: articleDetail.thumb_url,
              });
            });
          } else {
            Toast("查询失败");
          }
        });
    },
    //文章详情
    handleArticleDetail(detail) {
      window.location.href = detail.url;
    },
    //获取4g健康报告详情
    get4GReportDetail() {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      this.$axios
        .get(
          // "https://health.ruinetworks.com/api/health/lite4g/reportSenvivData",
          "https://health.ruinetworks.com/report14/health/lite4g/devReportData",
          {
            params: {
              // id: "109339e2-c7b6-4ba7-9019-a6e8f3ebfabd",
              id: this.$route.query.id,
            },
            headers: {
              user_token: this.$route.query.token,
            },
          }
        )
        .then((res) => {
          Toast.clear();
          if (res.data.success == "true") {
            const reportDetail = res.data.data[0];

            this.report.TotalScore = reportDetail.hv;
            this.report.hrvreport = Number(reportDetail.hrv);
            this.report.FinishTime = dayjs(reportDetail.FinishTime).format(
              "YYYY-MM-DD"
            );
            this.report.reportCount = reportDetail.reportCount;
            // 姓名
            this.userInfo.nick = reportDetail.nick;
            //设置健康值
            this.setHealthSoreText();
            //心脏总能量-心率
            var refTpValue = this.$refs.refTpValue;
            if (refTpValue) {
              refTpValue.init(reportDetail);
            }
            // 标签： 睡眠情况等标签
            var refLabelValue = this.$refs.refLabelValue;
            if (refLabelValue) {
              refLabelValue.init(reportDetail);
            }

            //调用格式转换
            this.getReportPar(reportDetail).then((res) => {
              var minyiref = this.$refs.minyiref;
              var qingxuref = this.$refs.qingxuref;
              var gangranref = this.$refs.gangranref;
              //慢性病防控
              var refMangBingValue = this.$refs.refMangBingValue;
              if (refMangBingValue) {
                refMangBingValue.init(reportDetail, res);
              }
              for (let i = 0; i < res.length; i++) {
                var qd = res[i];

                //免疫力
                if (qd.code == "im") {
                  this.cardConfig.imText = qd;

                  //免疫平衡
                  if (reportDetail.hrv == "1") {
                    minyiref.score = reportDetail.im;
                    minyiref.scoreText = "";
                  } else {
                    minyiref.scoreText = "--";
                  }
                  minyiref.init();
                }

                //感染
                if (qd.code == "gr") {
                  this.cardConfig.grText = qd;

                  if (reportDetail.hrv == "1") {
                    gangranref.score = reportDetail.gr;
                    gangranref.scoreText = "";
                  } else {
                    gangranref.scoreText = "--";
                  }
                  gangranref.init();
                }

                //情绪心理
                if (qd.code == "qxxl") {
                  this.cardConfig.qxxlText = qd;
                  if (reportDetail.hrv == "1") {
                    qingxuref.score = reportDetail.qxxl;
                    qingxuref.scoreText = "";
                  } else {
                    qingxuref.scoreText = "--";
                  }
                  qingxuref.init();
                }
              }

              if (reportDetail.hrv == "0") {
                this.cardConfig.imText = {};
                this.cardConfig.grText = {};
                this.cardConfig.qxxlText = {};
              }
            });
          } else {
            Toast(res.data.data.msg);
          }
        });
    },
    //设置个人健康值
    setHealthSoreText() {
      var soreText = baseConfig.reportConfig.soreText;
      var TotalScore = this.report.TotalScore;

      soreText.forEach((u) => {
        var where = u.where.replace(/a/g, TotalScore);
        if (eval(where)) {
          this.jcValue = u;
          this.jcValue.text = TotalScore + "";
          this.jcValue.currentRate = TotalScore;
        }
      });
    },
    //免疫平衡
    handleMinYiPH() {
      this.$router.push("/Immunity?id=" + this.$route.query.id + "&token=" + this.$route.query.token);
    },
    //情绪心理
    handleQingxuXL() {
      this.$router.push("/feeling?id=" + this.$route.query.id + "&token=" + this.$route.query.token);
    },
    //感染风险
    handleGanranFX() {
      this.$router.push("/infection?id=" + this.$route.query.id + "&token=" + this.$route.query.token);
    },
    //报告格式生成 {v: 值, arrow: 箭头, where: 范围, color: 颜色， text: 说明 .....}
    async getReportPar(detail) {
      var dic = detail;
      var puWhere = [];
      var xcode = baseConfig.reportConfig.reportPar;

      xcode.forEach((b) => {
        var field = b.code;

        var dicvalue = dic[field];
        if (!dicvalue) {
          var fields = field.match(/\w+/g);
          let fieldValue = field;
          fields.forEach((f) => {
            var qdicValue = dic[f];
            if (qdicValue != null) {
              fieldValue = fieldValue.replace(f, qdicValue);
            }
          });

          if (fieldValue.indexOf("hc") != -1) {
          }

          try {
            dicvalue = eval(fieldValue);
          } catch (e) {}
        }

        if (b.where) {
          for (let i = 0; i < b.where.length; i++) {
            var m = b.where[i];
            var qbds = m.where
              .replace(/\[/, "x>=")
              .replace(",", "&&")
              .replace(")", ">x")
              .replace("]", ">=x")
              .replace(/x/g, dicvalue)
              .replace("∞>", `${dicvalue + 1}>`)
              .replace("∞=", `${dicvalue}=`)
              .replace(field, dicvalue);
            try {
              var bo = eval(qbds);
              if (bo) {
                puWhere.push({
                  v: dicvalue,
                  arrow: m.arrow,
                  where: m.where,
                  color: m.color,
                  text: m.text,
                  level: m.level,
                  title: b.title,
                  code: b.code,
                  type: b.type,
                  m: b.where,
                });
                break;
              }
            } catch (e) {}
          }
        }
      });

      let data0 = {
        id: this.reportId,
        reportPar: puWhere,
      };

      //存储indexedDB
      let { result } = await this.$indexedDB.getSingle(
        "myReport",
        this.reportId
      );

      if (result) {
        //走更新逻辑
        this.$indexedDB.updateDB("myReport", data0);
      } else {
        //走新增逻辑
        this.$indexedDB.addData("myReport", data0);
      }

      return new Promise((resolve, reject) => {
        resolve(puWhere);
      });
    },
  },
  mounted() {
    //记录报告id
    this.reportId = this.$route.query.id;
    //获取报告详情
    this.get4GReportDetail();
    //获取文章列表
    this.getArticleList();
  },
};
</script>


<style lang="less" scoped>
.report-wrapper {
  box-sizing: border-box;
  padding-bottom: 50px;
  .banner-wrapper {
    position: relative;
    min-height: 300px;
    background: url("~@/assets/images/day/banner.png") no-repeat center / 100%
      100%;
    padding: 10px 16px;
    .report-dateTime {
      font-size: 16px;
      color: #fff;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      padding: 10px;
      > img {
        width: 18px;
        padding-right: 6px;
      }
    }
    .health-count_box {
      background: rgba(229, 255, 242, 0.2);
      min-height: 100px;
      padding: 16px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      .circle-wrapper {
        position: relative;
        width: 120px;
        height: 120px;
        flex-shrink: 0;
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        .number-info {
          position: absolute;
          text-align: center;
          h3 {
            font-size: 34px;
            font-weight: 500;
            color: #fff;
          }
          .label {
            color: #fff;
            font-size: 14px;
          }
        }
      }
      .user-information {
        h2 {
          font-size: 22px;
          color: #fff;
          font-weight: 600;
          margin-bottom: 6px;
        }
        .health--tips {
          line-height: 21px;
          font-size: 14px;
          font-weight: 500;
          color: #fff;
        }
      }
    }
    .report-tips {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      font-weight: 400;
      color: #fff;
      padding: 6px 0;
    }
  }
  .card-wrapper {
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    .card-item {
      background: #fff;
      box-shadow: 2px 3px 0px 2px #d8ebe1;
      border-radius: 12px;
      padding: 10px;
      h3 {
        font-size: 16px;
        font-weight: 500;
        > img {
          width: 16px;
          vertical-align: middle;
          padding-left: 6px;
        }
      }
      .circle-box {
        height: 90px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .info {
        color: #2e3142;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
  .article-wrapper {
    padding: 10px 20px;
    h4 {
      font-size: 16px;
      font-weight: 600;
    }
    .article-list {
      margin-top: 10px;
      background: #fff;
      border-radius: 10px;
      padding: 0 10px;
      .article-item {
        padding: 10px;
        display: flex;
        border-bottom: 1px solid #cbccd4;
        &:last-child {
          border-bottom: 0;
        }
        > img {
          width: 100px;
          object-fit: contain;
        }
        h5 {
          font-size: 14px;
          margin-left: 6px;
        }
      }
    }
  }
}
</style>

