<template>
  <div class="main-container">
    <div class="banner-wrapper">
      <h2 class="title">红外健康监测报告</h2>
      <p class="report-time">{{reportTime}}</p>
      <div class="user-info">
        <div class="avatar-img">
          <img :src="require('@/assets/images/logo.png')" alt="" />
        </div>
        <p class="user-name">
          {{ reportDetail.healthForm.userInfo.realName }}
        </p>
      </div>
    </div>
    <div class="report-wrapper">
      <div class="health-guide">-本次功能评估报告仅对本次的数字信息负责-</div>
      <!-- 基本信息 -->
      <div class="report-detail">
        <div class="title relative">客户健康测评信息</div>
        <!-- 基础信息 -->
        <div class="item">
          <h3>基础信息</h3>
          <div class="flex-row">
            <p>
              <span>姓名：</span>
              <span>{{ reportDetail.healthForm.userInfo.realName }}</span>
            </p>
            <p>
              <span>性别：</span>
              <span>{{ reportDetail.healthForm.userInfo.sex }}</span>
            </p>
          </div>
          <div class="flex-row">
            <p>
              <span>年龄：</span>
              <span>{{ reportDetail.healthForm.userInfo.age }}</span>
            </p>
            <p>
              <span>电话：</span>
              <span>{{ reportDetail.healthForm.userInfo.mobile }}</span>
            </p>
          </div>
          <div class="flex-row">
            <p>
              <span>身高：</span>
              <span>{{ reportDetail.healthForm.basicInfo.height }}cm</span>
            </p>
            <p>
              <span>体重：</span>
              <span>{{ reportDetail.healthForm.basicInfo.weight }}kg</span>
            </p>
          </div>
          <div class="flex-row">
            <p>
              <span>BMI：</span>
              <span>{{ reportDetail.healthForm.basicInfo.BMI }}</span>
            </p>
            <p>
              <span>腰围：</span>
              <span>{{ reportDetail.healthForm.basicInfo.waistline }}cm</span>
            </p>
          </div>
          <div class="flex-row">
            <p>
              <span>体温：</span>
              <span>{{ reportDetail.healthForm.basicInfo.temperature }}℃</span>
            </p>
            <p>
              <span>血糖：</span>
              <span
                >{{ reportDetail.healthForm.basicInfo.bloodSugar }}nmol/L</span
              >
            </p>
          </div>
        </div>
        <!-- 数字脉诊信息 -->
        <div class="item">
          <h3>数字脉诊信息</h3>
          <div class="flex-row">
            <p>
              <span class="w-md">心率/左：</span>
              <span
                >{{
                  reportDetail.healthForm.shuZiMaiZhen.left.heartRate
                }}次/min</span
              >
            </p>
            <p>
              <span class="w-md">心率/右：</span>
              <span
                >{{
                  reportDetail.healthForm.shuZiMaiZhen.right.heartRate
                }}次/min</span
              >
            </p>
          </div>
          <div class="flex-row">
            <p>
              <span class="w-md">低压/左：</span>
              <span
                >{{
                  reportDetail.healthForm.shuZiMaiZhen.left.diastolic
                }}mmHg</span
              >
            </p>
            <p>
              <span class="w-md">低压/右：</span>
              <span
                >{{
                  reportDetail.healthForm.shuZiMaiZhen.right.diastolic
                }}mmHg</span
              >
            </p>
          </div>
          <div class="flex-row">
            <p>
              <span class="w-md">高压/左：</span>
              <span
                >{{
                  reportDetail.healthForm.shuZiMaiZhen.left.systolic
                }}mmHg</span
              >
            </p>
            <p>
              <span class="w-md">高压/右：</span>
              <span
                >{{
                  reportDetail.healthForm.shuZiMaiZhen.right.systolic
                }}mmHg</span
              >
            </p>
          </div>
        </div>
        <!-- 臂部脉诊信息 -->
        <div class="item">
          <h3>臂部脉诊信息</h3>
          <div class="flex-row">
            <p>
              <span class="w-md">心率/左：</span>
              <span
                >{{
                  reportDetail.healthForm.biBuMaiZhen.left.heartRate
                }}次/min</span
              >
            </p>
            <p>
              <span class="w-md">心率/右：</span>
              <span
                >{{
                  reportDetail.healthForm.biBuMaiZhen.right.heartRate
                }}次/min</span
              >
            </p>
          </div>
          <div class="flex-row">
            <p>
              <span class="w-md">低压/左：</span>
              <span
                >{{
                  reportDetail.healthForm.biBuMaiZhen.left.diastolic
                }}mmHg</span
              >
            </p>
            <p>
              <span class="w-md">低压/右：</span>
              <span
                >{{
                  reportDetail.healthForm.biBuMaiZhen.right.diastolic
                }}mmHg</span
              >
            </p>
          </div>
          <div class="flex-row">
            <p>
              <span class="w-md">高压/左：</span>
              <span
                >{{
                  reportDetail.healthForm.biBuMaiZhen.left.systolic
                }}mmHg</span
              >
            </p>
            <p>
              <span class="w-md">高压/右：</span>
              <span
                >{{
                  reportDetail.healthForm.biBuMaiZhen.right.systolic
                }}mmHg</span
              >
            </p>
          </div>
        </div>
        <!-- 检查报告 -->
        <div class="item">
          <h3>检查报告</h3>
          <div class="description" v-html="reportDetail.healthForm.report"></div>
          <p class="poster">
            测评师：{{ reportDetail.healthForm.basicTester }}
          </p>
          <p class="poster-time">
            报告日期：{{ reportDetail.healthForm.baseDate }}
          </p>
        </div>
      </div>
      <!-- 红外信息 -->
      <div class="report-detail">
        <div class="title relative">亚健康红外测评报告</div>
        <!-- 基础信息 -->
        <div class="item">
          <h3>红外热成像技术检测信息</h3>
          <div class="grid-images">
            <div class="grid-item">
              <img :src="reportDetail.irImages.frontTop.imageUrl" alt="" />
              <p>正面上部</p>
            </div>
            <div class="grid-item">
              <img :src="reportDetail.irImages.rearTop.imageUrl" alt="" />
              <p>背面上部</p>
            </div>
            <div class="grid-item">
              <img :src="reportDetail.irImages.frontLow.imageUrl" alt="" />
              <p>正面下部</p>
            </div>
            <div class="grid-item">
              <img :src="reportDetail.irImages.rearLow.imageUrl" alt="" />
              <p>背面下部</p>
            </div>
            <div class="grid-item">
              <img :src="reportDetail.irImages.leftTop.imageUrl" alt="" />
              <p>左侧上部</p>
            </div>
            <div class="grid-item">
              <img :src="reportDetail.irImages.leftLow.imageUrl" alt="" />
              <p>左侧下部</p>
            </div>
            <div class="grid-item">
              <img :src="reportDetail.irImages.rightTop.imageUrl" alt="" />
              <p>右侧上部</p>
            </div>
            <div class="grid-item">
              <img :src="reportDetail.irImages.rightLow.imageUrl" alt="" />
              <p>右侧下部</p>
            </div>
            <div class="grid-item">
              <img :src="reportDetail.irImages.headInHands.imageUrl" alt="" />
              <p>正面抱头</p>
            </div>
          </div>
        </div>
        <!-- 测评报告 -->
        <div class="item">
          <h3>测评报告</h3>
          <div class="description" v-html="reportDetail.irReport.infraredReport"></div>
        </div>
        <!-- 调理产品组合 -->
        <div class="item">
          <h3>调理产品组合</h3>
          <div class="description" v-html="reportDetail.irReport.product"></div>
        </div>
        <!-- 家庭调理方案 -->
        <div class="item">
          <h3>家庭调理方案</h3>
          <div class="description" v-html="reportDetail.irReport.suggest"></div>
        </div>
        <!-- 注意事项 -->
        <div class="item">
          <h3>注意事项</h3>
          <div class="description" v-html="reportDetail.irReport.attention"></div>
        </div>
        <!-- 饮食建议 -->
        <div class="item">
          <h3>饮食建议</h3>
          <div class="description" v-html="reportDetail.irReport.diet"></div>
        </div>
        <!-- 好转反应-->
        <div class="item">
          <h3>好转反应</h3>
          <div class="description" v-html="reportDetail.irReport.feedback"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Icon, Toast } from "vant";
Vue.use(Icon);
Vue.use(Toast);
export default {
  name: "ReportDetail",
  data() {
    return {
      reportTime: "",
      reportDetail: {
        healthForm: {},
        irImages: {},
        irReport: {},
      },
    };
  },
  methods: {
    //获取红外报告详情
    getHWReportDetail() {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      this.$axios
        .get(
          "https://health.ruinetworks.com/api/xkl/IRReportDetail",
          {
            params: {
              id: this.$route.query.id,
            },
            headers: {
              user_token: this.$route.query.token,
            },
          }
        )
        .then((res) => {
          Toast.clear();

          if (res.data.success == "true") {
            this.reportTime = res.data.data.health.reportDate;
            const hongwaiInfo = res.data.data.health;
            const baseInfo = JSON.parse(hongwaiInfo.basicInfo);
            this.reportDetail.healthForm = baseInfo.healthForm;
            const hongwaiImages = JSON.parse(hongwaiInfo.irimages);
            this.reportDetail.irImages = hongwaiImages.infraredRay.IRImages;
            const reportHtmlInfo = JSON.parse(hongwaiInfo.irreport);
            this.reportDetail.irReport = reportHtmlInfo.analystReports;
          } else {
            Toast(res.data.data.msg);
          }
        });
    },
  },
  mounted() {
    //获取报告详情
    this.getHWReportDetail();
  },
};
</script>

<style lang="less" scoped>
.main-container {
  .banner-wrapper {
    position: relative;
    height: 120px;
    background: linear-gradient(180deg, #0cc2a9 0%, #49cdab 100%);
    border-radius: 0 0 30px 30px;
    padding: 10px 24px;
    .title {
      color: #fff;
      font-size: 22px;
      font-weight: 500;
    }
    .report-time {
      font-size: 14px;
      color: #fff;
      font-weight: 400;
      line-height: 36px;
    }
    .user-info {
      position: absolute;
      right: 20px;
      top: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .avatar-img {
        width: 38px;
        height: 38px;
        border-radius: 8px;
        background: #f2f2f2;
        overflow: hidden;
        > img {
          width: 100%;
          height: 100%;
        }
      }
      .user-name {
        margin-top: 6px;
        text-align: center;
        color: #fff;
        font-size: 18px;
        font-weight: 500;
      }
    }
  }
  .report-wrapper {
    position: relative;
    margin-top: -80px;
    padding: 15px;
    .health-guide {
      text-align: center;
      font-size: 12px;
      line-height: 36px;
      color: #fff;
    }
    .report-detail {
      padding: 10px;
      background: #fff;
      box-shadow: 0px 4px 12px 0px rgba(229, 229, 229, 0.5);
      border-radius: 12px;
      margin-bottom: 16px;
      .title {
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 16px;
        .title-icon {
          width: 24px;
          margin-right: 10px;
        }
        .health-score {
          position: absolute;
          right: 0;
          font-weight: 600;
          color: #3d854d;
        }
      }
      .item {
        background: #f8f8f8;
        margin-bottom: 16px;
        border-radius: 10px;
        padding: 12px 12px 4px;
        &:last-child {
          margin-bottom: 0;
        }
        h3 {
          font-size: 18px;
          color: #272c47;
          font-weight: 500;
          line-height: 38px;
          display: flex;
          align-items: center;
          border-bottom: 2px solid #afeaee;
          .img-icon {
            width: 24px;
            margin-right: 6px;
          }
          &.sleep-time {
            border-bottom: 0;
            position: relative;
            > span {
              position: absolute;
              right: 10px;
              // color: #2ccbd6;
              color: #3d854d;
              font-size: 20px;
            }
          }
        }
        .flex-row {
          display: flex;
          > p {
            flex: 1;
            display: flex;
            line-height: 36px;
            font-size: 16px;
            position: relative;
            > span {
              // color: #2ccbd6;
              color: #3d854d;
              &:first-child {
                color: #272c47;
                width: 45px;
                white-space: nowrap;
              }
              &.w-md {
                width: 68px;
              }
            }
          }
        }
        > p {
          display: flex;
          line-height: 36px;
          font-size: 16px;
          position: relative;
          > span {
            color: #3d854d;
            &:first-child {
              color: #272c47;
              width: 45px;
              white-space: nowrap;
            }
          }
        }
        .grid-images {
          padding-top: 10px;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 4px;
          .grid-item {
            min-height: 100px;
            text-align: center;
            cursor: pointer;
            > img {
              height: 120px;
              margin-bottom: -4px;
            }
            > p {
              font-size: 16px;
            }
          }
        }
        .description {
          line-height: 32px;
          font-size: 16px;
        }
        .poster,
        .poster-time {
          justify-content: flex-end;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
}
.mr4 {
  margin-right: 4px;
}
.action-content {
  font-size: 14px;
  padding: 10px;
  text-indent: 2em;
}
</style>
