const baseConfig = {
    心脏总能量: {
        title: "心脏总能量",
        text: "心脏总能量，反映心脏的整体储备功能，心脏总能量过低提示心脏储备能量不足，心脏总能量过高提示心脏负荷过大。心脏总能量的个人范围，最初是以人群的参考范围3000-6000作为个人范围，使用10天后将会计算出本人的范围，当心脏总能量在个人的范围内波动时，提示心脏总能量波动较小，短时间内不会发生严重情况，当某次的心脏总能量变化超出个人的参考范围时，一般与行为、睡眠等相关，注意避免不良的生活习惯。",
    },
    当次基准心率与长期基准心率: {
        title: "当次基准心率与长期基准心率",
        text: "当次基准心率是指本次监测期间出现次数最多的心率值，而长期基准心率是指较长一段时间内出现次数最多的心率值。夜间基准心率的参考范围为 50~65 次/分钟。当次基准心率值与长期基准心率值相差较小时，提示夜间心率较为稳定；若当次基准心率比长期基准心率升高，影响因素有感染、发热、疼痛、运动、饮酒等，某些疾病如高血压、糖尿病患者当出现血压波动或血糖变化时也可能导致心率升高；当次基准心率较长期基准心率降低，规律有氧运动可是心率逐渐降低，这是好的变化，但一些异常情况如服用镇静类、抑制心率的药物也会使心率降低。",
    },
    实际睡眠时长: {
        title: "实际睡眠时长",
        text: '指睡眠期时长，从进入睡眠期开始至睡眠期结束，正常人实际睡眠时长应不少于6小时，不多于9小时。<div style="color:#DE4F46"> 研究发现睡眠时长为7小时的人群最长寿。</div>',
    },
    浅度睡眠: {
        title: "浅度睡眠",
        text: "浅睡眠是人从清醒期或微觉醒期与深睡期的过度状态，人进入浅睡眠后，肌张力逐步降低，意识逐渐消失。浅睡眠在整晚睡眠中占比最多，正常人浅睡眠的比例为50%~65%.",
    },
    深度睡眠: {
        title: "深度睡眠",
        text: "深睡眠，也被称为“黄金睡眠”，在此期主要的生理功能有细胞修复、生长激素分泌、免疫调节、能量储备等，当缺乏深睡眠时将会出现能量代谢紊乱、精神疲劳、免疫下降等。正常成人的深睡期约占整晚睡眠的15%~25%.",
    },
    REM睡眠: {
        title: "REM睡眠",
        text: "REM期，即快速眼动睡眠期，得名于此睡眠期内眼球的特征性快速运动。REM期与记忆形成和情绪调控有着密切联系，做梦大多发生在此期。正常成人的REM期约占整晚睡眠的15%~25%.",
    },
    睡眠周期: {
        title: "睡眠周期",
        text: "人的睡眠可分为REM期、浅睡期、深睡期，其中浅睡期和深睡期并称为NREM期。这些睡眠状态会按照一定规律进行转换，REM期与NREM期转换一次就是1个睡眠周期。正常人一晚上可经历3~6个睡眠周期。",
    },
    体动次数: {
        title: "体动次数",
        text: "指躯体或肢体运动的次数，正常情况下在浅睡眠状态和REM期发生频率较高。参考范围为 50~200次。难入睡、睡眠不安、呼吸暂停、抽搐、癫痫等都能导致体动较多。",
    },
    当次基准心率: {
        title: "当次基准心率",
        text: "基准心率是反映心脏功能的基本指标，是指监测期间出现次数最多的心率值。夜间基准心率的参考范围为  50~65次/分钟。夜间基准心率过高，会使心血管疾病风险增加。坚持长期运动可提高心肌收缩能力，提高心脏储备功能，从而使静息心率降低。",
    },
    当次平均心率: {
        title: "当次平均心率",
        text: "平均心率是指监测期间心率的平均值，夜间平均心率的参考范围为 50~65 次/分钟。平均心率是反映心脏功能的基本指标之一，坚持长期运动可提高心肌收缩能力，提高心脏储备功能，从而使静息心率降低。",
    },

    当次基准呼吸: {
        title: "当次基准呼吸",
        text: "基准呼吸，是评价肺功能的基本指标，是指监测期间出现次数最多的呼吸频率值。夜间基准呼吸的参考范围为9-18次/分钟。发热、缺氧时会导致基准呼吸频率升高。",
    },
    呼吸暂停次数: {
        title: "呼吸暂停次数",
        text: "是指整晚睡眠中发生呼吸暂停的总次数，呼吸暂停是指睡眠中呼吸运动停止10秒以上则记录为1次。整晚的呼吸暂停总次数除以睡眠时间得到呼吸暂停指数(AI,Apnea Index)，代表每小时发生呼吸暂停的次数。AI与低通气指数(HI)组成了AHI，用来判断睡眠呼吸暂停的严重程度。",
    },
    AHI指数: {
        title: "AHI指数",
        text: `AHI(Apnea-Hypopnea Index)是指呼吸暂停低通气指数，也叫呼吸紊乱指数，是指每小时发生呼吸暂停和低通气的次数，正常范围是0~5次/小时。超过5次/小时就能判断为睡眠呼吸暂停综合征；5~15次/小时为轻度呼吸暂停；15~30次/小时为中度；大于30次/小时为重度。
  引起睡眠呼吸暂停的因素常见为肥胖、呼吸道炎症（如感冒、鼻炎等）和气道解剖异常（如鼻甲肿大、肿物等）。有睡眠呼吸暂停的人通常出现打鼾、头晕乏力，严重的情况下会出现睡觉时憋醒、白天嗜睡、注意力不集中、记忆力下降、性格改变等，中、重度呼吸暂停会增加高血压、心律失常、冠心病、卒中等疾病的患病风险。`,
    },
    低通气次数: {
        title: "低通气次数",
        text: "是指整晚睡眠中发生低通气的总次数，用整晚低通气总次数除以睡眠时间得到低通气指数(HI,Hypopnea Index)，代表每小时发生低通气的次数。HI与呼吸暂停指数(AI)组成了AHI，用来判断睡眠呼吸暂停的严重程度。",
    },
};


const amExplainConfig = {
    'Home-TP': [
        {
            where: '0<=BaseTP&&BaseTP<2000',
            result: [
                {
                    where: ' 0<=TP/BaseTP&&TP/BaseTP<0.5',
                    result: `定义：心脏总能量反映了心脏的整体储备功能，降低提示心脏能量不足。
  
                      判断：您的心脏总能量长期偏低，今天的值更低，请您关注。
                      长期心脏总能量低通常与冠心病、糖尿病、快速型心律失常、高血压并发自主神经损伤等慢性病相关，也可见于亚健康、久坐不动者。突然明显降低多与过度劳累、情绪不良、过量饮酒、疾病前驱期等有关。`
                },
                {
                    where: ' 0.5<=TP/BaseTP&&TP/BaseTP<0.75',
                    result: `定义：心脏总能量反映了心脏的整体储备功能，降低提示心脏能量不足。
  
                      判断：您的心脏总能量长期偏低，今天的值稍有降低，请您关注。
                      长期心脏总能量低通常与冠心病、糖尿病、快速型心律失常高血压并发自主神经损伤等慢性病相关，也可见于亚健康、久坐不动者。熬夜、劳累、饮酒等可能会导致降低，调整状态后会恢复到稳态水平。`
                },
                {
                    where: ' 0.75<=TP/BaseTP&&TP/BaseTP<1.5',
                    result: `定义：心脏总能量反映了心脏的整体储备功能，降低提示心脏能量不足。
  
                      判断：您的心脏总能量长期偏低，但今天的值在合理范围内波动，提示身体处于稳定状态。
                      长期心脏总能量低通常与冠心病、糖尿病、快速型心律失常高血压并发自主神经损伤等慢性病相关，也可见于亚健康、久坐不动者。`
                },
                {
                    where: ' 1.5<=TP/BaseTP&&TP/BaseTP<2.0',
                    result: `定义：心脏总能量反映了心脏的整体储备功能，升高提示心脏负荷增大，降低提示心脏能量不足。
  
                      判断：您的心脏总能量长期偏低，今天的值比平常稍有升高。
                      运动、规律的生活习惯、均衡营养有助于逐渐提高心脏总能量，这种属于正常的变化。而异常情况夜间频繁觉醒、情绪应激、压力过大等也会导致升高属于不正常情况，调整状态后会恢复到稳态水平。`
                },
                {
                    where: ' 2.0<=TP/BaseTP',
                    result: `定义：心脏总能量反映了心脏的整体储备功能，升高提示心脏负荷增大，降低提示心脏能量不足。
  
                      判断：您的心脏总能量长期偏低，但今天的值比平常异常升高，请您关注。
                      长期心脏总能量低通常与冠心病、糖尿病、快速型心律失常高血压并发自主神经损伤等慢性病相关，也可见于亚健康、久坐不动者。而突然升高可能为胃肠道或呼吸道疾病前驱期，高血压、糖尿病、冠心病患者管控欠佳、心律失常也会异常升高。`
                }
            ]
        },
        {
            where: '2000<=BaseTP&&BaseTP<6000',
            result: [
                {
                    where: ' 0<=TP/BaseTP&&TP/BaseTP<0.5',
                    result: `定义：心脏总能量反映了心脏的整体储备功能，降低提示心脏能量不足。
  
                      判断：您的心脏总能量平常较为正常，今天的值出现明显降低，请您关注。
                      突然明显降低多与过度劳累、情绪不良、过量饮酒、疾病前驱期等有关。`
                },
                {
                    where: ' 0.5<=TP/BaseTP&&TP/BaseTP<0.75',
                    result: `定义：心脏总能量反映了心脏的整体储备功能，降低提示心脏能量不足。
  
                      判断：您的心脏总能量平常较为正常，今天的值出现降低，请您关注。
                      一般来说心脏总能量降低与熬夜、劳累、饮酒等生活习惯改变有关，调整状态后能恢复到稳态水平。`
                },
                {
                    where: ' 0.75<=TP/BaseTP&&TP/BaseTP<1.5',
                    result: `定义：心脏总能量反映了心脏的整体储备功能，升高提示心脏负荷增大，降低提示心脏能量不足。
  
                      判断：您的心脏总能量较为正常。`
                },
                {
                    where: ' 1.5<=TP/BaseTP&&TP/BaseTP<2.0',
                    result: `
                      定义：心脏总能量反映了心脏的整体储备功能，升高提示心脏负荷增大。
  
  判断：您的心脏总能量平常较为正常，今天的值比平常稍有升高，请您关注。
  夜间频繁觉醒、情绪应激、压力过大等会导致升高，调整状态后会恢复到稳态水平。
                      `
                },
                {
                    where: ' 2.0<=TP/BaseTP',
                    result: `定义：心脏总能量反映了心脏的整体储备功能，升高提示心脏负荷增大，降低提示心脏能量不足。
                      判断：您的心脏总能量平常较为正常，今天的值比平常明显升高，请您关注。
                      偶发性升高与情绪、应激反应有关；而连续升高可能为胃肠道或呼吸道疾病前驱期，高血压、糖尿病、冠心病患者管控欠佳、心律失常也会异常升高。`
                }
            ]
        },
        {
            where: '6000<=BaseTP&&BaseTP<10000',
            result: [
                {
                    where: ' 0<=TP/BaseTP&&TP/BaseTP<0.5',
                    result: `定义：心脏总能量反映了心脏的整体储备功能，升高提示心脏负荷增大，降低提示心脏能量不足。
  
                      判断：您的心脏总能量长期偏高，今天的值较平时明显降低，请您关注。
                      长期心脏总能量偏高常与高血压、冠心病、缓慢性心律失常等慢性病相关。
                      突然明显降低多与过度劳累、情绪不良、过量饮酒、疾病并发症前驱期等有关。`
                },
                {
                    where: ' 0.5<=TP/BaseTP&&TP/BaseTP<0.75',
                    result: `定义：心脏总能量反映了心脏的整体储备功能，升高提示心脏负荷增大，降低提示心脏能量不足。
  
                      判断：您的心脏总能量长期偏高，今天的值较平时降低，请您关注。
                      长期心脏总能量偏高常与高血压、冠心病、缓慢性心律失常等慢性病相关。
                      一般来说心脏总能量降低与熬夜、劳累、饮酒等生活习惯改变有关，调整状态后能恢复到稳态水平。`
                },
                {
                    where: ' 0.75<=TP/BaseTP&&TP/BaseTP<1.5',
                    result: `定义：心脏总能量反映了心脏的整体储备功能，升高提示心脏负荷增大，降低提示心脏能量不足。
  
                      判断：您的心脏总能量长期偏高，今天的值在合理范围内波动，提示身体处于稳定状态。
                      长期心脏总能量偏高常与高血压、冠心病、缓慢性心律失常等慢性病相关。`
                },
                {
                    where: ' 1.5<=TP/BaseTP&&TP/BaseTP<2.0',
                    result: `定义：心脏总能量反映了心脏的整体储备功能，升高提示心脏负荷增大，降低提示心脏能量不足。
  
                      判断：您的心脏总能量长期偏高，今天的值较平时升高，请您关注。
                      夜间频繁觉醒、情绪应激、压力过大等会导致升高，调整状态后会恢复到稳态水平。长期心脏总能量偏高常与高血压、冠心病、缓慢性心律失常等慢性病相关。`
                },
                {
                    where: ' 2.0<=TP/BaseTP',
                    result: `定义：心脏总能量反映了心脏的整体储备功能，升高提示心脏负荷增大，降低提示心脏能量不足。
  
                      判断：您的心脏总能量长期偏高，今天的值较平时明显升高，请您关注。
                      长期心脏总能量偏高常与高血压、冠心病、缓慢性心律失常等慢性病相关。
                      偶发性升高与情绪、应激反应有关；而连续升高可能为胃肠道或呼吸道疾病前驱期，高血压、糖尿病、冠心病患者管控欠佳、心律失常也会异常升高。`
                }
            ]
        },
        {
            where: '10000<=BaseTP',
            result: [
                {
                    where: ' 0<=TP/BaseTP&&TP/BaseTP<0.5',
                    result: `定义：心脏总能量反映了心脏的整体储备功能，升高提示心脏负荷增大，降低提示心脏能量不足。
  
                      判断：您的心脏总能量长期处于过高水平，今天的值较平时明显降低，请您关注。
                      突然明显降低多与过度劳累、情绪不良、过量饮酒、疾病前驱期等有关。长期心脏总能量过高常与高血压、冠心病、缓慢性心律失常等慢性病相关。`
                },
                {
                    where: ' 0.5<=TP/BaseTP&&TP/BaseTP<0.75',
                    result: `定义：心脏总能量反映了心脏的整体储备功能，升高提示心脏负荷增大，降低提示心脏能量不足。
  
                      判断：您的心脏总能量长期处于过高水平，今天的值较平时降低，请您关注。
                      一般来说心脏总能量降低与熬夜、劳累、饮酒等生活习惯改变有关，调整状态后能恢复到稳态水平。长期心脏总能量过高常与高血压、冠心病、缓慢性心律失常等慢性病相关。`
                },
                {
                    where: ' 0.75<=TP/BaseTP&&TP/BaseTP<1.5',
                    result: `定义：心脏总能量反映了心脏的整体储备功能，升高提示心脏负荷增大。
  
                      判断：您的心脏总能量长期处于过高水平，今天的值与平常差不多。
                      长期心脏总能量过高常与高血压、冠心病、缓慢性心律失常等慢性病相关。`
                },
                {
                    where: ' 1.5<=TP/BaseTP&&TP/BaseTP<2.0',
                    result: `定义：心脏总能量反映了心脏的整体储备功能，升高提示心脏负荷增大。
  
                      判断：您的心脏总能量长期处于过高水平，今天的值较平时升高，请您关注。
                      夜间频繁觉醒、情绪应激、压力过大等会导致升高，调整状态后会恢复到稳态水平。而长期心脏总能量偏高常与高血压、冠心病、缓慢性心律失常等慢性病相关。`
                },
                {
                    where: ' 2.0<=TP/BaseTP',
                    result: `定义：心脏总能量反映了心脏的整体储备功能，升高提示心脏负荷增大。
                      判断：您的心脏总能量长期处于过高水平，今天的值较平时明显升高，请您关注。
                      长期心脏总能量偏高常与高血压、冠心病、缓慢性心律失常等慢性病相关。突然升高可能为胃肠道或呼吸道疾病前驱期，高血压、糖尿病、冠心病患者管控欠佳、心律失常也会异常升高。`
                }
            ]
        }
    ],

    'Home-xilu': [
        {
            where: '0<=lhr&&lhr<50',
            result: [
                {
                    where: '0<=hr/lhr&&hr/lhr<0.85',
                    result: `定义：基准心率是指本次监测时间内出现次数最多的心率值，反映近期的心脏储备功能。
  
                      判断：您的长期基准心率偏低，今天的值较平时明显降低，请您关注。
                      长期心率偏慢常与病态窦房结综合征、房室传导阻滞、甲状腺功能低下等疾病有关，但长期运动者夜间心率偏慢属正常现象，无需过度担心。夜间心率突然降低可能为突发缓慢性心律失常、服用有减慢心率作用的药物、急性心肌梗死等。`
                },
                {
                    where: '0.85<=hr/lhr&&hr/lhr<1.15',
                    result: `定义：基准心率是指本次监测时间内出现次数最多的心率值，反映近期的心脏储备功能。
  
                      判断：您的长期基准心率偏低，今天的值在合理范围内波动。
                      长期心率偏慢常与病态窦房结综合征、房室传导阻滞、甲状腺功能低下等疾病有关，但长期运动者夜间心率偏慢属正常现象，无需过度担心。`
                },
                {
                    where: '1.15<=hr/lhr',
                    result: `定义：基准心率是指本次监测时间内出现次数最多的心率值，反映近期的心脏储备功能。
  
                      判断：您的长期基准心率偏低，今天的值较平时明显升高，请您关注。
                      心率突然升高多由于感染、发热、疼痛、睡前运动、饮酒等导致，夜间出现血糖、血压异常波动时也会升高。长期心率偏慢常与病态窦房结综合征、房室传导阻滞、甲状腺功能低下等疾病有关，但长期运动者夜间心率偏慢属正常现象，无需过度担心。`
                }
            ]
        },
        {
            where: '50<=lhr&&lhr<65',
            result: [
                {
                    where: '0<=hr/lhr&&hr/lhr<0.85',
                    result: `定义：基准心率是指本次监测时间内出现次数最多的心率值，反映近期的心脏储备功能。
  
                      判断：您的基准心率平常较为正常，但今天的值较平时明显降低，请您关注。
                      心率突然降低可能为突发缓慢性心律失常、服用有减慢心率作用的药物、急性心肌梗死等。`
                },
                {
                    where: '0.85<=hr/lhr&&hr/lhr<1.15',
                    result: `定义：基准心率是指本次监测时间内出现次数最多的心率值，反映近期的心脏储备功能。
  
                      判断：您的基准心率较为正常。`
                },
                {
                    where: '1.15<=hr/lhr',
                    result: `定义：基准心率是指本次监测时间内出现次数最多的心率值，反映近期的心脏储备功能。
  
                      判断：您的基准心率平常较为正常，今天的值较平时明显升高，请您关注。
                      心率突然升高多由于感染、发热、疼痛、睡前运动、饮酒等导致，夜间出现血糖、血压异常波动时也会升高。`
                }
            ]
        },
        {
            where: '65<=lhr&&lhr<84',
            result: [
                {
                    where: '0<=hr/lhr&&hr/lhr<0.85',
                    result: `定义：基准心率是指本次监测时间内出现次数最多的心率值，反映近期的心脏储备功能。
  
                      判断：您的长期基准心率偏高，但今天的值较平时明显降低，请您关注。
                      长期心率偏高常与贫血、甲状腺功能亢进、神经官能症、肥胖等相关。偶然心率突然降低可能为突发缓慢性心律失常、服用有减慢心率作用的药物、急性心肌梗死等。`
                },
                {
                    where: '0.85<=hr/lhr&&hr/lhr<1.15',
                    result: `定义：基准心率是指本次监测时间内出现次数最多的心率值，反映近期的心脏储备功能。
  
                      判断：您的长期基准偏高，今天的值在合理范围内波动。
                      长期心率偏高常与贫血、甲状腺功能亢进、神经官能症、肥胖等相关。`
                },
                {
                    where: '1.15<=hr/lhr',
                    result: `定义：基准心率是指本次监测时间内出现次数最多的心率值，反映近期的心脏储备功能。
  
                      判断：您的长期基准心率偏高，今天的值较平时明显升高，请您关注。
                      长期心率偏高常与贫血、甲状腺功能亢进、神经官能症、肥胖等相关。突然升高多由于感染、发热、疼痛、睡前运动、饮酒等导致，夜间出现血糖、血压异常波动时也会升高。`
                }
            ]
        },
        {
            where: '84<=lhr',
            result: [
                {
                    where: '0<=hr/lhr&&hr/lhr<0.85',
                    result: `定义：基准心率是指本次监测时间内出现次数最多的心率值，反映近期的心脏储备功能。
                      判断：您的长期基准心率过高，但今天的值较平时明显降低，请您关注。
                      长期心率过高常与贫血、甲状腺功能亢进、高血压性心脏病、冠心病等相关。心率突然降低可能为突发缓慢性心律失常、服用有减慢心率作用的药物、急性心肌梗死等。`
                },
                {
                    where: '0.85<=hr/lhr&&hr/lhr<1.15',
                    result: `定义：基准心率是指本次监测时间内出现次数最多的心率值，反映近期的心脏储备功能。
                      判断：您的长期基准心率过高，今天的值在合理范围内波动。
                      长期心率过高常与贫血、甲状腺功能亢进、高血压性心脏病、冠心病等相关。`
                },
                {
                    where: '1.15<=hr/lhr',
                    result: `定义：基准心率是指本次监测时间内出现次数最多的心率值，反映近期的心脏储备功能。
                      判断：您的长期基准心率过高，今天的值较平时。
                      长期心率过高常与贫血、甲状腺功能亢进、高血压性心脏病、冠心病等相关。心率突然升高多由于感染、发热、疼痛、睡前运动、饮酒等导致，夜间出现血糖、血压异常波动时也会升高。`
                }
            ]
        }
    ],

    'TP/BaseTP':
        '心脏总能量反映了心脏的整体储备功能，过低提示心脏储备能量不足，过高提示心脏负荷过大。人群的参考范围为2000~6000，并随着年龄的增加，心脏总能量降低。\n心功能不全、心衰、高血压长期用药者，糖尿病患者，久坐不动者心脏总能量降低；高血压未用药者，停博患者，心律失常患者，过量运动者心脏总能量升高。\n',
    'LF/BaseLF':
        '反映了交感神经的调节张力，交感神经是保证人在紧张状态下生理功能的重要因素，参考范围为600-1800，随着年龄的增大，交感神经张力降低。\n交感神经张力过高时能引起末梢血管收缩、心跳加快、新陈代谢亢进，过低则容易出现乏力、精神萎靡。交感神经张力指数过高通常发生在高血压患者血压控制不佳时，房颤、停搏、房室传导阻滞发生时，情绪过激或运动过量时。而交感神经张力指数降低则发生在高血压、糖尿病患者发生自主神经损伤时、心律失常、冠心病患者，年龄增大心功能减弱者也会降低。\n',
    'HF/BaseHF':
        '反映了迷走神经的调节张力，其与交感神经的功能是相互拮抗的，迷走神经保持人在安静时的平衡状态，迷走神经张力指数的参考范围为600-1200，并随着年龄的增大而降低。\n迷走神经的主要的生理功能是使心跳减慢、血压降低、促进胃肠蠕动和消化腺分泌。迷走神经张力过高时可能导致胃肠过激、肠易激综合征等，导致便秘、腹泻等；高血压、冠心病患者这个值太高可能出现早搏、房颤或其他心律不齐。迷走神经张力指数过低则可能出现胃肠功能减弱、便秘，糖尿病患者则提示出现了自主神经受损，喝酒、服用抑制神经的药物则会导致迷走神经张力降低。\n',
    LFHF:
        '自主神经平衡指数，反映了交感神经和迷走神经的平衡性，正常人在睡眠状态下，交感神经活性降低，迷走神经活性升高，参考范围为0.8~1.2.\n自主神经平衡指数升高，有以下情况：提示高血压风险升高，高血压患者血压波动，情绪烦躁不安、焦虑恐惧等。自主神经平衡指数降低的，原患糖尿病者提示出现明显自主神经受损，可能出现血糖控制不佳；血压降低或低血压者此值也会降低。"\n',
    sdnn:
        '心律失常风险指数，反映了心脏跳动节律的总体变异性，可用于预测心律失常发生风险，参考范围为50-180，指数值升高或降低都可能出现心律失常。\n当心律失常风险指数降低时，提示快速性心律失常风险增加，原患高血压、冠心病或其他心脏病者，可能出现心动过速、房扑等。当心律失常风险指数升高，提示慢速性心律失常风险增加，心动过缓、停搏、房室传导阻滞的风险增加。\n',
    dc:
        '心率减速力是迷走神经的负性作用对心率的减速调节结果，即降低心率，对心脏起到保护性作用。心率减速力对预测心脏性猝死有重要意义，参考范围为4.6~12，心率减速力越低，心脏性猝死风险越高。相反的情况，当心率减速力过大，停搏、房室传导阻滞的风险会升高。\n',
    hr:
        '基准心率是反映心脏功能的基础指标，夜间基准心率的参考范围为50~65次/分钟。感染发热、长期不运动、饮酒、疲劳者心率升高，高血压、糖尿病、冠心病、心衰等患者心率也会比较高，一些药物如阿托品、肾上腺素等会导致心率升高，长期夜间心率太高，会是心血管疾病风险升高。心率低的情况，如果您是长期运动者，心率降低属于正常，其他的可能是都行心动过缓、停博、传导阻滞等影响，需寻求专业医生的指导。\n',
    lbr:
        '长期基准呼吸反映了一段时间内的肺功能情况，参考范围为9~18次/分钟，长期运动者肺活量大，呼吸频率比普通人要低；而长期不运动者、吸烟、有心肺功能受损的患者长期基准呼吸通常较高。',
    brz:
        '睡眠过程中呼吸运动停止10秒钟以上记录为呼吸暂停，主要特征表现为打鼾。引起睡眠呼吸暂停的因素中最常见的是肥胖，呼吸道炎症（如感冒、鼻炎等）和气道解剖学异常（如鼻甲肿大、肿物等）也会导致呼吸暂停。呼吸暂停不超过15次是最好的，呼吸暂停较多时会导致血压饱和度下降，血液黏稠度增加，高血压、冠心病的发病风险升高。\n',
    AHI:
        '也叫睡眠呼吸暂停和低通气指数，是指每小时发生呼吸暂停和低通气的平均次数，正常范围使0~5次/小时。超过5次/小时则可判断为睡眠呼吸暂停，5~15次/小时为轻度，15~30次/小时为重度，大于30次/小时为重度。中重度呼吸暂停会增加高血压、心律失常、冠心病、心衰、卒中、认知异常等疾病的风险。\n',
    lhr:
        '长期基准心率反映了一段时间内心脏的总体功能，其参考范围为50~65次/分钟。长期不运动、经常饮酒、吸烟、睡眠障碍者，患高血压、冠心病、心衰的患者等长期基准心率可能会较正常人要高，发生心血管意外的风险也会相应增加。\n',
    Sc_tst:
        '保证充足的睡眠有助于维持身心健康，美国睡眠医学会和睡眠研究学会建议，成年人每晚最佳睡眠时间是7-9小时。但睡眠时间不是评价睡眠质量的唯一标准，只要睡醒后感觉神清气爽、精神饱满即可，习惯性短睡者不需因为低于推荐标准而过分担忧。另外，一味为了延长睡眠时间而赖在床上并不能弥补睡眠不足，反而更不利于获得高质量睡眠，可以通过短暂午睡来弥补夜间睡眠不足。',
    sffcy2:
        '高效的睡眠，对于增强智力和体力起着重要作用，睡眠效率大于90%为优秀，85%为正常。难入睡者入睡需时太长，易醒者在睡眠中清醒次数增多，都是导致睡眠效率不高的直接原因。',
    st:
        '从躺在床上至入睡所需要的时长，正常人入睡应该在0.5小时以内。如果超过半小时仍然睡不着就可能存在失眠，失眠是睡眠障碍中最常见的一种。随着生活压力的增大和一系列社会事件的增多，失眠发生率升高；此外一系列电子产品的广泛应用，也大大增加了入睡困难的发生率，与此同时也导致焦虑发生率升高。白天注意运动锻炼，晚上减少使用电子产品，如果睡不着建议起来到客厅或者书房看书或做其它活动再到床上来睡觉，不要使劲在床上睡。',
    SleepContinuity:
        '睡眠过程不间断，则为睡眠连续性好，是评价睡眠质量的其中一个标准，对体力恢复、情绪调节和增强记忆力都有重要作用。典型的睡眠状态转换为浅睡-深睡-浅睡-REM（快速眼动），为一个睡眠周期，随即进入下一次的睡眠周期。但如果睡眠过程中觉醒次数较多，醒后难以再次入睡，则直接影响睡眠的连续性。',
    sct:
        '典型的睡眠状态转换为浅睡-深睡-浅睡-REM（快速眼动），为一个睡眠周期，随即进入下一次的睡眠周期。但实际过程中不一定会经历所有的睡眠状态，睡眠状态的转换也不一定是完全规律的。7小时睡眠中睡眠周期3-6个是正常的。',
    slr:
        '浅睡眠阶段的心率和呼吸比清醒时减慢，人在浅睡眠时容易被唤醒，对人体疲劳消除、精力恢复的作用不如深睡眠，但浅睡眠是人从深睡眠过度到清醒的保护机制，是一种必须的生理需求。浅睡眠的正常比例为45%~65%。',
    dpr:
        '深睡眠，也成为“黄金睡眠”，深睡眠对机体细胞修复、生长激素分泌、增强免疫、消除疲劳、精力恢复有重要作用。正常人的深睡眠比例为15%~25%，缺乏深睡将可能出现代谢紊乱、免疫力下降、精神疲劳等；深睡眠比例长期过高则可能是某些疾病发作的信号，需寻找专业医生的指导。',
    remr:
        'REM睡眠，即快速眼动睡眠，得名于此睡眠期内眼球的特征性快速运动，睡梦多发生在这个阶段，如果在这个阶段被唤醒，大多数人都可能说自己在做梦。快速眼动睡眠对记忆力形成、情绪调节、维持精神健康起着重要作用。正常人的REM睡眠比例为15%~25%，REM睡眠不足会影响记忆形成，导致记忆力下降，并且增加全因死亡风险和老年痴呆风险。REM睡眠过多则会影响身体得不到完全恢复，易怒很难控制自己情绪。',
    AwakeTimes: '睡眠过程中觉醒次数，会直接影响睡眠连续性，影响睡眠质量，正常人睡眠中间觉醒次数不超过2次。',
    mct:
        '睡眠过程中体动次数的多少反映了睡眠质量的高低，正常人体动次数为50~200次，体动过多通常是因为睡眠不安、早醒、难入睡等，而睡眠呼吸暂停患者由于翻身较多、身体抽动等原因，体动次数通常也较多。',
    br:
        '基准呼吸时评价肺功能的基础指标之一，其参考范围为9~18次/分钟，感染发热、睡眠呼吸障碍、缺血缺氧或某些药物的影响可能会导致呼吸加快。',
    hc: [
        {
            where: '0<=hc&&hc<40',
            result: '提示您的血压控制平稳，波动较小。'
        },
        {
            where: '40<=hc&&hc<70',
            result:
                '提示您的血压水平控制欠佳，出现波动可能性较大。应加强血压监测，注意情绪调节，忌大悲大喜，避免劳累，规律用药，切忌漏服或擅自停药。'
        },
        {
            where: '70<=hc&&hc<=100',
            result: `提示您的血压水平控制不佳，出现波动可能性大，发生高血压并发症风险增加；自主神经失衡还会直接影响睡眠、情绪调控等。应加强血压监测，如果规律服药仍控制不佳，请咨询专业医师调整药物。如果出现头晕、头痛、视物模糊、肢体麻木等请及时就医检查。`
        }
    ],
    mc: [
        {
            where: '0<=mc&&mc<40',
            result:
                '提示您的冠心病病情控制稳定，发生急性心血管疾病的风险小。需注意情绪调节，避免激动，可进行适度有氧运动锻炼，多喝水，保证蔬菜水果等膳食纤维摄入，预防便秘。'
        },
        {
            where: '40<=mc&&mc<70',
            result:
                '提示您的冠心病病情控制欠稳定，发生急性心血管疾病的风险升高，需调整生活作息，切忌熬夜，注意少量多餐，忌油腻和高盐食物，可多吃鱼类，尤其是深海鱼。按医嘱按时用药，避免过度忧虑、激动、发怒等不良情绪影响，饭后散散步。'
        },
        {
            where: '70<=mc&&mc<=100',
            result: `需警惕冠心病急性发作及其他并发症风险，如出现心前区疼痛、心悸、憋气、心慌等不适，请及时在家人陪同下就医检查。避免过度体力活动，避免烟酒刺激，避免情绪激动，家里常备硝酸甘油、速效救心丸等药物。`
        }
    ],
    tc: [
        {
            where: '0<=tc&&tc<40',
            result: '提示您的血糖水平控制平稳。坚持规律药物治疗，保持良好生活习惯，可通过有氧运动如慢跑、太极拳等。'
        },
        {
            where: '40<=tc&&tc<70',
            result:
                '提示血糖水平控制欠佳，血糖波动可能性较大，容易导致自主神经受损。建议餐前、餐后测量血糖，规律用药，定期行糖化血红蛋白检查，切忌漏服或擅自停药，注重食物选择，减少碳水化合物摄入，如米、面食。'
        },
        {
            where: '70<=tc&&tc<=100',
            result: `可能存在血糖水平控制不佳，血糖波动可能性大，更容易导致自主神经受损，心血管疾病并发症风险升高。建议测量晨起空腹血糖、餐前、餐后血糖并记录，如果用药后血糖仍较高，请咨询专业医师调整药物。同时坚持饮食控制及运动锻炼，运动前做好热身准备，避免空腹运动。`
        }
    ],

    im: [
        //免疫力是指机体抵抗外来侵袭、维护体内环境稳定性的能力，它具有识别和排除抗原性异物的功能，即机体区分自己和非己的功能。
        {
            where: '0<=im&&im<30',

            result:
                '免疫平衡较差提示机体抵抗外来侵袭、维护体内环境稳定性的能力不足。容易感到疲劳，易感冒或肠胃不适等，建议流感流行季节前及早接种疫苗，必要时按医嘱服用增强免疫力的药物调理。<br> <span style="    padding-left: 28px;color: #3e3e3e;padding-right: 5px;  font-weight: 600;text-indent: 30px;">了解更多:</span> 免疫力，就好比人体健康的“守卫军”，与外界的病毒、细菌“作战”，也负责清理人体内衰老、损伤、变性的细胞，承担了重要的防御任务。每个人免疫力都有一个平衡的状态，这个状态被打破了，就会免疫失衡，出现免疫功能紊乱。'
        },
        {
            where: '30<=im&&im<50',

            result:
                '免疫平衡较差提示机体抵抗外来侵袭、维护体内环境稳定性的能力较差。注意情绪调节，参加体育锻炼，如慢跑、户外散步、跳舞等；同时可以选择中医艾灸、按摩、药膳方式提高免疫力。<br><span style="   padding-left: 28px;color: #3e3e3e;padding-right: 5px;   font-weight: 600;text-indent: 30px;">了解更多:</span>免疫力，就好比人体健康的“守卫军”，与外界的病毒、细菌“作战”，也负责清理人体内衰老、损伤、变性的细胞，承担了重要的防御任务。每个人免疫力都有一个平衡的状态，这个状态被打破了，就会免疫失衡，出现免疫功能紊乱。'
        },
        {
            where: '50<=im&&im<70',

            result:
                '免疫平衡中等提示机体抵抗外来侵袭、维持体内环境稳定性的能力中等。充足的睡眠可以消除疲劳，增强免疫力，睡前不宜饮酒、喝茶、喝咖啡。<br><span style="   padding-left: 28px;color: #3e3e3e;padding-right: 5px;   font-weight: 600;text-indent: 30px;">了解更多:</span>免疫力，就好比人体健康的“守卫军”，与外界的病毒、细菌“作战”，也负责清理人体内衰老、损伤、变性的细胞，承担了重要的防御任务。每个人免疫力都有一个平衡的状态，这个状态被打破了，就会免疫失衡，出现免疫功能紊乱。'
        },
        {
            where: '70<=im&&im<90',

            result:
                '免疫平衡较好提示机体抵抗外来侵袭、维护体内环境稳定性的能力较好。科学营养饮食，保证每日摄入足量蛋白质、维生素、微量元素。<br><span style="   padding-left: 28px;color: #3e3e3e;padding-right: 5px;   font-weight: 600;text-indent: 30px;">了解更多:</span>免疫力，就好比人体健康的“守卫军”，与外界的病毒、细菌“作战”，也负责清理人体内衰老、损伤、变性的细胞，承担了重要的防御任务。每个人免疫力都有一个平衡的状态，这个状态被打破了，就会免疫失衡，出现免疫功能紊乱。'
        },
        {
            where: '90<=im&&im<=100',
            result:
                '免疫平衡好提示机体抵抗外来侵袭、维护体内环境稳定性的能力良好。需保持积极主动的锻炼、均衡的营养、平和乐观的心境至关重要。<br><span style="   padding-left: 28px;color: #3e3e3e;padding-right: 5px;   font-weight: 600;text-indent: 30px;">了解更多:</span>免疫力，就好比人体健康的“守卫军”，与外界的病毒、细菌“作战”，也负责清理人体内衰老、损伤、变性的细胞，承担了重要的防御任务。每个人免疫力都有一个平衡的状态，这个状态被打破了，就会免疫失衡，出现免疫功能紊乱。'
        }
    ],
    gr: [
        {
            where: '85<=gr&&gr<=100',
            result:
                '反映机体受到病原体（细菌、病毒、真菌、寄生虫等）入侵并发生相应病理变化，出现不适可能高。关注身体变化，如出现体温升高、干咳、乏力、腹泻等不适，及时对症治疗，就医检查，外出时做好个人防护，如佩戴口罩。居室保持清洁，经常通风，随时保持手卫生，少到人员密集的公共场所活动。可同时补充维生素C，多喝水，暂时减少户外运动锻炼。'
        },
        {
            where: '70<=gr&&gr<85',
            result:
                '反映机体受到病原体（细菌、病毒、真菌、寄生虫等）入侵并发生相应病理变化，出现不适可能较高。关注身体变化，如出现体温升高、干咳、乏力、腹泻等不适，及时对症治疗，必要时就医检查，外出时做好个人防护，如佩戴口罩。居室保持清洁，经常通风，随时保持手卫生，少到人员密集的公共场所活动。饮食宜清淡，增加维生素C摄入。'
        },
        {
            where: '40<=gr&&gr<70',

            result:
                '反映机体受到病原体（细菌、病毒、真菌、寄生虫等）入侵并发生相应病理变化，出现身体不适可能一般。注意居室清洁通风，勤洗手，可做健身操、太极拳、八段锦等运动锻炼，多吃新鲜蔬菜水果，少到人员密集的公共场所。'
        },
        {
            where: '20<=gr&&gr<40',

            result:
                '反映机体受到病原体（细菌、病毒、真菌、寄生虫等）入侵并发生相应病理变化，出现身体不适可能较低。坚持规律有氧锻炼，保持心情愉快，注意居室清洁通风。'
        },
        {
            where: '0<=gr&&gr<20',
            result:
                '反映机体受到病原体（细菌、病毒、真菌、寄生虫等）入侵并发生相应病理变化，出现身体不适可能很低。劳逸结合，作息规律是很好的生活习惯，请继续保持。'
        }
    ],
    Sc_an: [
        {
            where: '"Sc_an"=="安康"',
            result:
                '安康一般是没有焦虑或很轻微，您通常可以感到轻松、舒适和快乐，可能会出现轻微焦虑，但不影响您的健康和生活状态。'
        },
        {
            where: '"Sc_an"=="轻度"',
            result:
                '轻度焦虑水平有助于提高人的注意力和警惕性，有益于提高学习与工作效率，应付各种情况和总结经验。在一定程度上，轻度焦虑对人是有益的，然而若长期处于焦虑的状态，也有可能出现不适症状或加重焦虑的程度，影响人的精神和生理健康。'
        },
        {
            where: '"Sc_an"=="中度"',

            result:
                '中度焦虑可能导致观察力变差，注意力不集中，学习或工作较为费力，适应外界环境变化和分析能力会受到影响，部分人可能伴有说话声音改变，躯体发颤、抖动，呼吸、心跳加快。    '
        },
        {
            where: '"Sc_an"=="重度"',

            result:
                '重度焦虑可能导致适应能力大大减弱，通常出现注意力分散，学习、工作受到严重影响，观察和思维能力受限。同时常伴有睡眠的改变、失眠、多梦、易激惹、烦躁不安等。'
        },
        {
            where: '"Sc_an"=="恐慌"',
            result:
                '恐慌可能表现为对某件事物或某种情况极其担心、害怕，有时出现接受能力失常，严重是可能出现交谈障碍，一些微小的刺激可产生剧烈的反应。'
        }
    ],

    press: [
        {
            where: '0<=press&&press<30',
            result:
                '抗压能力差，当外界环境发生变化或者遇到压力事件时，身体可能来不及调整而引发身心问题。健康的生活习惯、适当的运动和及时纾解压力，可以提高抗压能力。'
        },
        {
            where: '30<=press&&press<70',
            result:
                '抗压能力一般，遇到剧烈的环境变化或压力很大时，身体容易不堪重负而出现问题。建议适度运动提升身体素质以便应对突发事件，同时提升自身能力和知识储备，以免遇到巨大困难时感到手足无措。'
        },
        {
            where: '70<=press&&press<=100',
            result:
                '抗压能力好，当外界环境发生变化或承受很大压力时，身体能够及时调整，迅速适应现状。规律的运动有助于保持这种状态，面对压力时要注意情绪调整。'
        }
    ],
    lable: [
        {
            Explain:
                '心率减速力是定量评估自主神经系统对心脏的调控结果的指标，提示对心脏的减速能力降低，可预警心脏性猝死风险的危险程度。\n过度劳累、肥胖、三高（高血压、高血糖、高血脂）、抽烟喝酒、压力大、熬夜、睡眠呼吸暂停等都是心率减速力降低的重要危险因素；冠心病、肥厚型和扩张型心肌病、心脏瓣膜病、心肌炎和严重室性心律失常者也会出现心率减速力降低。',
            suggest: [
                '平素健康人群建议戒烟限酒，停止熬夜，避免剧烈运动。',
                '高血压、冠心病或其他心脏病患者若出现心悸、胸闷、胸痛等不适请及时就医。',
                '平素易出现四肢不温、喜饮热水者，可进食温补类的食物，如当归生姜羊肉汤。',
                '维生素A和C等抗氧化剂对预防动脉粥样硬化、心源性猝死有重要意义，可适当补充。',
                '可参加适量轻、中度运动，如慢跑、太极拳，骑自行车，切记以运动后感觉轻度疲累即可，不要过量运动。'
            ],
            color: '2',
            name: '心率减速力偏低'
        },
        {
            Explain:
                '心率减速力是定量评估自主神经系统对心脏的调控结果的指标，提示对心脏的减速能力降低，可预警心脏性猝死风险的危险程度。\n过度劳累、肥胖、三高（高血压、高血糖、高血脂）、压力过大、熬夜、睡眠呼吸暂停等都是心率减速力降低的重要危险因素，冠心病、肥厚型和扩张型心肌病、心脏瓣膜病、心肌炎和严重室性心律失常者也会出现心率减速力降低。',
            suggest: [
                '有心脑血管基础疾病（如高血压、糖尿病）病史者，或伴有心悸、胸闷、头晕等不适时，请尽快到医院就诊。',
                '部分人群存在十分隐匿的心脏疾病，需定期进行与心脏相关的检查，如心电图、胸部X线检查、心脏超声等。',
                '饮酒、熬夜也可能导致突发的猝死风险升高，需去除危险因素。',
                '白藜芦醇是动脉粥样硬化、心脑血管疾病的化学预防剂，平素可多摄入富含此类化学物的食物，如花生、葡萄等。'
            ],
            color: '1',
            name: '心率减速力过低'
        },
        {
            Explain:
                '提示发生心脏传导异常（QT间期延长、房室传导阻滞）、停搏的风险为中等风险。心脏传导阻滞患者、病态窦房结综合征患者，低钾血症患者，服用可致停搏的药物如奎尼丁、利血平、胺碘酮、普罗帕酮等药物都可能导致心率减速力升高。',
            suggest: [
                '尽快到医院就诊、检查，确定病因。',
                '起居有常，适当地散步，练气功，打太极拳，以使筋脉气血流通，有益于健康。',
                '可适当补充钙和维生素D。'
            ],
            color: '2',
            name: '心率减速力偏高'
        },
        {
            Explain:
                '提示发生心脏传导异常（QT间期延长、房室传导阻滞）、停搏的风险为高风险。心脏传导阻滞患者、病态窦房结综合征、心脏外伤或手术后窦房结损伤患者，服用可致停搏的药物如奎尼丁、利血平、胺碘酮、普罗帕酮等药物，低钾血症等都可能导致心率减速力严重升高。',
            suggest: [
                '尽快医院就诊，进行动态心电图、心脏超声及其他心脏检查。',
                '高血压、冠心病或其他疾病用药者，需咨询专业医师后遵医嘱用药。'
            ],
            color: '1',
            name: '心率减速力过高'
        },
        {
            Explain:
                '神经衰弱多表现为精神容易兴奋和身体容易疲乏，常伴有烦躁及睡眠障碍等。持续紧张的心情、长期内心的矛盾、过度疲劳、长期紧张的脑力活动等都可能导致神经衰弱，长期以往易出现夜间心率升高，睡眠质量低下等。',
            suggest: [
                '参加文体活动，特别是体育运动，选择适合自己的项目，每日进行，养成良好的运动习惯。',
                '作息规律，不可经常更换睡眠时间和起床时间。',
                '有研究显示，肠道菌群失衡、胃肠功能异常可导致中枢神经功能异常，可补充益生菌改善肠道微环境。',
                '睡前调整情绪，避免过思，以免产生烦躁、焦虑情绪。',
                '以上手段无效时，可寻求心理咨询师或心理医生的帮助。'
            ],
            color: '2',
            name: '神经衰弱'
        },
        {
            Explain:
                '表现为睡眠过程中身体活动过多，辗转反侧。失眠、夜尿过多、中至重度呼吸暂停者常伴随着睡眠不安；睡前进食、睡前情绪激动、枕头高度不适等因素可能导致睡眠不安。',
            suggest: [
                '睡前远离咖啡和尼古丁，建议睡觉前八小时避免喝咖啡。',
                '补充B族维生素，有助于改善睡眠。',
                '晚餐不宜过饱或吃难消化的食物，睡前1小时内不宜进食，避免情绪过激等。',
                '有呼吸暂停者，需增加运动，控制饮食，保持健康体质，还可通过补充益生菌改善肠道菌群来达到减重目的，且能提高睡眠质量。',
                '失眠者需对诱因进行改善，若为压力情绪问题，需自我调整，必要时需求心理疏导，若为疾病因素导致，需治疗原发病。'
            ],
            color: '2',
            name: '睡眠不安'
        },
        {
            Explain:
                '睡眠不足指睡眠的时长较短，常因入睡过晚、睡眠质量低下或者生活作息不规律导致。长期睡眠不足会导致思考能力会下降、警觉力与判断力会被削弱、免疫功能失调等，严重时可引发疾病。',
            suggest: [
                '保持良好的睡眠习惯，睡前忌饮用刺激性饮料，避免情绪过度激动、过度娱乐与言谈。',
                '烦躁不安、辗转反侧、难以入睡者睡前可用热水泡脚，配合用合欢花、酸枣仁、柏子仁煎水代茶饮。',
                '对于部分人来说，睡眠不足的行为因素大于病理性因素，包括所谓的“报复性熬夜”行为，在心理学上这种现象叫做“消极性补偿心理”。发现了问题所在，建立“积极性补偿心理”是解决问题的关键。'
            ],
            color: '2',
            name: '睡眠不足'
        },
        {
            Explain:
                '“梦”是一种经常发生于“快速眼动睡眠期”的睡眠，它与人的记忆的建立和情绪的条件息息相关。“多梦”是一种梦出现频率过高的情况，当人体处于做梦状态时，可因情绪变化导致心率波动，这可能会导致人在半夜多次惊醒，并在觉醒后感觉乱梦纷纭并伴有头晕疲倦等不适感。',
            suggest: [
                '适当参加体育活动，多食用安神补脑食品有助于改善睡眠。',
                '日有所思，夜有所梦。减少睡前的思虑可净少睡梦过多的发生。',
                '有胸闷痰多、身体困重者可用陈皮、茯苓、半夏煎水代茶饮。'
            ],
            color: '1',
            name: '多梦'
        },
        {
            Explain:
                '耀晚上庀是指上床时间晚于00：30。也称为“熬夜”，跟个人生活作息习惯相关。熬夜会导致肝功能异常、经常性疲劳、免疫力下降、肥胖、头痛、皮肤问题等，还会导致新陈代谢压力增加，进而引发高血压、糖尿病等慢性疾病。',
            suggest: [
                '熬夜时，忌喝过多咖啡和茶来刺激神经兴奋，可以用桑葚子桂圆茶替代。',
                '忌吃甜食。',
                '熬夜前补充一颗复合维生素B族，熬夜时要随时补充水分。',
                '加强锻炼，提高身体素质。',
                '熬夜后的第二天记得午睡半小时，有助于缓解身体疲劳程度。',
                '消除思想负担，保持乐观心态。常熬夜者切忌忧虑，不要总是想着我不够睡明天是不是会出什么问题等，想得越多对身体危害越大。'
            ],
            color: '2',
            name: '过晚上床'
        },
        {
            Explain:
                '较难入睡是指入睡的潜伏期较长，是睡眠障碍的一种表现，常由于情绪波动、心理压力以及胃肠不适、过敏等身体疾病等引起，长期入睡困难可诱发焦虑情绪，严重时可导致焦虑症、抑郁症等精神疾病的发生。（需要补充的是，睡前玩手机是一个越来越普遍的问题，这会导致入睡时间明显的延长。）',
            suggest: [
                '需要认清偶尔一两次失眠、睡眠不足不会对身体造成大的损害，不必过分忧虑；若过分担心，反而更难入睡。',
                '安排规律生活，养成定时就寝和定时起床的习惯。',
                '保持适度运动，每天有0.5-1小时运动，但睡前应避免剧烈运动。',
                '睡前避免喝酒、茶、咖啡等。',
                '有夜读或者睡前刷手机习惯的人，不要在床上做这些事情，否则容易造成上床后兴奋度增加，严重者导致失眠。'
            ],
            color: '2',
            name: '较难入睡'
        },
        {
            Explain:
                '极难入睡是指入睡的潜伏期明显较长，是睡眠障碍的一种表现，常由于情绪波动、心理压力以及胃肠不适、过敏等身体疾病等引起，长期入睡困难可诱发焦虑情绪，严重时可导致焦虑症、抑郁症等精神疾病的发生。（需要补充的是，睡前玩手机是一个越来越普遍的问题，这会导致入睡时间明显的延长。）',
            suggest: [
                '若是由于身体疾病、精神疾病导致此种情况，建议积极治疗原有疾病。',
                '有烦躁多梦、口舌生疮、口干口苦者可用莲子心煎水代茶饮；也可用莲子、粳米煮粥食用，但经常腹胀或便秘者不适用本品。',
                '保持适度运动，每天有0.5-1小时运动，但睡前应避免剧烈运动。',
                '有夜读或者睡前刷手机习惯的人，不要在床上做这些事情，否则容易造成上床后兴奋度增加，严重者导致失眠。'
            ],
            color: '2',
            name: '极难入睡'
        },
        {
            Explain:
                '提示发生快速性心律失常的风险为中等风险，快速性心律失常包括心动过速、心房扑动、心房颤动、早搏。可单独发病，亦可与其他心血管病伴发，临床可表现为心悸，胸闷，头晕，低血压，出汗，严重者可出现晕厥或猝死。心律失常常见于冠心病、心肌病、风湿性心脏病、心力衰竭患者；也可见于一些基本健康者、植物神经功能失调者；甲亢、内分泌失调，药物作用也可能导致心律失常。',
            suggest: [
                '注意劳逸结合，根据自身的情况选择合适的体育锻炼，如散步、太极拳、气功等。',
                '以易消化、低盐低脂高蛋白饮食为主，可适当摄入维生素B族、维生素D和钙。',
                '戒烟限酒，停止熬夜，避免剧烈运动。',
                '高血压、冠心病或其他心脏病患者若出现心悸、胸闷、胸痛等不适请及时就医。'
            ],
            color: '2',
            name: '快速性心律失常中风险'
        },
        {
            Explain:
                '提示发生快速性心律失常的风险为高风险，快速性心律失常包括心动过速、心房扑动、心房颤动、早搏。可单独发病，亦可与其他心血管病伴发，临床可表现为心悸，胸闷，头晕，低血压，出汗，严重者可出现晕厥或猝死。心律失常常见于冠心病、心肌病、风湿性心脏病、心力衰竭患者；也可见于一些基本健康者、植物神经功能失调者；甲状腺功能亢进、内分泌失调，药物作用也可能导致心律失常。',
            suggest: [
                '若长期出现心律失常高风险，建议进行动态心电图及超声检查，确定病因，具体请寻求心内科医生的帮助。',
                '若心律失常高风险是偶发性的，可能与过量饮酒、过量运动等行为有关，需要及时调整个体行为，保证足够的休息，必要时需求医生帮助。',
                '患有心脏病、高血压、糖尿病、高血脂患者，提示心律失常高风险的，请及时到心内科就诊。'
            ],
            color: '1',
            name: '快速性心律失常高风险'
        },
        {
            Explain:
                '提示发生缓慢性心律失常的风险为中等风险，缓慢性心律失常包括心动过缓、停搏、窦房阻滞、房室传导阻滞等。常见引起缓慢性心律失常的因素有：老年人传导系统退行性病变（最常见）、冠心病、心肌炎、心肌病，非心源性因素有高血钾、自主神经功能紊乱或严重微生物感染。',
            suggest: [
                '尽快到医院就诊、检查，确定病因。',
                '起居有常，适当地散步，练气功，打太极拳，以使筋脉气血流通，有益于健康。',
                '平时注意饮食清淡，不要过食肥甘厚腻食物。'
            ],
            color: '2',
            name: '缓慢性心律失常中风险'
        },
        {
            Explain:
                '提示发生缓慢性心律失常的风险为高风险，缓慢性心律失常包括心动过缓、停搏、窦房阻滞、房室传导阻滞等。常见引起缓慢性心律失常的因素有：老年人传导系统退行性病变（最常见）、冠心病、心肌炎、心肌病，非心源性因素有高血钾、自主神经功能紊乱或严重微生物感染。',
            suggest: [
                '尽快医院就诊，进行动态心电图、心脏超声及其他心脏检查。',
                '高血压、冠心病或其他疾病用药者，需咨询专业医师后遵医嘱用药。'
            ],
            color: '1',
            name: '缓慢性心律失常高风险'
        },
        {
            Explain:
                '提示心脏的储备能力不足，可能出现心悸胸闷、头晕目眩、烦躁失眠等症状，长期下来可能对心脏损害大，导致心血管意外风险增加，加速机体衰老。影响因素有：饮酒、压力过大、过度劳累等，患快速性心律失常、甲状腺疾病者也可能出现此种情况。',
            suggest: [
                '平时可增加补益气血的膳食，如可用乌鸡、当归、桂圆、大枣煲汤后食肉饮汤。',
                '适当补充钙、维生素D。',
                '不宜过劳或思虑过度，建议适当增加低强度有氧运动，如散步、太极拳等。',
                '心血不足多数情况下和脾胃健运不足相关，切忌饱饥失常，损伤脾胃。',
                '工作生活中苀出现心悸、气促、眩晕感，需立即休息，休息仍难以缓解的请及时就医。'
            ],
            color: '1',
            name: '心脏能量不足'
        },
        {
            Explain:
                '酒精摄入量较少时，自主神经系统会表现出活跃状态。但摄入酒精量过多时，神经系统会被严重抑制，会出现心率加快，且猝死风险加大。\n长期饮酒对各个器官都会产生危害，最敏感的是肝脏，影响肝功能，甚至导致酒精性脂肪肝、肝炎；对心血管功能也有影响，导致心血管疾病的发生风险升高。',
            suggest: [
                '饮酒前半个小时内服用维生素C、维生素B，有助于预防酒精中毒。',
                '饮酒前饮用奶类、豆浆等蛋白质饮料，有助于保护胃粘膜；饮酒前进食富含淀粉的食物，淀粉分子与酒精结合，能延缓酒精的吸收。',
                '酒后可饮用葛花解酒汤，有醒酒的作用。',
                '长期饮酒者定期检查肝功能。'
            ],
            color: '1',
            name: '过量饮酒'
        },
        {
            Explain:
                '轻度呼吸暂停是指睡眠期间AHI指数处在5～15次/小时之间。呼吸暂停是一种睡眠时出现呼吸停止的睡眠障碍，多发于身体肥胖人群，常伴有打鼾、身体抽动等表现。长期出现睡眠呼吸暂停易导致高血压、心肌梗塞、心肌缺氧等。对于轻度患者来说，大多可以通过戒烟、限酒和减脂的方法来纠正睡眠呼吸暂停。',
            suggest: ['戒烟限酒。', '睡眠时采取侧卧睡姿。', '增强体育锻炼、减肥塑身，保持良好的生活习惯。'],
            color: '2',
            name: '轻度呼吸暂停'
        },
        {
            Explain:
                '中度呼吸暂停是指睡眠期间AHI指数处在>15～30次/小时之间。呼吸暂停是一种睡眠时出现呼吸停止的睡眠障碍，多发于身体肥胖人群，常伴有打鼾、身体抽动等表现。长期出现睡眠呼吸暂停易导致高血压、心肌梗塞、心肌缺氧等。对于中度患者来说，也建议通过戒烟、限酒和减脂的方法来纠正睡眠呼吸暂停，若以上措施长期无效时，可使用器械进行辅助通气治疗（即呼吸机治疗）。',
            suggest: [
                '肥胖的人群建议加强运动，减轻体重。',
                '可以尝试服用益生菌纠正肠道菌群紊乱，可增强减肥效果。',
                '打鼾明显者可以尝试佩戴止鼾器。',
                '睡前禁止服用镇静、安眠药物，以免加重对呼吸中枢调节的抑制。'
            ],
            color: '1',
            name: '中度呼吸暂停'
        },
        {
            Explain:
                '重度呼吸暂停是指睡眠期间AHI指数>30次/小时。呼吸暂停是一种睡眠时出现呼吸停止的睡眠障碍，多发于身体肥胖人群，常伴有打鼾、身体抽动等表现。长期出现睡眠呼吸暂停易导致高血压、心肌梗塞、心肌缺氧等。对于重度患者来说，强烈建议戒烟、限酒，如果心肺功能允许的话，也建议通过运动或者其他的减脂的方法来纠正睡眠呼吸暂停。为避免睡眠中缺氧对身体造成进一步的损害，对于重度阻塞性呼吸暂停患者，一般推荐同时使用器械进行辅助通气治疗（即呼吸机治疗）。',
            suggest: [
                '如果长期出现这种现象，需要进行多导睡眠监护的检查，并寻求呼吸内科医生的帮助。',
                '鼾症病人常伴有高血压、心律不齐、血液粘稠度增高，容易导致心脑血管疾病的发生，需重视血压的日常监测，高血压患者按时服用降压药物。',
                '肥胖的人群建议调整饮食结构、加强运动，减轻体重。',
                '戒烟酒。',
                '睡前禁止服用镇静、安眠药物，以免加重对呼吸中枢的抑制。'
            ],
            color: '1',
            name: '重度呼吸暂停'
        },
        {
            Explain:
                '已经患有高血压病的人，血压可能发生升高波动，或使心脏负荷增加。经常血压控制不佳者，会增加左心室肥大、心肌梗死、心力衰竭、脑卒中、高血压肾病等并发症。以下几种情况可能导致血压升高：不规律服药、擅自停药换药、焦虑、失眠或压力过大。',
            suggest: [
                '请早晚定时测量血压，如血压未达降压目标，请及时寻求你的医生帮助。',
                '遵医嘱规律用药，切忌在无医师监控下自行换药、调药。',
                '同时伴有自主神经功能受损者须执行运动方案，规律运动有助于延缓及改善受损。',
                '作息、饮食要规律。膳食结构合理，减少碳水化合物比例，必要时营养师提供膳食方案。',
                '压力过大、情绪紧张等不利于血压的控制，需及时自我调整，释放压力。',
                '肥胖者需减重；糖尿病患者、高血脂者需注意血糖、血脂的控制。'
            ],
            color: '',
            name: '血压控制不佳\n（删除）'
        },
        {
            Explain:
                '糖尿病患者的监测报告中出现“血糖控制不佳”时，发生血糖升高的可能性大。若此时交感神经和迷走神经张力指数都小于400，提示自主神经功能受损，机体对循环、消化系统的调节能力降低。\n糖尿病患者经常发生血糖控制不佳时，早期可能导致乏力、头晕、心慌胸闷、肢端麻木、频繁感染等，后期可能导致高血压、冠心病、糖尿病性肾病。\n以下情况可能导致血糖不稳：不规律用药者，摄入碳水化合物过多，环境温度过高或过低，外伤、感染等应激情况。',
            suggest: [
                '遵医嘱规律用药，切忌在无医师监控下自行换药、调药。',
                '自主神经功能受损者须执行运动方案，规律运动有助于延缓及改善受损。',
                '作息、饮食要规律，不暴饮暴食，不在短时间内进食含糖、淀粉较多的食物。',
                '对于肥胖者，运动减脂可以一定程度的逆转“胰岛素抵抗”。',
                '服用“降糖”药物和运动的过程中，应避免低血糖的发生，如有头晕、乏力、眼前发黑的情况，请寻求家属或朋友的关注，及时送医。'
            ],
            color: '',
            name: '血糖控制不佳\n（删除）'
        },
        {
            Explain:
                '提示高血压患病风险较一般成人升高。高血压病与年龄增加、饮食不合理、吸烟酗酒、缺少运动、肥胖、长期处于高度紧张状态等相关，有高血压家族史者更需高度警惕并预防高血压病的发生。',
            suggest: [
                '要求膳食结构合理，减少碳水化合物比例，必要时营养师提供膳食方案。',
                '有近一半的难治性高血压是由“睡眠呼吸暂停”造成，如你存在肥胖、白天嗜睡或注意力不集中等情况，请密切关注你的睡眠呼吸情况，轻度的睡眠呼吸暂停也是可以通过减肥、戒烟、限酒等手段纠正。',
                '压力过大、情绪紧张等不利于血压的控制，需及时自我调整，释放压力。',
                '肥胖者需减重；糖尿病患者、高血脂者需注意血糖、血脂的控制。',
                '定期测量高血压，若处于正常高值血压或血压已经超过怀常血压上限，请及时到医院检查并确诊。'
            ],
            color: '1',
            name: '高血压风险'
        },
        {
            Explain:
                '提示糖尿病患病风险较一般成人升高，糖尿病与遗传、进食过多和体力活动减少等有关。随着年龄的增大，风险会升高。若有以下情况者，风险会更高：有家族性糖尿病史者、肥胖（尤其是腹型肥胖）、高血压、血脂异倀者、不健康饮食习惯者（碳水化合物摄入比例过多），久坐缺乏运动者。',
            suggest: [
                '有糖尿病家族史的人，需要注意的地方要比无家族史的人来说更多。',
                '减少高升糖指数食物的摄入，这些食物稀饭、糖水、精制面包、精制米饭等，增加低升糖指数的食物如全谷物、完整水果等。',
                '肥胖仍然是糖尿病的高危因素，对于肥胖患者来说，减肥是必要措施。',
                '每年定期体检，监测血糖，必要时可做糖耐量试验。做到“早发现、早干预、早控制”。',
                ''
            ],
            color: '1',
            name: '糖尿病风险'
        },
        {
            Explain:
                '指夜间发生阵发性心率升高。夜间心率增高可能与情绪紧张、劳累有关，也可能与室性阵发性心动过速、预激综合征、异常呼吸等病理性因素有关。',
            suggest: [
                '对于平时较为健康的人来说，心率增高可能是快速眼动期的时候发生的，这是正常生理现象，不必担心。个别情况可能是睡前过于焦虑、睡前过于兴奋、睡前饮酒或睡前运动等原因造成的，找到原因予以避免即可。',
                '对于患有心脏疾病的人来说，可能是心律失常或心衰发生的前兆，如次日醒来后出现明显的心悸、气促、乏力等情况，则建议及时就医，排查相关疾病。此外，注意减少运动量，避免危险发生。'
            ],
            color: '2',
            name: '突发心率增高'
        },
        {
            Explain:
                '指夜间发生阵发性心率显著升高。突然心率显著升高可能发生在睡前过量运动后、噩梦、呼吸暂停、高碳酸血症时，也可见于风湿性心脏病、冠心病、高血压性心脏病、预激综合征等。',
            suggest: [
                '对于平时较为健康的人来说，心率增高可能是快速眼动期的时候发生的，这是正常生理现象，不必担心。个别情况可能是睡前过于焦虑、睡前过于兴奋、睡前饮酒或睡前运动等原因造成的，找到原因予以避免即可。',
                '对于有心脏疾病的人来说，可能是心律失常或心衰发生的前兆，如次日醒来后出现明显的心悸、气促、乏力等情况，则建议及时就医，排查相关疾病。此外，注意减少运动量，避免危险发生。'
            ],
            color: '1',
            name: '突发心率显著增高'
        },
        {
            Explain:
                '指夜间发生阵发性心率降低。夜间心率降低可能与病态窦房结综合征、房室传导阻滞、睡眠呼吸暂停引起的窦性心动过缓、甲状腺功能低下、或服用减缓心率药物（如倍他乐克、利血平、洋地黄类药物等）有关。',
            suggest: [
                '如果反复出现突发心率下降，请注意排查各种可以导致心率下降的原因，如心脏病、睡眠呼吸暂停综合征和服用降低心率的药物等。',
                '在未查清心率下降原因之前，请谨慎对待运动，避免进行超过自身可负荷的强度的运动，以免引起更严重的心律失常。'
            ],
            color: '2',
            name: '突发心率下降'
        },
        {
            Explain:
                '指夜间发生阵发性心率显著降低。突发心率显著降低可能与病态窦房结综合征、房室传导阻滞、睡眠呼吸暂停引起的窦性心动过缓、甲状腺功能低下、或服用减缓心率药物（如倍他乐克、利血平、洋地黄类药物等）有关。',
            suggest: [
                '如果反复出现突发心率下降或突发心率显著下降，请注意排查各种可以导致心率下降的原因，如心脏病、睡眠呼吸暂停综合征和服用降低心率的药物等。',
                '在未查清心率下降原因之前，请谨慎对待运动，避免进行超过自身可负荷的强度的运动，以免引起更严重的心律失常。'
            ],
            color: '1',
            name: '突发心率显著下降'
        },
        {
            Explain:
                '是指人体对食物的消化、吸收营养物质的能力低下，可能出现餐后腹胀，不易吸收，容易疲乏等症状，长期消化力差会导致人体营养供应不足，加快衰老。',
            suggest: [
                '多吃富含纤维素的食物，如新鲜水果、蔬菜和全谷食物，增强胃肠动力。',
                '增加多种维生素的摄入，如维生素B、维生素C。'
            ],
            color: '1',
            name: '消化力差'
        },
        {
            Explain:
                '是指胃肠的收缩蠕动能力不足，可能出现上腹部不适，腹胀、早饱、嗳气、恶心等消化不良的症状，这些症状提示可能存在胃炎或胃溃疡。',
            suggest: [
                '运动可以促胃动力，促进消化，请适当参加体育锻炼。',
                '平时饮食要注意，不吃辛辣、刺激、生冷的食物，少吃甜食，少吃油炸食品，坚硬的食物，多吃新鲜的蔬菜水果，一日三餐定时定量，不要暴饮暴食。',
                '出现腹部不适、腹胀等不良症状时，可以按摩腹部缓解不适感。'
            ],
            color: '2',
            name: '胃肠动力不足'
        },
        {
            Explain: '提示存在抑郁的风险，表现为常感到疲倦，经常烦躁，注愀倀瀀常䀀能集中，出现睡眠障碍，说话缓慢或不想说话。',
            suggest: [
                '找个适合做“情绪垃圾桶”的朋友，倾诉自己的焦虑与不安。',
                '让自己的生活充实起来，除了必要的工作外，适当运动或者偶尔外出游玩　',
                '如果感到特别压抑＀或者已经倀现影响日帀生活的变化，倀以厀寻求心理医生的帮助。'
            ],
            color: '',
            name: '抑郁倾向'
        },
        {
            Explain: '睡眠过程中容易醒来，或醒来的次数较多，有的还可能出现醒后难再入睡的情况，经常易醒容易出现神经衰弱。',
            suggest: [
                '睡前要保持平和心态，不能太过兴奋，否则容易导致浅睡和噩梦连连。',
                '晚上不要吃太饱，睡前避免食用咖啡、巧克力、可乐、茶等食品或饮料。'
            ],
            color: '2',
            name: '易醒'
        },
        {
            Explain:
                '是指在半夜清醒，醒来后翻来覆去，难以再次入睡，或入睡后睡眠很浅，容易醒来，这会导致白天嗜睡，注意力不集中，长最睡眠不足时可能导致神经衰弱。',
            suggest: [
                '睡前避免饮酒，如果必须要喝，最好不要在睡前2小时内喝酒。',
                '每夀呼吸锻炼，肥胖者需减重，改善呼吸有助于改善易醒和难入睡。',
                '半夜醒来继续躺床上睡不着时，不妨起来打个坐，将注意力放在呼吸上，放空身心，有助于入睡。'
            ],
            color: '2',
            name: '醒后难入睡'
        },
        {
            Explain: '瀀眠过程中由于睡眠太浅、易醒、早醒、醒后难入睡等原因导致睡眠断开，不连续。',
            suggest: [
                '适当运动，消耗体力，有助于提高睡眠效率。',
                '睡前补充色氨酸含量丰富的食物，例如牛奶、少量坚果、食物种子等。'
            ],
            color: '2',
            name: '睡眠不连续'
        },
        {
            Explain: '睡眠时间超过10小时，睡眠时间太长对缓解疲劳不仅没有帮助，反而可能导致头晕、更加疲惫。',
            suggest: [
                '养成规律睡眠的习惯，每天入睡和清醒的时间都差不过，在假期也不例外，形成生物钟。',
                '如果醒后头晕、疲惫，可以在清醒后喝一杯温水，然后做一套体操或几个伸展运动，唤醒身体。'
            ],
            color: '2',
            name: '睡眠时间过长'
        },
        {
            Explain: '是指基础代谢率处于较低水平，表现为心率代偿性增快，心脏能量不足，容易出现怕冷、易疲劳等症状。',
            suggest: [
                '以易消化吸收、低脂高蛋白膳食为主，并适当补充复合B族维生素。',
                '夏天尽量少吃苦瓜、绿豆、冷冻饮料等寒凉食品。'
            ],
            color: '2',
            name: '代谢率低'
        },
        {
            Explain:
                '当人体的能量消耗小于能量摄入，多余的能量将转化为脂肪储存，导致肥胖。易积脂肪的人群出现肥胖的概率较高，原因包括饮食不节制，食物摄入过多、运动较少。',
            suggest: [
                '增加身体的能量消耗，增加慢跑、游泳、散步、瑜伽等有氧运动的频次。',
                '易出现腹部胀满者可用白术、苍术、茯苓煎水代茶饮。'
            ],
            color: '2',
            name: '易积脂肪'
        },
        {
            Explain:
                '心率变异性较差者身体活力会相应较差，自主神经系统调节能力低，心脏能量不足等，更容易出现疲劳，运动时容易心悸、气促。',
            suggest: [
                '如果想要提高身体活力，运动是有效的做法，但运动需要循序渐进，从轻量的有氧运动开始，如散步、慢跑等，然后逐渐增加。',
                '多吃富含蛋白质、维生素的食物，包括牛奶、蛋类、豆制品和绿叶蔬菜。'
            ],
            color: '2',
            name: '身体活力不足'
        },
        { Explain: '睡眠结果较好，对疲劳消除和精力恢复有重要意义。', suggest: [], color: '3', name: '优质睡眠' },
        { Explain: '提示您的身体机能良好，自身调节能力较好。', suggest: [], color: '3', name: '自身调节能力好' },
        {
            Explain: '提示您身体各系统功能状态较为良好，容易适应外界环境的变化，急性病风险低。',
            suggest: [],
            color: '3',
            name: '身体素质佳'
        },
        {
            Explain:
                '提示胃肠蠕动增加，与过晚进食导致胃肠负荷增加有关，也有可能是急性胃肠疾病导致肠道活跃度升高。长期胃肠功能过激者可能表现为腹泻，或腹泻和便秘交替出现。',
            suggest: [
                '戒除过晚进食晚餐或宵夜的习惯。',
                '规律作息，避免过度焦虑的情绪产生。',
                '关注近期身体情况，有乏力、发热、腹泻等症状时请及时就医。'
            ],
            color: '2',
            name: '胃肠功能过激'
        },
        {
            Explain: '提示您的心脏处于一种不规整的搏动状态，可能存在心律失常。',
            suggest: [],
            color: '2',
            name: '心律失常警报'
        },
        {
            Explain: '交感神经张力明显占优势，血压和心率均容易升高。',
            suggest: [
                '对于高血压人群，请规律服用降压药物，避免漏服。',
                '保持良好的心态，避免过度焦虑和与人发生争执。',
                '请避免饮酒。酒精具有广泛的抑制神经功能的作用，在自主神经系统方面＀会进一步增加交感神经的相对优势，使心率　血压更容易飙倀。'
            ],
            color: '2',
            name: '自主神经平衡过高'
        },
        {
            Explain:
                '迷走神经张力占优势，血压和心率有可能降低，出现直立性低血压；糖尿病患者可能表现为血糖控制不佳；不良的行为习惯如过晚进食会造成胃肠负荷过高也可能导致自主神经失衡。',
            suggest: ['戒除过晚进食晚餐或宵夜的习惯。', '注意血压、血糖情况，避免突然体位改变，避免短时间内进食大量糖类。'],
            color: '2',
            name: '自主神经平衡过低'
        },
        {
            Explain:
                '您夜间呼吸频率高于21次/分钟，多数情况下与血氧下降相关，与呼吸过速相关的疾病有心功能不全、肺功能不全、感染性疾病、发热。',
            suggest: [
                '对于合并有心脏或呼吸系统疾病的人群来说，找到引起呼吸率升高的原因，遵嘱治疗。',
                '对于对于合并有心脏或呼吸系统疾病的人群来，建议采取半卧位睡眠。',
                '对于感染发热的人来说，请注意积极抗感染和对症治疗。如果可疑为传染性疾病，请注意对易感人群进行隔离。'
            ],
            color: '1',
            name: '呼吸过速'
        }
    ]
}


//报告首页配置
const reportConfig = {
    imText: {},
    grText: {},
    qxxlText: {},
    sleepValueText: {},
    sleepValue: [
        {
            where: 'a>=90',
            text: '优秀',
            // color: 'linear-gradient(60deg, #b9dafe, #125fff)',

            currentRate: 2,
            gradientColor: {
                '0%': '#DFFFFE',
                '100%': '#1AC1BE'
            },
            text: `优秀`,
            size: '120px'
        },
        {
            where: 'a>=80&&a<90',
            text: '良好',
            // color: 'linear-gradient(60deg, #bbdbfe, #638eea)',

            currentRate: 2,
            gradientColor: {
                '0%': '#DFFFFE',
                '100%': '#1AC168'
            },
            text: `良好`,
            size: '120px'
        },
        {
            where: 'a>=60&&a<80',

            gradientColor: {
                '0%': '#F2FFDF',
                '100%': '#BEAC09'
            },
            text: `中等`,
            size: '120px'
            //color: 'linear-gradient(45deg, #fad29e, #d98048)'
        },
        {
            where: 'a>=30&&a<60',
            //  text: '较差',

            currentRate: 10,
            gradientColor: {
                '0%': '#FFE7DF',
                '100%': '#BE4109'
            },
            text: `较差`,
            size: '120px'

            //color: 'linear-gradient(45deg, #fbc4c4, #e06666)'
        },
        {
            where: 'a<30',
            // text: '非常差',

            currentRate: 10,
            gradientColor: {
                '0%': '#FFE7DF',
                '100%': '#BE0909'
            },
            text: `非常差`,
            size: '120px'
            //color: 'linear-gradient(45deg, #f99c9c, #a00c0c)'
        }
    ],
    //睡眠评价
    sleepEvaluation: [
        {
            where: `'{hrv}'=='1'`, //显示条件 大于四小时
            text: `<div style="text-align: left;
    text-indent: 30px;"> 
  您本次睡眠的在床时间为 {StartTime}-{FinishTime}，共 {rft_rst}，睡眠总时长为{Sc_tst}。</div>
  <div style="text-align: left;
    text-indent: 30px;"> 
  本次睡眠效率{sffcy2},睡眠连续性{SleepContinuity}，深睡眠比例{dpr}，中间离枕次数{ofbdc2}，清醒次数{AwakeTimes}。</div>
  <div style="text-align: left;
    text-indent: 30px;"> 
  呼吸暂停次数{brz}，呼吸紊乱指数{AHI}；基准心率{hr} 基准呼吸 {br} 。</div>
  <div style="text-align: left;
    text-indent: 30px;"> 
  心脏总能量{TP}，交感神经张力指数{LF}，自主神经平衡指数{LFHF}，心律失常风险指数{sdnn}。</div>
  ` //显示模版
        },
        {
            where: `'{hrv}'=='0'`, //显示条件 小于四小时
            text: `<div style="text-align: left;">本次报告的在床时间为 {StartTime}-{FinishTime}，共 {rft_rst} 
          睡眠时间共{Sc_tst}。<br/>
          长期基准心率{lhr}次/分钟，<br/>
          本次基准心率为{hr}，<br/>
          长期基准呼吸{lbr}次/分钟，<br/>
          本次基准呼吸为{br}，<br/>
          呼吸暂停次数{brz}，请您关注</div>` //显示模版
        }
    ],

    im: [
        {
            where: `hrv==1`,
            text: ``
        }
    ],

    mangbing: {
        h4: '睡眠时间未满4小时，无数据',
        h7: `生成7份完整报告后即可计算`,
        mangbingText: [
            {
                where: 'A>=70&&A<100',
                text: '正常',
                color: '#178355',
                rame: '您的XXX管控好，继续加油。'
            },
            {
                where: 'A>=40&& A<70 ',
                text: '值得关注',
                color: '#F6A717',
                rame: '您的XXX管控一般，请关注。'
            },
            {
                where: 'A>=0&& A<40 ',
                text: '密切关注',
                color: '#DE4F46',
                rame: '您的XXX管控较差，请密切关注。'
            }
        ]
    },

    tpwhere: 'reportCount>=7',
    tpdefault: '3000-6000',
    tpValue: 'TP*0.75-TP*1.5',

    tpText: [
        {
            where: 'reportCount<8',
            table: [
                {
                    where: 'TP>=0&&TP<2000 ',
                    text: '↓↓',
                    color: '#DE4F46'
                },
                {
                    where: 'TP>=2000&& TP<3000 ',
                    text: '↓',
                    color: '#F6A717'
                },
                {
                    where: 'TP>=3000&& TP<6000 ',
                    text: '',
                    color: '#178355'
                },
                {
                    where: 'TP>=6000&& TP<8000 ',
                    text: '↑',
                    color: '#F6A717'
                },
                {
                    where: 'TP>=8000',
                    text: '↑↑',
                    color: '#DE4F46'
                }
            ]
        },
        {
            where: 'reportCount>=8',
            table: [
                {
                    where: 'TP>=0&& TP<BaseTP*0.5 ',
                    text: '↓↓',
                    color: '#DE4F46'
                },
                {
                    where: 'TP>=BaseTP*0.5&& TP<BaseTP*0.75 ',
                    text: '↓',
                    color: '#F6A717'
                },
                {
                    where: 'TP>=BaseTP*0.75&& TP<BaseTP*1.5 ',
                    text: '',
                    color: '#178355'
                },
                {
                    where: 'TP>=BaseTP*1.5&& TP<BaseTP*2.0 ',
                    text: '↑',
                    color: '#F6A717'
                },

                {
                    where: 'TP>=BaseTP*2.0',
                    text: '↑↑',
                    color: '#DE4F46'
                }
            ]
        }
    ],
    hrlhrText: [
        {
            where: 'hr>=0&&hr<lhr*0.75',
            text: '↓↓',
            color: '#DE4F46'
        },
        {
            where: 'hr>=lhr*0.75&&hr<lhr*0.85',
            text: '↓',
            color: '#F6A717'
        },

        {
            where: 'hr>=lhr*0.85&&hr<lhr*1.15',
            text: '',
            color: '#178355'
        },

        {
            where: 'hr>=lhr*1.15&&hr<lhr*1.5',
            text: '↑',
            color: '#F6A717'
        },
        {
            where: 'hr>=lhr*1.5',
            text: '↑↑',
            color: '#DE4F46'
        }
    ],

    lhrText: [
        {
            where: 'lhr>=0&&lhr<43',
            text: '过低',
            color: '#DE4F46'
        },
        {
            where: 'lhr>=43&&lhr<50',
            text: '偏低',
            color: '#F6A717'
        },
        {
            where: 'lhr>=50&&lhr<65',
            text: '正常',
            color: '#178355'
        },
        {
            where: 'lhr>=65&&lhr<84',
            text: '偏高',
            color: '#F6A717'
        },
        {
            where: 'lhr>=84',
            text: '过高',
            color: '#DE4F46'
        }
    ],

    hrText: [
        {
            where: 'hr>=0&&hr<43',
            text: '过低',
            color: '#DE4F46'
        },
        {
            where: 'hr>=43&&hr<50',
            text: '偏低',
            color: '#F6A717'
        },
        {
            where: 'hr>=50&&hr<65',
            text: '正常',
            color: '#178355'
        },
        {
            where: 'hr>=65&&hr<84',
            text: '偏高',
            color: '#F6A717'
        },
        {
            where: 'hr>=84',
            text: '过高',
            color: '#DE4F46'
        }
    ],

    soreText: [
        //健康值 得分配置
        //background: linear-gradient(107deg, #DFFFFE 0%, #1AC1BE 100%);
        {
            where: 'a>=90',
            currentRate: 2,
            gradientColor: {
                '0%': '#DFFFFE',
                '100%': '#1AC1BE'
            },
            text: `渐变颜色`,
            size: '120px'
        },

        {
            where: 'a>=80&&a<90', //良好 //
            //background: linear-gradient(107deg, #DFFFFE 0%, #1AC168 100%);
            currentRate: 2,
            gradientColor: {
                '0%': '#DFFFFE',
                '100%': '#1AC168'
            },
            text: `渐变颜色`,
            size: '120px'
        },
        {
            where: 'a>=60&&a<80', //中等
            currentRate: 10,
            gradientColor: {
                '0%': '#F2FFDF',
                '100%': '#BEAC09'
            },
            text: `渐变颜色`,
            size: '120px'
            //
            //background: linear-gradient(107deg, #F2FFDF 0%, #BEAC09 100%);
        },
        {
            where: 'a>=30&&a<60', //较差
            currentRate: 10,
            gradientColor: {
                '0%': '#FFE7DF',
                '100%': '#BE4109'
            },
            text: `渐变颜色`,
            size: '120px'
            //
            //background: linear-gradient(107deg, #FFE7DF 0%, #BE4109 100%);
        },
        {
            where: 'a<30', //非常差
            currentRate: 10,
            gradientColor: {
                '0%': '#FFE7DF',
                '100%': '#BE0909'
            },
            text: `渐变颜色`,
            size: '120px'
            //
            //background: linear-gradient(107deg, #FFE7DF 0%, #BE0909 100%);
            //
        }
    ],
    reportPar: [
        {
            "explain": "心脏总能量反映了心脏的整体储备功能，过低提示心脏储备能量不足，过高提示心脏负荷过大。人群的参考范围为2000~6000，并随着年龄的增加，心脏总能量降低。\n心功能不全、心衰、高血压长期用药者，糖尿病患者，久坐不动者心脏总能量降低；高血压未用药者，停博患者，心律失常患者，过量运动者心脏总能量升高。\n",
            "type": "hrv",
            "num": 0,
            "title": "心脏总能量",
            "value": "5420",
            "level": "5",
            "code": "TP/BaseTP",
            "where": [
                {
                    "where": "[0,0.5)",
                    "text": "过低",
                    "color": "#ED6869",
                    "level": "1",
                    "arrow": "↓↓"
                },
                {
                    "where": "[0.5,0.75)",
                    "text": "偏低",
                    "color": "#F8B260",
                    "level": "2",
                    "arrow": "↓"
                },
                {
                    "where": "[0.75,1.5)",
                    "text": "正常",
                    "color": "#628DF2",
                    "level": "3",
                    "arrow": ""
                },
                {
                    "where": "[1.5,2.0)",
                    "text": "偏高",
                    "color": "#F8B260",
                    "level": "2",
                    "arrow": "↑"
                },
                {
                    "where": "[2.0,∞)",
                    "text": "过高",
                    "color": "#ED6869",
                    "level": "1",
                    "arrow": "↑↑"
                }
            ]
        },
        {
            "_id": "5fb47fff75a91d33f0cd5fd0",
            "title": "交感神经张力",
            "value": "1258",
            "level": "5",
            "code": "LF/BaseLF",
            "where": [
                {
                    "where": "[0,0.5)",
                    "text": "过低",
                    "color": "#ED6869",
                    "level": "1",
                    "arrow": "↓↓"
                },
                {
                    "where": "[0.5,0.75)",
                    "text": "偏低",
                    "color": "#F8B260",
                    "level": "2",
                    "arrow": "↓"
                },
                {
                    "where": "[0.75,1.5)",
                    "text": "正常",
                    "color": "#628DF2",
                    "level": "3"
                },
                {
                    "where": "[1.5,2.0)",
                    "text": "偏高",
                    "color": "#F8B260",
                    "level": "2",
                    "arrow": "↑"
                },
                {
                    "where": "[2.0,∞)",
                    "text": "过高",
                    "color": "#ED6869",
                    "level": "1",
                    "arrow": "↑↑"
                }
            ],
            "type": "hrv",
            "num": 1,
            "explain": "反映了交感神经的调节张力，交感神经是保证人在紧张状态下生理功能的重要因素，参考范围为600-1800，随着年龄的增大，交感神经张力降低。\n交感神经张力过高时能引起末梢血管收缩、心跳加快、新陈代谢亢进，过低则容易出现乏力、精神萎靡。交感神经张力指数过高通常发生在高血压患者血压控制不佳时，房颤、停搏、房室传导阻滞发生时，情绪过激或运动过量时。而交感神经张力指数降低则发生在高血压、糖尿病患者发生自主神经损伤时、心律失常、冠心病患者，年龄增大心功能减弱者也会降低。\n"
        },
        {
            "_id": "5fb47fff75a91d33f0cd5fd1",
            "title": "迷走神经张力",
            "value": "1420",
            "level": "5",
            "code": "HF/BaseHF",
            "where": [
                {
                    "where": "[0,0.5)",
                    "text": "过低",
                    "color": "#ED6869",
                    "level": "1"
                },
                {
                    "where": "[0.5,0.75)",
                    "text": "偏低",
                    "color": "#F8B260",
                    "level": "2"
                },
                {
                    "where": "[0.75,1.5)",
                    "text": "正常",
                    "color": "#628DF2",
                    "level": "3"
                },
                {
                    "where": "[1.5,2.0)",
                    "text": "偏高",
                    "color": "#F8B260",
                    "level": "2"
                },
                {
                    "where": "[2.0,∞)",
                    "text": "过高",
                    "color": "#ED6869",
                    "level": "1"
                }
            ],
            "type": "hrv",
            "num": 2,
            "explain": "反映了迷走神经的调节张力，其与交感神经的功能是相互拮抗的，迷走神经保持人在安静时的平衡状态，迷走神经张力指数的参考范围为600-1200，并随着年龄的增大而降低。\n迷走神经的主要的生理功能是使心跳减慢、血压降低、促进胃肠蠕动和消化腺分泌。迷走神经张力过高时可能导致胃肠过激、肠易激综合征等，导致便秘、腹泻等；高血压、冠心病患者这个值太高可能出现早搏、房颤或其他心律不齐。迷走神经张力指数过低则可能出现胃肠功能减弱、便秘，糖尿病患者则提示出现了自主神经受损，喝酒、服用抑制神经的药物则会导致迷走神经张力降低。\n"
        },
        {
            "_id": "5fb47fff75a91d33f0cd5fd2",
            "title": "自主神经平衡",
            "value": "0.89",
            "level": "5",
            "code": "LFHF",
            "where": [
                {
                    "where": "[0,0.5)",
                    "text": "过低",
                    "color": "#ED6869",
                    "level": "1"
                },
                {
                    "where": "[0.5,0.75)",
                    "text": "偏低",
                    "color": "#F8B260",
                    "level": "2"
                },
                {
                    "where": "[0.75,1.5)",
                    "text": "正常",
                    "color": "#628DF2",
                    "level": "3"
                },
                {
                    "where": "[1.5,2.0)",
                    "text": "偏高",
                    "color": "#F8B260",
                    "level": "2"
                },
                {
                    "where": "[2.0,∞)",
                    "text": "过高",
                    "color": "#ED6869",
                    "level": "1"
                }
            ],
            "type": "hrv",
            "num": 3,
            "explain": "自主神经平衡指数，反映了交感神经和迷走神经的平衡性，正常人在睡眠状态下，交感神经活性降低，迷走神经活性升高，参考范围为0.8~1.2.\n自主神经平衡指数升高，有以下情况：提示高血压风险升高，高血压患者血压波动，情绪烦躁不安、焦虑恐惧等。自主神经平衡指数降低的，原患糖尿病者提示出现明显自主神经受损，可能出现血糖控制不佳；血压降低或低血压者此值也会降低。\"\n"
        },
        {
            "_id": "5fb47fff75a91d33f0cd5fd3",
            "title": "心律失常风险",
            "value": "125",
            "level": "5",
            "code": "sdnn",
            "where": [
                {
                    "where": "[0,30)",
                    "text": "过低",
                    "color": "#ED6869",
                    "level": "1"
                },
                {
                    "where": "[30,50)",
                    "text": "偏低",
                    "color": "#F8B260",
                    "level": "2"
                },
                {
                    "where": "[50,180)",
                    "text": "正常",
                    "color": "#628DF2",
                    "level": "3"
                },
                {
                    "where": "[180,220)",
                    "text": "偏高",
                    "color": "#F8B260",
                    "level": "2"
                },
                {
                    "where": "[220,∞)",
                    "text": "过高",
                    "color": "#ED6869",
                    "level": "1"
                }
            ],
            "type": "hrv",
            "num": 4,
            "explain": "心律失常风险指数，反映了心脏跳动节律的总体变异性，可用于预测心律失常发生风险，参考范围为50-180，指数值升高或降低都可能出现心律失常。\n当心律失常风险指数降低时，提示快速性心律失常风险增加，原患高血压、冠心病或其他心脏病者，可能出现心动过速、房扑等。当心律失常风险指数升高，提示慢速性心律失常风险增加，心动过缓、停搏、房室传导阻滞的风险增加。\n"
        },
        {
            "_id": "5fb47fff75a91d33f0cd5fd4",
            "title": "心率减速力",
            "value": "5.85",
            "level": "5",
            "code": "dc",
            "where": [
                {
                    "where": "[0,2.5)",
                    "text": "过低",
                    "color": "#ED6869",
                    "level": "1"
                },
                {
                    "where": "[2.5,4.6)",
                    "text": "偏低",
                    "color": "#F8B260",
                    "level": "2"
                },
                {
                    "where": "[4.6,12)",
                    "text": "正常",
                    "color": "#628DF2",
                    "level": "3"
                },
                {
                    "where": "[12,20)",
                    "text": "偏高",
                    "color": "#F8B260",
                    "level": "2"
                },
                {
                    "where": "[20,∞)",
                    "text": "过高",
                    "color": "#ED6869",
                    "level": "1"
                }
            ],
            "type": "hrv",
            "num": 5,
            "explain": "心率减速力是迷走神经的负性作用对心率的减速调节结果，即降低心率，对心脏起到保护性作用。心率减速力对预测心脏性猝死有重要意义，参考范围为4.6~12，心率减速力越低，心脏性猝死风险越高。相反的情况，当心率减速力过大，停搏、房室传导阻滞的风险会升高。\n"
        },
        {
            "_id": "5fbb325aabe87bc634b22480",
            "explain": "心脏总能量反映了心脏的整体储备功能，过低提示心脏储备能量不足，过高提示心脏负荷过大。人群的参考范围为2000~6000，并随着年龄的增加，心脏总能量降低。\n心功能不全、心衰、高血压长期用药者，糖尿病患者，久坐不动者心脏总能量降低；高血压未用药者，停博患者，心律失常患者，过量运动者心脏总能量升高。\n",
            "type": "免疫力",
            "num": "1",
            "title": "免疫力",
            "value": "5420",
            "level": "5",
            "code": "im",
            "where": [
                {
                    "where": "[0,30)",
                    "text": "差",
                    "color": "#ed6869",
                    "level": "1"
                },
                {
                    "where": "[30,50)",
                    "text": "较差",
                    "color": "#ee8b8a",
                    "level": "2"
                },
                {
                    "where": "[50,70)",
                    "text": "中等",
                    "color": "#f8b260",
                    "level": "2"
                },
                {
                    "where": "[70,90)",
                    "text": "较好",
                    "color": "#95e9d4",
                    "level": "3"
                },
                {
                    "where": "[90,∞)",
                    "text": "好",
                    "color": "#62e4c4",
                    "level": "3"
                }
            ]
        },
        {
            "explain": "基准呼吸时评价肺功能的基础指标之一，其参考范围为9~18次/分钟，感染发热、睡眠呼吸障碍、缺血缺氧或某些药物的影响可能会导致呼吸加快。",
            "type": "呼吸",
            "num": 0,
            "title": "基准呼吸",
            "value": "15次/分钟",
            "level": "5",
            "code": "br",
            "where": [
                {
                    "where": "[0,6)",
                    "text": "过低",
                    "color": "#ED6869",
                    "level": "1"
                },
                {
                    "where": "[6,9)",
                    "text": "偏低",
                    "color": "#F8B260",
                    "level": "2"
                },
                {
                    "where": "[9,18)",
                    "text": "正常",
                    "color": "#628DF2",
                    "level": "3"
                },
                {
                    "where": "[18,21)",
                    "text": "偏高",
                    "color": "#F8B260",
                    "level": "2"
                },
                {
                    "where": "[21,∞)",
                    "text": "过高",
                    "color": "#ED6869",
                    "level": "1"
                }
            ]
        },
        {
            "_id": "5fb47fa275a91d33f0cd5fcc",
            "title": "长期基准呼吸",
            "value": "16次/分钟",
            "level": "5",
            "code": "lbr",
            "where": [
                {
                    "where": "[0,6)",
                    "text": "过低",
                    "color": "#ED6869",
                    "level": "1"
                },
                {
                    "where": "[6,9)",
                    "text": "偏低",
                    "color": "#F8B260",
                    "level": "2"
                },
                {
                    "where": "[9,18)",
                    "text": "正常",
                    "color": "#628DF2",
                    "level": "3"
                },
                {
                    "where": "[18,21)",
                    "text": "偏高",
                    "color": "#F8B260",
                    "level": "2"
                },
                {
                    "where": "[21,∞)",
                    "text": "过高",
                    "color": "#ED6869",
                    "level": "1"
                }
            ],
            "type": "呼吸",
            "num": 1,
            "explain": "长期基准呼吸反映了一段时间内的肺功能情况，参考范围为9~18次/分钟，长期运动者肺活量大，呼吸频率比普通人要低；而长期不运动者、吸烟、有心肺功能受损的患者长期基准呼吸通常较高。"
        },
        {
            "explain": "长期基准呼吸反映了一段时间内的肺功能情况，参考范围为9~18次/分钟，长期运动者肺活量大，呼吸频率比普通人要低；而长期不运动者、吸烟、有心肺功能受损的患者长期基准呼吸通常较高。",
            "type": "呼吸",
            "num": 1,
            "title": "平均呼吸",
            "value": "16次/分钟",
            "level": "5",
            "code": "bavg",
            "where": [
                {
                    "where": "[0,6)",
                    "text": "过低",
                    "color": "#ED6869",
                    "level": "1"
                },
                {
                    "where": "[6,9)",
                    "text": "偏低",
                    "color": "#F8B260",
                    "level": "2"
                },
                {
                    "where": "[9,18)",
                    "text": "正常",
                    "color": "#628DF2",
                    "level": "3"
                },
                {
                    "where": "[18,21)",
                    "text": "偏高",
                    "color": "#F8B260",
                    "level": "2"
                },
                {
                    "where": "[21,∞)",
                    "text": "过高",
                    "color": "#ED6869",
                    "level": "1"
                }
            ]
        },
        {
            "explain": "也叫睡眠呼吸暂停和低通气指数，是指每小时发生呼吸暂停和低通气的平均次数，正常范围使0~5次/小时。超过5次/小时则可判断为睡眠呼吸暂停，5~15次/小时为轻度，15~30次/小时为重度，大于30次/小时为重度。中重度呼吸暂停会增加高血压、心律失常、冠心病、心衰、卒中、认知异常等疾病的风险。\n",
            "type": "呼吸",
            "num": 3,
            "title": "AHI",
            "value": "2.5",
            "level": "4",
            "code": "AHI",
            "where": [
                {
                    "where": "[0,5)",
                    "text": "正常",
                    "color": "#628DF2",
                    "level": "3",
                    "arrow": ""
                },
                {
                    "where": "[5,15)",
                    "text": "轻度呼吸暂停",
                    "color": "#F8B260",
                    "level": "2",
                    "arrow": "↑"
                },
                {
                    "where": "[15,30)",
                    "text": "中度呼吸暂停",
                    "color": "#ED6869",
                    "level": "2",
                    "arrow": "↑↑"
                },
                {
                    "where": "[30,∞)",
                    "text": "重度呼吸暂停",
                    "color": "#ED6869",
                    "level": "1",
                    "arrow": "↑↑↑"
                }
            ]
        },
        {
            "explain": "也叫睡眠呼吸暂停和低通气指数，是指每小时发生呼吸暂停和低通气的平均次数，正常范围使0~5次/小时。超过5次/小时则可判断为睡眠呼吸暂停，5~15次/小时为轻度，15~30次/小时为重度，大于30次/小时为重度。中重度呼吸暂停会增加高血压、心律失常、冠心病、心衰、卒中、认知异常等疾病的风险。\n",
            "type": "呼吸",
            "num": 3,
            "title": "呼吸暂停次数",
            "value": "20次",
            "level": "4",
            "code": "brz",
            "where": [
                {
                    "where": "[0,5)",
                    "text": " ",
                    "color": "#628DF2",
                    "level": "3",
                    "arrow": ""
                },
                {
                    "where": "[5,15)",
                    "text": "  ",
                    "color": "#628DF2",
                    "level": "3",
                    "arrow": "↑"
                },
                {
                    "where": "[15,30)",
                    "text": "   ",
                    "color": "#628DF2",
                    "level": "3",
                    "arrow": "↑↑"
                },
                {
                    "where": "[30,∞)",
                    "text": "    ",
                    "color": "#628DF2",
                    "level": "3",
                    "arrow": "↑↑↑"
                }
            ],
            "open": false
        },
        {
            "explain": "也叫睡眠呼吸暂停和低通气指数，是指每小时发生呼吸暂停和低通气的平均次数，正常范围使0~5次/小时。超过5次/小时则可判断为睡眠呼吸暂停，5~15次/小时为轻度，15~30次/小时为重度，大于30次/小时为重度。中重度呼吸暂停会增加高血压、心律失常、冠心病、心衰、卒中、认知异常等疾病的风险。\n",
            "type": "呼吸",
            "num": 3,
            "title": "低通气次数",
            "value": "20次",
            "level": "4",
            "code": "ht",
            "where": [
                {
                    "where": "[0,5)",
                    "text": " ",
                    "color": "#628DF2",
                    "level": "3",
                    "arrow": ""
                },
                {
                    "where": "[5,15)",
                    "text": "  ",
                    "color": "#628DF2",
                    "level": "3",
                    "arrow": "↑"
                },
                {
                    "where": "[15,30)",
                    "text": "   ",
                    "color": "#628DF2",
                    "level": "3",
                    "arrow": "↑↑"
                },
                {
                    "where": "[30,∞)",
                    "text": "    ",
                    "color": "#628DF2",
                    "level": "3",
                    "arrow": "↑↑↑"
                }
            ]
        },
        {
            "explain": "基准心率是反映心脏功能的基础指标，夜间基准心率的参考范围为50~65次/分钟。感染发热、长期不运动、饮酒、疲劳者心率升高，高血压、糖尿病、冠心病、心衰等患者心率也会比较高，一些药物如阿托品、肾上腺素等会导致心率升高，长期夜间心率太高，会是心血管疾病风险升高。心率低的情况，如果您是长期运动者，心率降低属于正常，其他的可能是都行心动过缓、停博、传导阻滞等影响，需寻求专业医生的指导。\n",
            "type": "心率",
            "num": 0,
            "title": "当次基准心率",
            "value": "58次/分钟",
            "level": "5",
            "code": "hr",
            "where": [
                {
                    "where": "[0,43)",
                    "text": "过低",
                    "color": "#ED6869",
                    "level": "1",
                    "arrow": "↓↓"
                },
                {
                    "where": "[43,50)",
                    "text": "偏低",
                    "color": "#F8B260",
                    "level": "2",
                    "arrow": "↓"
                },
                {
                    "where": "[50,65)",
                    "text": "正常",
                    "color": "#628DF2",
                    "level": "3",
                    "arrow": ""
                },
                {
                    "where": "[65,84)",
                    "text": "偏高",
                    "color": "#F8B260",
                    "level": "2",
                    "arrow": "↑"
                },
                {
                    "where": "[84,∞)",
                    "text": "过高",
                    "color": "#ED6869",
                    "level": "1",
                    "arrow": "↑↑"
                }
            ]
        },
        {
            "_id": "5fb48edddaa1a3ecf15359d7",
            "title": "长期基准心率",
            "value": "57次/分钟",
            "level": "5",
            "code": "lhr",
            "where": [
                {
                    "where": "[0,43)",
                    "text": "过低",
                    "color": "#ED6869",
                    "level": "1"
                },
                {
                    "where": "[43,50)",
                    "text": "偏低",
                    "color": "#F8B260",
                    "level": "2"
                },
                {
                    "where": "[50,65)",
                    "text": "正常",
                    "color": "#628DF2",
                    "level": "3"
                },
                {
                    "where": "[65,84)",
                    "text": "偏高",
                    "color": "#F8B260",
                    "level": "2"
                },
                {
                    "where": "[84,∞)",
                    "text": "过高",
                    "color": "#ED6869",
                    "level": "1"
                }
            ],
            "type": "心率",
            "num": 1,
            "explain": "长期基准心率反映了一段时间内心脏的总体功能，其参考范围为50~65次/分钟。长期不运动、经常饮酒、吸烟、睡眠障碍者，患高血压、冠心病、心衰的患者等长期基准心率可能会较正常人要高，发生心血管意外的风险也会相应增加。\n"
        },
        {
            "explain": "长期基准心率反映了一段时间内心脏的总体功能，其参考范围为50~65次/分钟。长期不运动、经常饮酒、吸烟、睡眠障碍者，患高血压、冠心病、心衰的患者等长期基准心率可能会较正常人要高，发生心血管意外的风险也会相应增加。\n",
            "type": "心率",
            "num": 1,
            "title": "当次与长期之比",
            "value": "0.85",
            "level": "5",
            "code": "hr/lhr",
            "where": [
                {
                    "where": "[0,0.75)",
                    "text": "过低",
                    "color": "#ED6869",
                    "level": "1"
                },
                {
                    "where": "[0.78,0.90)",
                    "text": "偏低",
                    "color": "#F8B260",
                    "level": "2"
                },
                {
                    "where": "[0.90,1.10)",
                    "text": "正常",
                    "color": "#628DF2",
                    "level": "3"
                },
                {
                    "where": "[1.10,1.25)",
                    "text": "偏高",
                    "color": "#F8B260",
                    "level": "2"
                },
                {
                    "where": "[1.25,∞)",
                    "text": "过高",
                    "color": "#ED6869",
                    "level": "1"
                }
            ]
        },
        {
            "explain": "长期基准心率反映了一段时间内心脏的总体功能，其参考范围为50~65次/分钟。长期不运动、经常饮酒、吸烟、睡眠障碍者，患高血压、冠心病、心衰的患者等长期基准心率可能会较正常人要高，发生心血管意外的风险也会相应增加。\n",
            "type": "心率",
            "num": 1,
            "title": "平均心率",
            "value": "61次/min",
            "level": "5",
            "code": "avg",
            "where": [
                {
                    "where": "[0,43)",
                    "text": "过低",
                    "color": "#ED6869",
                    "level": "1"
                },
                {
                    "where": "[43,50)",
                    "text": "偏低",
                    "color": "#F8B260",
                    "level": "2"
                },
                {
                    "where": "[50,65)",
                    "text": "正常",
                    "color": "#628DF2",
                    "level": "3"
                },
                {
                    "where": "[65,84)",
                    "text": "偏高",
                    "color": "#F8B260",
                    "level": "2"
                },
                {
                    "where": "[84,∞)",
                    "text": "过高",
                    "color": "#ED6869",
                    "level": "1"
                }
            ]
        },
        {
            "_id": "5fbb4ffb1648a1edef7ff437",
            "explain": "心脏总能量反映了心脏的整体储备功能，过低提示心脏储备能量不足，过高提示心脏负荷过大。人群的参考范围为2000~6000，并随着年龄的增加，心脏总能量降低。\n心功能不全、心衰、高血压长期用药者，糖尿病患者，久坐不动者心脏总能量降低；高血压未用药者，停博患者，心律失常患者，过量运动者心脏总能量升高。\n",
            "type": "情绪心理",
            "num": "1",
            "title": "焦虑水平",
            "value": "安康",
            "level": "5",
            "code": "Sc_an",
            "where": [
                {
                    "where": "'Sc_an'=='恐慌'",
                    "text": "恐慌",
                    "color": "#ed6869",
                    "level": "1"
                },
                {
                    "where": "'Sc_an'=='重度'",
                    "text": "重度焦虑",
                    "color": "#ee8b8a",
                    "level": "2"
                },
                {
                    "where": "'Sc_an'=='中度'",
                    "text": "中度焦虑",
                    "color": "#f8b260",
                    "level": "2"
                },
                {
                    "where": "'Sc_an'=='轻度'",
                    "text": "轻度焦虑",
                    "color": "#95e9d4",
                    "level": "3"
                },
                {
                    "where": "'Sc_an'=='安康'",
                    "text": "安康",
                    "color": "#62e4c4",
                    "level": "3"
                }
            ]
        },
        {
            "_id": "5fbb509e1648a1edef7ff438",
            "explain": "心脏总能量反映了心脏的整体储备功能，过低提示心脏储备能量不足，过高提示心脏负荷过大。人群的参考范围为2000~6000，并随着年龄的增加，心脏总能量降低。\n心功能不全、心衰、高血压长期用药者，糖尿病患者，久坐不动者心脏总能量降低；高血压未用药者，停博患者，心律失常患者，过量运动者心脏总能量升高。\n",
            "type": "情绪心理",
            "num": "1",
            "title": "抗压能力",
            "value": "抗压能力",
            "level": "5",
            "code": "press",
            "where": [
                {
                    "where": "[0,30)",
                    "text": "差",
                    "color": "#ed6869",
                    "level": "1"
                },
                {
                    "where": "[30,70)",
                    "text": "一般",
                    "color": "#ee8b8a",
                    "level": "2"
                },
                {
                    "where": "[70,100]",
                    "text": "好",
                    "color": "#95e9d4",
                    "level": "3"
                }
            ]
        },
        {
            "_id": "5fe2bf97276c8b4f88d34f8c",
            "explain": "心脏总能量反映了心脏的整体储备功能，过低提示心脏储备能量不足，过高提示心脏负荷过大。人群的参考范围为2000~6000，并随着年龄的增加，心脏总能量降低。\n心功能不全、心衰、高血压长期用药者，糖尿病患者，久坐不动者心脏总能量降低；高血压未用药者，停博患者，心律失常患者，过量运动者心脏总能量升高。\n",
            "type": "情绪心理",
            "num": "1",
            "title": "情绪心理",
            "value": "情绪心理",
            "level": "5",
            "code": "qxxl",
            "where": [
                {
                    "where": "[0,30)",
                    "text": "差",
                    "color": "#ed6869",
                    "level": "1"
                },
                {
                    "where": "[30,50)",
                    "text": "较差",
                    "color": "#ee8b8a",
                    "level": "2"
                },
                {
                    "where": "[50,70)",
                    "text": "中等",
                    "color": "#f8b260",
                    "level": "2"
                },
                {
                    "where": "[70,90)",
                    "text": "较好",
                    "color": "#95e9d4",
                    "level": "3"
                },
                {
                    "where": "[90,101)",
                    "text": "好",
                    "color": "#62e4c4",
                    "level": "3"
                }
            ]
        },
        {
            "_id": "5fbb4d71526bfddc6bbaaa48",
            "explain": "心脏总能量反映了心脏的整体储备功能，过低提示心脏储备能量不足，过高提示心脏负荷过大。人群的参考范围为2000~6000，并随着年龄的增加，心脏总能量降低。\n心功能不全、心衰、高血压长期用药者，糖尿病患者，久坐不动者心脏总能量降低；高血压未用药者，停博患者，心律失常患者，过量运动者心脏总能量升高。\n",
            "type": "感染风险",
            "num": "1",
            "title": "感染风险",
            "value": "2",
            "level": "5",
            "code": "gr",
            "where": [
                {
                    "where": "[85,100)",
                    "text": "高风险",
                    "color": "#ed6869",
                    "level": "1"
                },
                {
                    "where": "[70,85)",
                    "text": "较高风险",
                    "color": "#ee8b8a",
                    "level": "2"
                },
                {
                    "where": "[40,70)",
                    "text": "中等风险",
                    "color": "#f8b260",
                    "level": "2"
                },
                {
                    "where": "[20,40)",
                    "text": "较低风险",
                    "color": "#95e9d4",
                    "level": "3"
                },
                {
                    "where": "[0,20)",
                    "text": "低风险",
                    "color": "#62e4c4",
                    "level": "3"
                }
            ]
        },
        {
            "_id": "5fbb13ae71b08a254289fcbc",
            "explain": "心脏总能量反映了心脏的整体储备功能，过低提示心脏储备能量不足，过高提示心脏负荷过大。人群的参考范围为2000~6000，并随着年龄的增加，心脏总能量降低。\n心功能不全、心衰、高血压长期用药者，糖尿病患者，久坐不动者心脏总能量降低；高血压未用药者，停博患者，心律失常患者，过量运动者心脏总能量升高。\n",
            "type": "慢病管理",
            "num": "1",
            "title": "高血压管控",
            "value": "66",
            "level": "5",
            "code": "hc",
            "where": [
                {
                    "where": "[0,40)",
                    "text": "差",
                    "color": "#ED6869",
                    "level": "1"
                },
                {
                    "where": "[40,70)",
                    "text": "中等",
                    "color": "#F8B260",
                    "level": "2"
                },
                {
                    "where": "[70,100]",
                    "text": "正常",
                    "color": "#628DF2",
                    "level": "3"
                }
            ]
        },
        {
            "_id": "5fbb14ba71b08a254289fcbd",
            "explain": "心脏总能量反映了心脏的整体储备功能，过低提示心脏储备能量不足，过高提示心脏负荷过大。人群的参考范围为2000~6000，并随着年龄的增加，心脏总能量降低。\n心功能不全、心衰、高血压长期用药者，糖尿病患者，久坐不动者心脏总能量降低；高血压未用药者，停博患者，心律失常患者，过量运动者心脏总能量升高。\n",
            "type": "慢病管理",
            "num": "2",
            "title": "冠心病管控",
            "value": "66",
            "level": "5",
            "code": "mc",
            "where": [
                {
                    "where": "[0,40)",
                    "text": "差",
                    "color": "#ED6869",
                    "level": "1"
                },
                {
                    "where": "[40,70)",
                    "text": "中等",
                    "color": "#F8B260",
                    "level": "2"
                },
                {
                    "where": "[70,100]",
                    "text": "正常",
                    "color": "#628DF2",
                    "level": "3"
                }
            ]
        },
        {
            "_id": "5fbb150371b08a254289fcbe",
            "explain": "心脏总能量反映了心脏的整体储备功能，过低提示心脏储备能量不足，过高提示心脏负荷过大。人群的参考范围为2000~6000，并随着年龄的增加，心脏总能量降低。\n心功能不全、心衰、高血压长期用药者，糖尿病患者，久坐不动者心脏总能量降低；高血压未用药者，停博患者，心律失常患者，过量运动者心脏总能量升高。\n",
            "type": "慢病管理",
            "num": "3",
            "title": "糖尿病管控",
            "value": "66",
            "level": "5",
            "code": "tc",
            "where": [
                {
                    "where": "[0,40)",
                    "text": "差",
                    "color": "#ED6869",
                    "level": "1"
                },
                {
                    "where": "[40,70)",
                    "text": "中等",
                    "color": "#F8B260",
                    "level": "2"
                },
                {
                    "where": "[70,100]",
                    "text": "正常",
                    "color": "#628DF2",
                    "level": "3"
                }
            ]
        },
        {
            "_id": "60506f73ee1f0e90e87401f1",
            "explain": "心脏总能量反映了心脏的整体储备功能，过低提示心脏储备能量不足，过高提示心脏负荷过大。人群的参考范围为2000~6000，并随着年龄的增加，心脏总能量降低。\n心功能不全、心衰、高血压长期用药者，糖尿病患者，久坐不动者心脏总能量降低；高血压未用药者，停博患者，心律失常患者，过量运动者心脏总能量升高。\n",
            "type": "慢病管理",
            "num": "4",
            "title": "血压调节机能",
            "value": "66",
            "level": "5",
            "code": "hcNot",
            "where": [
                {
                    "where": "[0,40)",
                    "text": "差",
                    "color": "#ED6869",
                    "level": "1"
                },
                {
                    "where": "[40,70)",
                    "text": "中等",
                    "color": "#F8B260",
                    "level": "2"
                },
                {
                    "where": "[70,100]",
                    "text": "正常",
                    "color": "#628DF2",
                    "level": "3"
                }
            ]
        },
        {
            "_id": "60506f8cee1f0e90e87401f2",
            "explain": "心脏总能量反映了心脏的整体储备功能，过低提示心脏储备能量不足，过高提示心脏负荷过大。人群的参考范围为2000~6000，并随着年龄的增加，心脏总能量降低。\n心功能不全、心衰、高血压长期用药者，糖尿病患者，久坐不动者心脏总能量降低；高血压未用药者，停博患者，心律失常患者，过量运动者心脏总能量升高。\n",
            "type": "慢病管理",
            "num": "5",
            "title": "冠状动脉机能",
            "value": "66",
            "level": "5",
            "code": "mcNot",
            "where": [
                {
                    "where": "[0,40)",
                    "text": "差",
                    "color": "#ED6869",
                    "level": "1"
                },
                {
                    "where": "[40,70)",
                    "text": "中等",
                    "color": "#F8B260",
                    "level": "2"
                },
                {
                    "where": "[70,100]",
                    "text": "正常",
                    "color": "#628DF2",
                    "level": "3"
                }
            ]
        },
        {
            "_id": "60506facee1f0e90e87401f3",
            "explain": "心脏总能量反映了心脏的整体储备功能，过低提示心脏储备能量不足，过高提示心脏负荷过大。人群的参考范围为2000~6000，并随着年龄的增加，心脏总能量降低。\n心功能不全、心衰、高血压长期用药者，糖尿病患者，久坐不动者心脏总能量降低；高血压未用药者，停博患者，心律失常患者，过量运动者心脏总能量升高。\n",
            "type": "慢病管理",
            "num": "6",
            "title": "血糖平衡机能",
            "value": "66",
            "level": "5",
            "code": "tcNot",
            "where": [
                {
                    "where": "[0,40)",
                    "text": "差",
                    "color": "#ED6869",
                    "level": "1"
                },
                {
                    "where": "[40,70)",
                    "text": "中等",
                    "color": "#F8B260",
                    "level": "2"
                },
                {
                    "where": "[70,100]",
                    "text": "正常",
                    "color": "#628DF2",
                    "level": "3"
                }
            ]
        },
        {
            "_id": "60586d2305f80035bc19e732",
            "explain": "心脏总能量反映了心脏的整体储备功能，过低提示心脏储备能量不足，过高提示心脏负荷过大。人群的参考范围为2000~6000，并随着年龄的增加，心脏总能量降低。\n心功能不全、心衰、高血压长期用药者，糖尿病患者，久坐不动者心脏总能量降低；高血压未用药者，停博患者，心律失常患者，过量运动者心脏总能量升高。\n",
            "type": "慢病防控",
            "num": "7",
            "title": "慢病防控",
            "value": "66",
            "level": "5",
            "code": "mbs",
            "where": [
                {
                    "where": "[0,51)",
                    "text": "差",
                    "color": "#ED6869",
                    "level": "1"
                },
                {
                    "where": "[51,77)",
                    "text": "中等",
                    "color": "#F8B260",
                    "level": "2"
                },
                {
                    "where": "[77,100]",
                    "text": "正常",
                    "color": "#628DF2",
                    "level": "3"
                }
            ]
        },
        {
            "_id": "5fb47f1075a91d33f0cd5fc0",
            "title": "睡眠总时长",
            "value": "5h25min",
            "level": "5",
            "code": "Sc_tst",
            "where": [
                {
                    "where": "[0,300)",
                    "text": "过少",
                    "color": "#ED6869",
                    "level": "1",
                    "arrow": "↓↓"
                },
                {
                    "where": "[300,420)",
                    "text": "偏少",
                    "color": "#F8B260",
                    "level": "2",
                    "arrow": "↓"
                },
                {
                    "where": "[420,480)",
                    "text": "正常",
                    "color": "#628DF2",
                    "level": "3"
                },
                {
                    "where": "[480,540)",
                    "text": "偏多",
                    "color": "#F8B260",
                    "level": "2",
                    "arrow": "↑"
                },
                {
                    "where": "[540,∞)",
                    "text": "过多",
                    "color": "#ED6869",
                    "level": "1",
                    "arrow": "↑↑"
                }
            ],
            "type": "睡眠",
            "num": 0,
            "explain": "保证充足的睡眠有助于维持身心健康，美国睡眠医学会和睡眠研究学会建议，成年人每晚最佳睡眠时间是7-9小时。但睡眠时间不是评价睡眠质量的唯一标准，只要睡醒后感觉神清气爽、精神饱满即可，习惯性短睡者不需因为低于推荐标准而过分担忧。另外，一味为了延长睡眠时间而赖在床上并不能弥补睡眠不足，反而更不利于获得高质量睡眠，可以通过短暂午睡来弥补夜间睡眠不足。"
        },
        {
            "_id": "5fb47f1075a91d33f0cd5fc1",
            "title": "睡眠效率",
            "value": "0.58",
            "level": "3",
            "code": "sffcy2",
            "where": [
                {
                    "where": "[0,0.5)",
                    "text": "低",
                    "color": "#ED6869",
                    "level": "1"
                },
                {
                    "where": "[0.5,0.85)",
                    "text": "偏低",
                    "color": "#F8B260",
                    "level": "2"
                },
                {
                    "where": "[0.85,1)",
                    "text": "正常",
                    "color": "#628DF2",
                    "level": "3"
                }
            ],
            "type": "睡眠",
            "num": 1,
            "explain": "高效的睡眠，对于增强智力和体力起着重要作用，睡眠效率大于90%为优秀，85%为正常。难入睡者入睡需时太长，易醒者在睡眠中清醒次数增多，都是导致睡眠效率不高的直接原因。"
        },
        {
            "_id": "5fb47f1075a91d33f0cd5fc2",
            "title": "入睡需时",
            "value": "45min",
            "level": "3",
            "code": "st",
            "where": [
                {
                    "where": "[0,1800)",
                    "text": "正常",
                    "color": "#628DF2",
                    "level": "3"
                },
                {
                    "where": "[1800,3600)",
                    "text": "偏多",
                    "color": "#F8B260",
                    "level": "2",
                    "arrow": "↑"
                },
                {
                    "where": "[3600,∞)",
                    "text": "过多",
                    "color": "#ED6869",
                    "level": "1",
                    "arrow": "↑↑"
                }
            ],
            "type": "睡眠",
            "num": 2,
            "explain": "从躺在床上至入睡所需要的时长，正常人入睡应该在0.5小时以内。如果超过半小时仍然睡不着就可能存在失眠，失眠是睡眠障碍中最常见的一种。随着生活压力的增大和一系列社会事件的增多，失眠发生率升高；此外一系列电子产品的广泛应用，也大大增加了入睡困难的发生率，与此同时也导致焦虑发生率升高。白天注意运动锻炼，晚上减少使用电子产品，如果睡不着建议起来到客厅或者书房看书或做其它活动再到床上来睡觉，不要使劲在床上睡。"
        },
        {
            "_id": "5fb47f1075a91d33f0cd5fc3",
            "title": "睡眠连续性",
            "value": "0.52",
            "level": "3",
            "code": "SleepContinuity",
            "where": [
                {
                    "where": "[0,0.75)",
                    "text": "低",
                    "color": "#ED6869",
                    "level": "1"
                },
                {
                    "where": "[0.75,0.90)",
                    "text": "偏低",
                    "color": "#F8B260",
                    "level": "2"
                },
                {
                    "where": "[0.90,∞)",
                    "text": "正常",
                    "color": "#628DF2",
                    "level": "3"
                }
            ],
            "type": "睡眠",
            "num": 3,
            "explain": "睡眠过程不间断，则为睡眠连续性好，是评价睡眠质量的其中一个标准，对体力恢复、情绪调节和增强记忆力都有重要作用。典型的睡眠状态转换为浅睡-深睡-浅睡-REM（快速眼动），为一个睡眠周期，随即进入下一次的睡眠周期。但如果睡眠过程中觉醒次数较多，醒后难以再次入睡，则直接影响睡眠的连续性。"
        },
        {
            "_id": "5fb47f1175a91d33f0cd5fc4",
            "title": "睡眠周期",
            "value": "5个",
            "level": "3",
            "code": "sct",
            "where": [
                {
                    "where": "[1,3)",
                    "text": "少",
                    "color": "#F8B260",
                    "level": "2"
                },
                {
                    "where": "[3,6)",
                    "text": "正常",
                    "color": "#628DF2",
                    "level": "3"
                },
                {
                    "where": "[6,∞)",
                    "text": "多",
                    "color": "#F8B260",
                    "level": "2"
                }
            ],
            "type": "睡眠",
            "num": 4,
            "explain": "典型的睡眠状态转换为浅睡-深睡-浅睡-REM（快速眼动），为一个睡眠周期，随即进入下一次的睡眠周期。但实际过程中不一定会经历所有的睡眠状态，睡眠状态的转换也不一定是完全规律的。7小时睡眠中睡眠周期3-6个是正常的。"
        },
        {
            "_id": "5fb47f1175a91d33f0cd5fc5",
            "title": "浅睡眠",
            "value": "0.45",
            "level": "3",
            "code": "slr",
            "where": [
                {
                    "where": "[0,45)",
                    "text": "少",
                    "color": "#F8B260",
                    "level": "2"
                },
                {
                    "where": "[45,65)",
                    "text": "正常",
                    "color": "#628DF2",
                    "level": "3"
                },
                {
                    "where": "[65,∞)",
                    "text": "多",
                    "color": "#F8B260",
                    "level": "2"
                }
            ],
            "type": "睡眠",
            "num": 5,
            "explain": "浅睡眠阶段的心率和呼吸比清醒时减慢，人在浅睡眠时容易被唤醒，对人体疲劳消除、精力恢复的作用不如深睡眠，但浅睡眠是人从深睡眠过度到清醒的保护机制，是一种必须的生理需求。浅睡眠的正常比例为45%~65%。"
        },
        {
            "_id": "5fb47f1175a91d33f0cd5fc6",
            "title": "深睡眠",
            "value": "0.3",
            "level": "3",
            "code": "dpr",
            "where": [
                {
                    "where": "[0,15)",
                    "text": "少",
                    "color": "#F8B260",
                    "level": "2",
                    "arrow": "↓"
                },
                {
                    "where": "[15,25)",
                    "text": "正常",
                    "color": "#628DF2",
                    "level": "3",
                    "arrow": "-"
                },
                {
                    "where": "[25,∞)",
                    "text": "多",
                    "color": "#F8B260",
                    "level": "2",
                    "arrow": "↑"
                }
            ],
            "type": "睡眠",
            "num": 6,
            "explain": "深睡眠，也成为“黄金睡眠”，深睡眠对机体细胞修复、生长激素分泌、增强免疫、消除疲劳、精力恢复有重要作用。正常人的深睡眠比例为15%~25%，缺乏深睡将可能出现代谢紊乱、免疫力下降、精神疲劳等；深睡眠比例长期过高则可能是某些疾病发作的信号，需寻找专业医生的指导。"
        },
        {
            "_id": "5fb47f1175a91d33f0cd5fc7",
            "title": "REM睡眠",
            "value": "0.15",
            "level": "3",
            "code": "remr",
            "where": [
                {
                    "where": "[0,15)",
                    "text": "少",
                    "color": "#F8B260",
                    "level": "2"
                },
                {
                    "where": "[15,25)",
                    "text": "正常",
                    "color": "#628DF2",
                    "level": "3"
                },
                {
                    "where": "[25,∞)",
                    "text": "多",
                    "color": "#F8B260",
                    "level": "2"
                }
            ],
            "type": "睡眠",
            "num": 7,
            "explain": "REM睡眠，即快速眼动睡眠，得名于此睡眠期内眼球的特征性快速运动，睡梦多发生在这个阶段，如果在这个阶段被唤醒，大多数人都可能说自己在做梦。快速眼动睡眠对记忆力形成、情绪调节、维持精神健康起着重要作用。正常人的REM睡眠比例为15%~25%，REM睡眠不足会影响记忆形成，导致记忆力下降，并且增加全因死亡风险和老年痴呆风险。REM睡眠过多则会影响身体得不到完全恢复，易怒很难控制自己情绪。"
        },
        {
            "explain": "睡眠过程中觉醒次数，会直接影响睡眠连续性，影响睡眠质量，正常人睡眠中间觉醒次数不超过2次。",
            "type": "睡眠1",
            "num": 8,
            "title": "清醒次数",
            "value": "2次",
            "level": "3",
            "code": "AwakeTimes",
            "where": [
                {
                    "where": "[0,2)",
                    "text": "正常",
                    "color": "#628DF2",
                    "level": "3"
                },
                {
                    "where": "[2,4)",
                    "text": "偏多",
                    "color": "#F8B260",
                    "level": "2"
                },
                {
                    "where": "[4,∞)",
                    "text": "过多",
                    "color": "#ED6869",
                    "level": "1"
                }
            ]
        },
        {
            "_id": "5fb47f1175a91d33f0cd5fc9",
            "title": "离枕次数",
            "value": "2次",
            "level": "3",
            "code": "ofbdc2",
            "where": [
                {
                    "where": "[0,2)",
                    "text": "正常",
                    "color": "#628DF2",
                    "level": "3"
                },
                {
                    "where": "[2,4)",
                    "text": "偏多",
                    "color": "#F8B260",
                    "level": "2"
                },
                {
                    "where": "[4,∞)",
                    "text": "过多",
                    "color": "#ED6869",
                    "level": "1"
                }
            ],
            "type": "睡眠",
            "num": 9
        },
        {
            "_id": "5fb47f1275a91d33f0cd5fca",
            "title": "体动",
            "value": "210次",
            "level": "4",
            "code": "mct",
            "where": [
                {
                    "where": "[0,50)",
                    "text": "偏少",
                    "color": "#F8B260",
                    "level": "2"
                },
                {
                    "where": "[50,200)",
                    "text": "正常",
                    "color": "#628DF2",
                    "level": "3"
                },
                {
                    "where": "[200,300)",
                    "text": "偏多",
                    "color": "#F8B260",
                    "level": "2"
                },
                {
                    "where": "[300,∞)",
                    "text": "过多",
                    "color": "#ED6869",
                    "level": "1"
                }
            ],
            "type": "睡眠",
            "num": 10,
            "explain": "睡眠过程中体动次数的多少反映了睡眠质量的高低，正常人体动次数为50~200次，体动过多通常是因为睡眠不安、早醒、难入睡等，而睡眠呼吸暂停患者由于翻身较多、身体抽动等原因，体动次数通常也较多。"
        },
        {
            "_id": "5fb5ef8242cb97122a2243de",
            "type": "睡眠值",
            "num": 0,
            "title": "睡眠值",
            "value": "99",
            "level": "5",
            "code": "sleepValue",
            "where": [
                {
                    "where": "[0,30)",
                    "text": "差",
                    "color": "#ed6869",
                    "level": "1"
                },
                {
                    "where": "[30,50)",
                    "text": "较差",
                    "color": "#ee8b8a",
                    "level": "1"
                },
                {
                    "where": "[50,70)",
                    "text": "中等",
                    "color": "#f8b260",
                    "level": "2"
                },
                {
                    "where": "[70,90)",
                    "text": "较好",
                    "color": "#95a1e9",
                    "level": "2"
                },
                {
                    "where": "[90,100)",
                    "text": "好",
                    "color": "#628DF2",
                    "level": "3"
                }
            ]
        },
        {
            "type": "魅力值",
            "num": 0,
            "title": "魅力值",
            "value": "99",
            "level": "5",
            "code": "gmml",
            "where": [
                {
                    "where": "[-20,30)",
                    "text": "差",
                    "color": "#ed6869",
                    "level": "1"
                },
                {
                    "where": "[30,60)",
                    "text": "较差",
                    "color": "#ee8b8a",
                    "level": "1"
                },
                {
                    "where": "[60,80)",
                    "text": "中等",
                    "color": "#f8b260",
                    "level": "2"
                },
                {
                    "where": "[80,90)",
                    "text": "较好",
                    "color": "#95a1e9",
                    "level": "2"
                },
                {
                    "where": "[90,100)",
                    "text": "好",
                    "color": "#628DF2",
                    "level": "3"
                }
            ]
        },
        {
            "type": "孕气指数",
            "num": 0,
            "title": "孕气指数",
            "value": "99",
            "level": "5",
            "code": "gmyq",
            "where": [
                {
                    "where": "[0,0.2)",
                    "text": "低",
                    "color": "#ed6869",
                    "level": "1"
                },
                {
                    "where": "[0.2,0.4)",
                    "text": "偏低",
                    "color": "#f8b260",
                    "level": "2"
                },
                {
                    "where": "[0.4,0.55)",
                    "text": "中",
                    "color": "#628DF2",
                    "level": "3"
                },
                {
                    "where": "[0.55,0.7)",
                    "text": "较高",
                    "color": "red",
                    "level": "1"
                },
                {
                    "where": "[0.7,101)",
                    "text": "高",
                    "color": "red",
                    "level": "1"
                }
            ]
        },
        {
            "type": "水润指数",
            "num": 0,
            "title": "水润指数",
            "value": "99",
            "level": "5",
            "code": "gmsr",
            "where": [
                {
                    "where": "[0,0.3)",
                    "text": "较低",
                    "color": "#ed6869",
                    "level": "1"
                },
                {
                    "where": "[0.3,0.5)",
                    "text": "中等偏低",
                    "color": "#f8b260",
                    "level": "2"
                },
                {
                    "where": "[0.5,0.7)",
                    "text": "中等偏低",
                    "color": "#628DF2",
                    "level": "3"
                },
                {
                    "where": "[0.7,101)",
                    "text": "较高",
                    "color": "red",
                    "level": "1"
                }
            ]
        },
        {
            "type": "易胖指数",
            "num": 0,
            "title": "易胖指数",
            "value": "99",
            "level": "5",
            "code": "gmyp",
            "where": [
                {
                    "where": "[0,0.3)",
                    "text": "较低",
                    "color": "#ed6869",
                    "level": "1"
                },
                {
                    "where": "[0.3,0.5)",
                    "text": "中等",
                    "color": "#f8b260",
                    "level": "2"
                },
                {
                    "where": "[0.5,0.7)",
                    "text": "中等偏高",
                    "color": "#628DF2",
                    "level": "3"
                },
                {
                    "where": "[0.7,101)",
                    "text": "较高",
                    "color": "red",
                    "level": "1"
                }
            ]
        }
    ],
    nothealValue: '您的睡眠时长不满4小时，无法为您提供更多完整数据，继续使用设备解锁更多吧~'
}


const amExplainFn = function (a, s) {
    var explain = amExplainConfig;
    var q = explain[a]
    if (q) {
      if (typeof q == 'string') {
        return q
      } else {
        var str = ''
        $(q).each(function (index, item) {
          var w = item.where
          // ;
          var re = new RegExp('\\' + a + '', 'g')
          var bo = w.replace(re, s)
          if (eval(bo)) {
            str = item.result
          }
        })
        return str
      }
    }
  }

export default {
    baseConfig,
    reportConfig,
    amExplainConfig,
    amExplainFn
};
