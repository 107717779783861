<template>
  <div id="slider" class="collapse-wrapper">
    <!-- header -->
    <div class="collapse-header">
      <div class="title">
        <img
          v-if="!Immunity.leftIco"
          class="icon-img"
          :src="require('@/assets/images/day/mianyiliIco.png')"
        />
        {{ Immunity.title }}
      </div>
      <div
        id="ImmunityCount"
        ref="ImmunityCountref"
        class="textcenter score"
      ></div>
      <div
        @click="handleToggle"
        id="miyiuliJiao"
        ref="miyiuliJiao"
        class="right-operate"
      >
        <div ref="ImmunityState" id="ImmunityState"></div>
        <van-icon class="van-cell__right-icon" name="arrow" />
      </div>
    </div>

    <div ref="click_show_li_show2" id="click_show_li_show">
      <div class="steps-flex" ref="htmlvref"></div>
      <div ref="click_show_li" class="information" id="click_show_li"></div>

      <div v-show="Immunity.showRightIco">
        <div v-if="Immunity.jxz" class="jxz">
          基线值: <span> {{ Immunity.jxz }}</span>
        </div>
        <div class="canvas-box" v-if="Immunity.canvas" ref="click_show_li2" id="dd">
          <canvas
            :id="Immunity.code.replace('/', '')"
            ref="myCanvas"
            :height="height"
          ></canvas>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Icon, Toast } from "vant";

Vue.use(Icon);
Vue.use(Toast);
import baseConfig from "@/base";
import { Canvas_Chat } from "@/components/clip.js";
export default {
  props: {
    Immunity: {
      type: Object,
    },
  },
  components: {},
  data() {
    return {
      height: 200,
    };
  },
  mounted() {
    this.initHtml();
    var q = this.Immunity.notInit;
    if (q) {
      this[q]();
    } else {
      this.init();
    }
  },
  methods: {
    temm() {
      var q = this.Immunity;

      if (q.canvas) {
        var canvasData = q.canvasData;
        if (q.code == "Sc_tst") {
        }

        Canvas_Chat(q.code.replace("/", ""), canvasData, 200);
      }
    },
    //切换
    handleToggle() {
      var q = this.Immunity;

      if (q.rightclick) {
        q.rightclick();
        return;
      }

      var click_show_li_show2 = this.$refs.click_show_li_show2;
      $(click_show_li_show2).slideToggle("fast", () => {
        if (!click_show_li_show2.temm) {
          setTimeout(() => {
            this.temm();
          }, 100);
        }
        click_show_li_show2.temm = true;

        var display = click_show_li_show2.style.display;

        var miyiuliJiao = this.$refs.miyiuliJiao;
        if (display == "block") {
          miyiuliJiao.className = "turn right-operate";
        } else {
          miyiuliJiao.className = "right-operate";
        }
      });
    },
    initHtml() {
      var data = this.Immunity.data;
      if (this.Immunity.code == "ht") {
      }

      var pu = [];
      data.forEach((u, i) => {
        pu.push(`
                     <div class="col">
             ${
               u.num == ""
                 ? `<div class="_hidden topnum" style="text-align: left;">0</div>`
                 : `<div class="topnum" style="text-align: left;">${u.num}</div>`
             }
             ${
               u.color && u.color[0] == "#"
                 ? `<div style="background-color:${u.color}" class="_slide "></div>`
                 : `<div class="_slide ${u.color}"></div>`
             } 
              <div class="text-center">${u.text}</div>
            </div>
            ${
              u.num2 != ""
                ? `<div class="  text-center">
              <div class="${
                data.length - 1 == i ? `lastnum2 topnum` : `topnum`
              }">${u.num2}</div>
            ${data.length - 1 != i ? `<div class="_shu_border"></div>` : ``}  
            </div>`
                : ``
            }
    
         
         `);
      });

      var o = pu.join("");
      var htmlvref = this.$refs.htmlvref;
      htmlvref.innerHTML = o;
    },
    sleep2DetailInit() {
      var q = this.Immunity;

      var ImmunityCountref = this.$refs.ImmunityCountref;
      ImmunityCountref.style.color = q.color;
      ImmunityCountref.innerHTML = q.score;
      var ImmunityState = this.$refs.ImmunityState;
      ImmunityState.innerHTML = q.text;
      ImmunityState.style.color = q.color;
      var jieshiTExt = baseConfig.amExplainFn(q.code, q.score);
      if (jieshiTExt) {
        var click_show_li = this.$refs.click_show_li;
        click_show_li.innerHTML = jieshiTExt;
      }
      if (q.code == "ht") {
        var click_show_li = this.$refs.click_show_li;
        click_show_li.innerHTML = `是指整晚睡眠中发生低通气的总次数，用整晚低通气总次数除以睡眠时间得到低通气指数(HI,Hypopnea Index)，代表每小时发生低通气的次数。HI与呼吸暂停指数(AI)组成了AHI，用来判断睡眠呼吸暂停的严重程度。`;
      }
      if (q.code == "brz") {
        //var click_show_li = this.$refs.click_show_li
        //click_show_li.innerHTML = `是指整晚睡眠中发生呼吸暂停的总次数，呼吸暂停是指睡眠中呼吸运动停止10秒以上则记录为1次。整晚的呼吸暂停总次数除以睡眠时间得到呼吸暂停指数(AI,Apnea Index)，代表每小时发生呼吸暂停的次数。AI与低通气指数(HI)组成了AHI，用来判断睡眠呼吸暂停的严重程度。`
      }

      for (let qd = 0; qd < q.data.length; qd++) {
        var item = q.data[qd];
        if (item.text == q.text) {
          var htmlvref = this.$refs.htmlvref;

          //this.Immunity.code

          var dt = $(htmlvref).find("._slide");
          var left = item.left;
          //if(q.code=='SleepContinuity')
          //{
          //            ;
          //  var mmk=left.replace('%','');
          //  left=(Number(mmk)*100)+'%';
          //}

          var html = this.html3(item.left, left, item.level);
          dt.eq(qd).parent().append(html);
          break;
        }
      }
      if (q.showcanvas) {
        this.temm();
      }
    },
    init() {
      var Immunityscore = this.Immunity.score;
      var where = this["Immunity"].where;
      for (var w = 0; w < where.length; w++) {
        var itemwhere = where[w].where.replace(/a/g, Immunityscore);
        if (eval(itemwhere)) {
          this["Immunity"].Select = where[w];

          var score = Math.floor(Immunityscore);
          this.$refs.ImmunityCountref.innerHTML = score;
          this.$refs.ImmunityState.innerHTML =
            '<div class="__' +
            where[w].img +
            '">' +
            where[w].whereResult +
            "</div> ";
          this.$refs.click_show_li.innerHTML = where[w].result;
          var htmlvref = this.$refs.htmlvref;
          var dt = $(htmlvref).find("._slide");
          var min = where[w].min;
          var max = where[w].max;
          var left = ((score - min) / (max - min)) * 100 + "%";

          if ("gr" == this.Immunity.ty) {
            var html = this.html2(score, left, where[w].img);
            dt.eq(w).parent().append(html);
          } else {
            var html = this.html(score, left, where[w].img);

            dt.eq(w).parent().append(html);
          }
        }
      }
    },
    html3: function (a, b, c) {
      return (
        '<div  style="left: ' +
        b +
        '"; class="_select _selectsorce" ><img class="c_img" src="http://www.senviv.com/litepro/report/' +
        c +
        '.png"> </div>'
      );
    },
    html: function (a, b, c) {
      return (
        '<div style="left: ' +
        b +
        '"; class="_select _selectsorce" ><img class="c_img" src="http://api.ryouhu.senviv.com/docs/_aaa/' +
        c +
        '.png"> </div>'
      );
    },
    html2: function (a, b, c) {
      return (
        '<div style="right: ' +
        b +
        '"; class="_select2 _selectsorce2" ><img class="c_img" src="http://api.ryouhu.senviv.com/docs/_aaa/' +
        c +
        '.png"></div>'
      );
    },
  },
};
</script>

<style lang="less" scoped>
.collapse-wrapper {
  .collapse-header {
    display: flex;
    align-items: center;
    padding: 10px 0;
    .title {
      flex: 1;
      font-size: 14px;
      font-weight: 500;
      display: flex;
      align-items: center;
      .icon-img {
        margin-right: 4px;
        width: 14px;
      }
    }
    .score {
      flex: 1;
      font-size: 16px;
      font-weight: 500;
      color: #178355;
    }
    .right-operate {
      flex: 1;
      text-align: right;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 14px !important;
      .van-cell__right-icon {
        color: #969799;
      }
      &.turn {
        .van-cell__right-icon {
          transform: rotate(-90deg);
        }
      }
    }
  }

  .steps-flex {
    display: flex;
    font-size: 12px;
    color: #969799;
    /deep/ .col {
      flex: 1;
      position: relative;
      ._slide {
        height: 8px;
        background: red;
        border-radius: 5px;
        margin: 15px 0px;
      }

      ._hidden {
        visibility: hidden;
      }
    }
    /deep/ .text-center {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      white-space: nowrap;
    }
    /deep/ ._shu_border {
      width: 1px;
      border-left: 1px dashed #c5c5c5;
      height: 44px;
      display: inline-block;
      box-sizing: border-box;
    }
    /deep/ .topnum {
      padding: 5px 0;
    }
    /deep/ ._select {
      position: absolute;
      top: 18px;
      z-index: 2;
    }
    /deep/ ._selectsorce {
      color: red;
      font-weight: 900;
      font-size: 7px;
      text-align: center;
      border-radius: 4px;
      display: inline-block;
      width: 20px;
      -webkit-transform: translateX(-10px);
      transform: translateX(-10px);
      .c_img {
        width: 20px;
        margin-top: 14px;
      }
    }
  }
  .information {
    font-size: 12px;
  }
  .canvas-box {
    > canvas {
      width: 100%;
    }
  }
}

#click_show_li_show {
  color: gray;
}
.c_img {
  width: 20px;
  margin-top: 14px;
}
.am-red {
  font-size: 12px;
}

.row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.col {
  flex: 1;
}

.gantanhao {
  width: 8px;
  height: 8px;
}

.miyiliH1 {
  font-size: 17px;
}

.smallroujiantou {
  width: 7px;
}

.textcenter {
  text-align: center;
}

.textright {
  text-align: right;
  justify-content: flex-end;
  display: flex;
  align-items: center;
}

.roujiantou {
  margin-left: 15px;
  width: 10px;
  height: 15px;
}

.miyili .row {
  padding: 10px 0px;
}

.miyili {
  border-radius: 8px;
  border-left: 7px solid #4391ec;
}

.flexleft {
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.textright2 {
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
  align-content: center;
  color: gray;
  font-size: 10px;
  text-align: right;
}

._slide {
  height: 8px;
  background: red;
  border-radius: 5px;
  margin: 15px 0px;
}

.text-center {
  text-align: center;
  position: relative;
}

._shu_border {
  width: 1px;
  border-left: 1px dashed #c5c5c5;
  height: 44px;
  display: inline-block;
  box-sizing: border-box;
}

._flex {
  display: flex;
  font-size: 12px;
}

._hidden {
  visibility: hidden;
}

.text-right {
  text-align: right;
}

._slidered {
  background-color: #ed6869 !important;
}

._slidefengred {
  background-color: #ee8b8a !important;
}

._slideyellow {
  background-color: #f8b260 !important;
}

._slidegreen {
  background-color: #95e9d4 !important;
}

._slidegreen2 {
  background-color: #62e4c4 !important;
}

._flex {
  color: gray;
}

._flex .col {
  position: relative;
}
._flex {
  .topnum {
    padding: 5px 0;
  }
  .text-center {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }
}

._select {
  position: absolute;
  top: 19px;
  z-index: 2;
}

._selectsorce {
  color: red;
  font-weight: 900;
  font-size: 7px;
  text-align: center;
  border-radius: 4px;
  display: inline-block;
  width: 20px;
  transform: translateX(-10px);
}

._select2 {
  position: absolute;
  top: 13px;
  z-index: 2;
}

._selectsorce2 {
  color: red;
  font-weight: 900;
  font-size: 7px;
  text-align: center;
  border-radius: 4px;
  display: inline-block;
  width: 20px;
  transform: translateX(10px);
}

.yangzhongyichange {
  background: #ed6869;
}

.yichange {
  background: #f8b260;
}

.zhengchange {
  background: #4391ec;
}

.im_img {
  width: 20px;
  margin-right: 5px;
}

#click_show_li {
  padding: 15px 0px;
  color: gray;
  text-indent: 28px;
}

.__red {
  color: #ed686c;
}

.__yellow {
  color: #f8b260;
}

.__green {
  color: #4391ec;
}

.vi_hiddel {
  visibility: hidden;
}
.abc {
  .van-cell__right-icon {
    transform: rotate(-90deg);
  }
}
.jxz {
  font-size: 14px;
  font-weight: 600;
  color: #6996f4;
  text-align: right;
  span {
    padding-left: 5px;
  }
}
</style>
