
/*
 * 打开数据库
 * @param {object} dbName 数据库的名字
 * @param {string} storeName 仓库名称/表名称
 * @param {string} version 数据库的版本
 * @return {object} 该函数会返回一个数据库实例
 */
function openDB(dbName, storeName, version = 1) {
    return new Promise((resolve, reject) => {
        //  兼容浏览器
        let indexedDB =
            window.indexedDB ||
            window.mozIndexedDB ||
            window.webkitIndexedDB ||
            window.msIndexedDB,
            db;

        // 打开数据库，若没有则会创建
        const request = indexedDB.open(dbName, version);

        // 数据库打开成功回调
        request.onsuccess = function (result) {
            console.log('数据库打开成功')

            // 数据库对象
            db = result.target.result;
            resolve(db);
        };

        // 数据库打开失败的回调
        request.onerror = function (result) {
            reject(result);
        };

        // 数据库有更新时候的回调
        request.onupgradeneeded = function (result) {
            // 数据库创建或升级的时候会触发
            // 数据库对象
            db = result.target.result;
            // 创建存储库/也就是数据表
            let objectStore = db.createObjectStore(storeName, {
                // 这是主键
                // 主键使用的是数据表里的id值
                keyPath: "id",
            });

            // 创建索引/属性，在后面查询数据的时候可以根据索引/属性查
            // 不能缺少的索引
            // objectStore.createIndex("id", "id", { unique: false });
            objectStore.createIndex("reportPar", "reportPar", { unique: false });
        };
    });
}

/*
 * 新增数据
 * @param {string} storeName 仓库名称/表名称
 * @param {string} data 数据
 */
function addData(storeName, data) {
    return new Promise((resolve, reject) => {
        let request = window.$db
            // 事务对象 指定表格名称和操作模式（"只读"或"读写"）
            .transaction([storeName], "readwrite")
            // 仓库对象
            .objectStore(storeName)
            .add(data);

        request.onsuccess = function (result) {
            console.log('数据添加成功')
            resolve(result);
        };

        request.onerror = function (result) {
            console.log('数据添加失败')
            reject(result);
        };
    });
}


/*
 * 通过getSingle读取数据
 * @param {string} storeName 仓库名称/表名称
 */
function getSingle(storeName, key) {
    return new Promise((resolve, reject) => {
        let request = window.$db
            // 事务
            .transaction(storeName, "readwrite")
            // 仓库对象
            .objectStore(storeName)
            // 指针对象
            .get(key);

        // 游标开启成功，逐行读数据
        request.onsuccess = function (event) {
            resolve(event.target);
        };

        request.onerror = function (result) {
            console.log("读取失败")
            reject(result);
        };
    });
}


/*
 * 通过游标读取数据
 * @param {string} storeName 仓库名称/表名称
 */
function cursorGetData(storeName) {
    return new Promise((resolve, reject) => {
        let list = [],
            request = window.$db
                // 事务
                .transaction(storeName, "readwrite")
                // 仓库对象
                .objectStore(storeName)
                // 指针对象
                .openCursor();

        // 游标开启成功，逐行读数据
        request.onsuccess = function (result) {
            let cursor = result.target.result;
            if (cursor) {
                // 必须要检查
                list.push(cursor.value);
                // 遍历了存储对象中的所有内容
                cursor.continue();
            } else {
                console.log('读取成功')
                resolve(list);
            }
        };

        request.onerror = function (result) {
            console.log('读取失败')
            reject(result);
        };
    });
}


/*
 * 更新数据
 * @param {string} storeName 仓库名称/表名称
 * @param {object} data 数据
 */
function updateDB(storeName, data) {
    return new Promise((resolve, reject) => {
        let request = window.$db
            // 事务对象
            .transaction([storeName], "readwrite")
            // 仓库对象
            .objectStore(storeName)
            .put(data);

        request.onsuccess = function (result) {
            console.log("更新成功")
            resolve(result);
        };

        request.onerror = function (result) {
            console.log("更新失败")
            reject(result);
        };
    });
}

/*
 * 通过主键删除数据
 * @param {string} storeName 仓库名称/表名称
 * @param {object} id 主键值
 */
function deleteDB(storeName, id) {
    return new Promise((resolve, reject) => {
        let request = window.$db
            .transaction([storeName], "readwrite")
            .objectStore(storeName)
            .delete(id);

        request.onsuccess = function (result) {
            console.log("删除成功");
            resolve(result);
        };

        request.onerror = function (result) {
            console.log("删除失败");
            reject(result);
        };
    });
}

let indexedDB = {
    deleteDB,
    updateDB,
    getSingle,
    cursorGetData,
    addData,
    openDB,
};

export default indexedDB;
