<template>
  <div class="circle--content">
    <van-circle
      v-model="healthCount"
      :rate="healthCount"
      color="#48C285"
      layer-color="#ebedf0"
      stroke-width="90"
      size="80px"
      :speed="100"
    />
    <p class="center-score">{{scoreText ? scoreText : healthCount}}<span class="unit">分</span></p>
  </div>
</template>

<script>
import Vue from "vue";
import { Circle } from "vant";

Vue.use(Circle);
export default {
  name: "Circular",
  data() {
    return {
        healthCount: 0,
        scoreText:''
    };
  },
  methods: {
    init() {
      this.healthCount = Math.floor(this.score);
    }
  }
};
</script>


<style lang="less" scoped>
.circle--content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .center-score {
    position: absolute;
    .unit {
      font-size: 12px;
    }
  }
}
</style>