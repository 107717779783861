<template>
  <div class="huitu">
    <div class="huitu__legend">
      <div class="line-list">
        <!-- <div class="item item--va">血氧</div> -->
        <div class="item item--vb">心率</div>
        <div class="item item--vc">呼吸</div>
      </div>
      <!-- legend -->
      <div class="sleep-chart-tip">
        <div class="placeholder">
          深睡
          <span class="itemIco deepsleep"></span>
        </div>
        <div class="placeholder">
          浅睡
          <span class="itemIco shallowsleep"></span>
        </div>
        <div class="placeholder">
          REM
          <span class="itemIco rem"></span>
        </div>
        <div class="placeholder">
          清醒
          <span class="itemIco qingx"></span>
        </div>
        <div class="placeholder">
          离枕
          <span class="itemIco lc"></span>
        </div>
      </div>
    </div>

    <div id="a_canvas_parent">
      <canvas id="a_canvas" height="430"></canvas>
    </div>
    <div id="canvas_bparent">
      <canvas id="bodymove" height="80"></canvas>
    </div>

    <div class="brstop">
      <span class="item" @click="stop_click_span" id="stop_click_span"
        >0次呼吸暂停</span
      >
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Icon, Toast } from "vant";
Vue.use(Icon);
Vue.use(Toast);
import {
  drawBodyMove,
  drawSleep,
  huxiclick,
  processedIndexData,
} from "@/components/reportCom.js";
import slider from "./slider.vue";

export default {
  components: {
    slider,
  },
  data() {
    return {
      reportData: {}, //报告详情
    };
  },
  mounted() {
    $("#stop_click_span").click(function () {
      huxiclick();
    });
  },
  methods: {
    //初始化
    init(reportDetail) {
      this.reportData = reportDetail;
      //获取4g图表数据
      this.get4GReportChartData();
    },
    //获取4g图表数据
    get4GReportChartData() {
      this.$axios
        .get("https://health.ruinetworks.com/api/health/lite4g/devReportData", {
          params: {
            reportId: this.$route.query.id,
          },
          headers: {
            user_token: this.$route.query.token,
          },
        })
        .then((res) => {
          Toast.clear();
          if (res.data.success == "true") {
            const data = res.data.data.reportData;
            var dk = this.sleepdata(data);

            var reportsleep = this.builderReportOfSleep(data.s, data.r.tbase);

            var breath = this.builderReportOfBreath(data.b, data.r.tbase);

            //心率
            var xinlu = data.c.trendchart.filter((u) => u.type == 2106)[0];

            var huxi = data.c.trendchart.filter((u) => u.type == 2107)[0]; //.xdatavalue

            let xueyang = data.c.trendchart.filter((u) => u.type === 2108)[0];

            var ot = {};

            //呼吸数据
            ot.breatdata = huxi.xdatavalue;
            //心率
            ot.heartratedata = xinlu.xdatavalue;
            // 血氧
            ot.bloodData = (xueyang && xueyang.xdatavalue) || [];
            //报告开始时间
            ot.starttime = this.reportData.StartTime; //.ToLong();
            //报告结束时间
            ot.endtime = this.reportData.FinishTime;
            ot.sleepstarttime = this.reportData.OnbedTime;
            ot.sleependtime = this.reportData.OffbedTime;

            //睡眠数据
            ot.sleepdata = dk.ReportBarChart.filter(
              (u) => u.ChartTypeId == 2110
            )[0].Items;

            ot.bodymove = reportsleep.Moves;
            ot.breatstop = breath.ReportOfBreathPause;
            this.huitu2(ot, this.reportData);
          }
        });
    },
    builderReportOfBreath(breath, basetime) {
      //构建呼吸报文模块
      var reportOfBreathPause = [];
      for (var i = 0; i < breath.p.length; i++) {
        var pitem = breath.p[i];
        var item = {
          StartTime: (basetime + pitem.s) * 1000,
          EndTime: (basetime + pitem.e) * 1000,
          starttime: (basetime + pitem.s) * 1000,
          endtime: (basetime + pitem.e) * 1000,
          Interval: pitem.i,
        };
        reportOfBreathPause.push(item);
      }
      return {
        ReportOfBreathPause: reportOfBreathPause,
        BreathMaxCount: breath.mc,
        BreathMax: breath.bmx,
        AvgPause: breath.avgp,
        BreathMin: breath.bmm,
        HigherCounts: breath.pc,
        Longest: breath.lpc,
        LowerCounts: breath.ht,
        Pause10Counts: breath.pc,
        Pause30Counts: breath.pc3,
        PauseSum: breath.ps,
        Average: breath.avg,
        Shortest: breath.spc,
        AHI: breath.ahi,
      };
    },
    builderReportOfSleep(sleep, baseTicks) {
      var moves = [];
      for (var i = 0; i < sleep.mv.length; i++) {
        var item = sleep.mv[i];
        moves.push({
          starttime: (baseTicks + item.s) * 1000,
          endtime: (baseTicks + item.e) * 1000,
          score: 0,
        });
      }
      return {
        ShallowRatio: sleep.slr,
        DeepRatio: sleep.dpr,
        RemRatio: sleep.remr,
        Rem: sleep.rem,
        MoveCounts: sleep.mct,
        Shallow: sleep.sl,
        Deep: sleep.dp,
        SleepCounts: sleep.sct,
        SleepTime: sleep.st,
        TotalTime: sleep.tt,
        Moves: moves,
        SoberRatio: sleep.srr,
        OffbedRatio: sleep.ofr,
        Sober: sleep.sr,
        Offbed: sleep.of,
        MovingAverageLength: sleep.mvavg,
      };
    },

    sleepdata(rpt) {
      var charts = rpt.c;
      var baseTicks = rpt.r.tbase;
      var newCharts = {
        ReportBarChart: [],
        ReportTrendChart: [],
        //"ReportDaysTrentChart": reportDaysTrentChart
      };
      for (var i = 0; i < charts.barchart.length; i++) {
        var item = charts.barchart[i];
        var ReportBarChartItem = {
          ChartTypeId: item.type,
          Title: "",
          Comments: "",
          Items: [],
          ExtColumn1: "",
          ExtColumn2: "",
          ExtColumn3: "",
        };

        for (var j = 0; j < item.items.length; j++) {
          var sjitem = item.items[j];
          var subitem = {
            endtime: rpt.r.ft + baseTicks,
            starttime: rpt.r.st + baseTicks,
            subitems: [],
          };
          for (var x = 0; x < sjitem.sub.length; x++) {
            subitem.subitems.push({
              starttime: sjitem.sub[x].st + baseTicks,
              endtime: sjitem.sub[x].et + baseTicks,
              type: sjitem.sub[x].type,
            });
          }
          ReportBarChartItem.Items.push(subitem);
        }

        newCharts.ReportBarChart.push(ReportBarChartItem);
      }
      return newCharts;
    },

    huitu2(mk, reportData) {
      var overalltrend = mk; //睡眠结构图数据 lhr  lbr

      var fn = (arr) => {
        arr.forEach((x, i) => {
          if (x === 0) {
            var q = arr[i - 1];
            if (q) {
              arr[i] = q;
            }
          }
        });
      };
      fn(overalltrend.breatdata);
      fn(overalltrend.heartratedata);
      processedIndexData(overalltrend, overalltrend.breatdata);
      processedIndexData(overalltrend, overalltrend.heartratedata);
      //睡眠分析
      drawBodyMove(overalltrend.bodymove, overalltrend);
      //这个是睡眠结构图
      drawSleep(true, overalltrend, reportData);
    },

    goto() {
      this.$router.push({ path: "/sleep2" });
    },
    stop_click_span() {
      //huxiclick()
    },
  },
};
</script>

<style lang="less" scoped>
.huitu__legend {
  padding: 20px 10px 0;

  .line-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .item {
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    flex-basis: 0;
    padding: 0 25px;

    &::before {
      content: "";
      display: inline-block;
      height: 1px;
      margin-right: 8px;
      flex-grow: 1;
      flex-basis: 0;
    }

    &.item--va::before {
      background-color: #ff24c6;
    }

    &.item--vb::before {
      background-color: #ff562d;
    }

    &.item--vc::before {
      background-color: #1679fc;
    }
  }
  .sleep-chart-tip {
    margin-top: 6px;
    display: flex;
    justify-content: space-around;
    .placeholder {
      font-size: 12px;
      .itemIco {
        vertical-align: middle;
      }
    }
  }
}

.brstop {
  display: flex;
  justify-content: center;
}

#sleep2 .sleep-chart-tip .itemIco {
  border-radius: 0;
  width: 12px;
  height: 12px;
}
</style>
