<template>
  <div class="blood-wrapper">
    <div class="banner-wrapper">
      <h2 class="title">血糖数据</h2>
      <p class="report-time">{{ reportTime }}</p>
      <div class="user-info">
        <div class="avatar-img">
          <img :src="userAvatar" alt="" />
        </div>
        <p class="user-name">{{ userName }}</p>
      </div>
    </div>
    <div class="report-wrapper">
      <div class="item">
        <h3 class="van-hairline--bottom">最近一次血糖记录</h3>
        <div class="report-data">
          <div class="xuetang-echart" id="gaugeChart"></div>
        </div>
      </div>
      <div class="item">
        <h3 class="van-hairline--bottom">
          血糖趋势图
          <span class="select-time" @click="handleSelectDate"
            >{{ nowDate == null ? "选择时间" : nowDate
            }}<van-icon class="date-icon" name="arrow" />
          </span>
        </h3>
        <div class="report-echart">
          <div class="echart-wrapper" id="lineChart"></div>
        </div>
      </div>
    </div>
    <van-action-sheet :close-on-click-overlay="false" v-model="isShow" title="">
      <div class="content">
        <van-datetime-picker
          v-model="currentDate"
          type="date"
          title="选择年月日"
          :min-date="minDate"
          :max-date="maxDate"
          @confirm="dateConfirm"
          @cancel="dateCancel"
        />
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import Vue from "vue";
import { Icon, DatetimePicker, ActionSheet, Toast } from "vant";
Vue.use(Icon);
Vue.use(DatetimePicker);
Vue.use(ActionSheet);
Vue.use(Toast);

export default {
  name: "BloodReport",
  data() {
    return {
      reportTime: "",
      userAvatar: "",
      userName: "",
      isShow: false,
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date(),
      xuetangData: {},
      nowDate: null,
    };
  },
  methods: {
    //时间选择
    handleSelectDate() {
      this.isShow = true;
    },
    //时间选择器确定操作
    dateConfirm(value) {
      const selectDate = this.formatDateTime(value);
      this.nowDate = selectDate;
      this.getXuetangChart();
      this.isShow = false;
    },
    formatDateTime(date) {
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return y + "-" + m + "-" + d;
    },
    //时间选择框关闭
    dateCancel() {
      this.isShow = false;
    },
    //获取最新的血糖测量数据
    getData() {
      this.$axios
        .get("https://health.ruinetworks.com/api/health/dev/bloodsugar/news", {
          params: {
            extUserId: this.$route.query.id,
          },
          headers: {
            user_token: this.$route.query.token,
          },
        })
        .then((res) => {
          const dataDetail = res.data;
          if (dataDetail.success == "true") {
            this.xuetangData = dataDetail.data.reportData;
            const percent = this.xuetangData.bloodSugar / 30;
            const value = this.xuetangData.bloodSugar;
            this.initGauge(percent, value);
            this.reportTime = dataDetail.data.createTime;
            this.userAvatar = dataDetail.data.wechatAvatarUrl;
            this.userName = dataDetail.data.userName;
          } else {
            this.initGauge(0, 0);
            Toast(dataDetail.data.msg);
          }
        });
    },
    //血糖
    initGauge(percent, value) {
      let myChart = this.$echarts.init(document.getElementById("gaugeChart"));
      let option = {
        backgroundColor: "#fff",
        series: [
          {
            name: "血糖",
            type: "gauge",
            z: 2,
            radius: "140%",
            splitNumber: 2,
            startAngle: 180,
            endAngle: 0,
            min: 0,
            max: 100,
            center: ["50%", "78%"], //整体的位置设置
            axisLine: {
              lineStyle: {
                color: [
                  [percent, "#1A6EFF"],
                  [1, "#EDEDED"],
                ],
                width: 20,
                opacity: 1,
              },
            },
            splitLine: {
              show: false,
            },
            data: [
              {
                show: false,
                value: this.xuetangData.gluValue,
              },
            ],
            axisLabel: {
              show: false,
            },
            pointer: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            detail: {
              show: false,
            },
          },
          {
            name: "血糖刻度",
            type: "gauge",
            z: 4,
            radius: "100%",
            splitNumber: 10,
            startAngle: 180,
            endAngle: 0,
            center: ["50%", "75%"],
            min: 0,
            max: 30,
            axisLine: {
              lineStyle: {
                color: [
                  [0.8, "#E9E9EC"],
                  [1, "#E9E9EC"],
                ],
                width: 5,
                opacity: 0,
              },
            },
            data: [
              {
                value: 3.4,
              },
            ],
            axisLabel: {
              show: true,
              distance: -10,
              color: "#999",
              padding: 20,
              formatter: function (value) {
                if (value === 0 || value === 30) {
                  return value;
                }
                return "";
              },
            },
            pointer: {
              show: false,
            },
            axisTick: {
              show: true,
              lineStyle: {
                color: "#C9CAD1",
                width: 2,
              },
              length: 6,
              splitNumber: 6,
            },
            detail: {
              formatter: function (params) {
                //图表中间文字数据展示
                return (
                  "{blue|" + value + "\n" + "}{gray|" + "单位: mol/L" + "}"
                );
              },
              rich: {
                gray: {
                  fontSize: 14,
                  color: "#999",
                  padding: 10,
                },
                blue: {
                  fontSize: 54,
                  fontWeight: 700,
                  color: "#1A6EFF",
                },
              },
              offsetCenter: ["0", "-25%"],
            },
            splitLine: {
              length: 20,
              lineStyle: {
                width: 2,
                color: "#C9CAD1",
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    //血糖趋势
    initBloodPressure(timer, meal, data) {
      let myChart = this.$echarts.init(document.getElementById("lineChart"));
      let option = {
        color: ["#1890FF"],
        grid: {
          top: 20,
          left: 20,
          right: 20,
          bottom: 50,
          containLabel: true,
        },
        legend: {
          show: true,
          bottom: 15,
        },
        tooltip: {
          show: true,
          trigger: "axis",
          formatter: function (params) {
            const data = params[0];
            return (
              data.axisValue +
              "<br/>" +
              meal[data.seriesIndex] +
              "：" +
              data.value
            );
          },
        },
        xAxis: {
          show: true,
          type: "category",
          data: timer,
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: true,
            formatter: function (params) {
              const dateTimerArr = params.split(" ");
              return dateTimerArr[1];
            },
          },
          axisLine: {
            lineStyle: {
              color: "#5D6175",
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: "#E9E9EC",
            },
          },
        },
        yAxis: {
          type: "value",
          name: "mol/L",
          nameGap: 10,
          nameTextStyle: {
            color: "#5D6175",
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            color: "#5D6175",
          },
          axisLine: {
            lineStyle: {
              color: "#5D6175",
            },
          },
          splitLine: {
            lineStyle: {
              type: "dashed",
              color: "#E9E9EC",
            },
          },
        },
        series: [
          {
            name: "血糖",
            data: data,
            type: "line",
            symbolSize: 6,
            smooth: true,
          },
        ],
      };
      myChart.setOption(option);
    },
    //获取用户的血糖趋势
    getXuetangChart() {
      this.$axios
        .get("https://health.ruinetworks.com/api/health/dev/bloodsugar/chart", {
          params: {
            extUserId: this.$route.query.id,
            time: this.nowDate,
          },
          headers: {
            user_token: this.$route.query.token,
          },
        })
        .then((res) => {
          const dataDetail = res.data;
          if (dataDetail.success == "true") {
            const data = dataDetail.data;
            //血糖趋势
            this.initBloodPressure(data.time, data.mealValue, data.gluValue);
          }
        });
    },
  },
  created() {
    //获取当前年月日
    const date = new Date();
    const timer =
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1) +
      "-" +
      (date.getDate() < 10 ? "0" + date.getDate() : date.getDate());
    this.nowDate = timer;
  },
  mounted() {
    //获取最新的血糖测量数据
    this.getData();

    this.getXuetangChart();
  },
};
</script>

<style lang="less" scoped>
.blood-wrapper {
  .banner-wrapper {
    position: relative;
    height: 120px;
    background: linear-gradient(180deg, #0cc2a9 0%, #49cdab 100%);
    border-radius: 0 0 30px 30px;
    padding: 10px 24px;
    .title {
      color: #fff;
      font-size: 22px;
      font-weight: 500;
    }
    .report-time {
      font-size: 14px;
      color: #fff;
      font-weight: 400;
      line-height: 36px;
    }
    .user-info {
      position: absolute;
      right: 20px;
      top: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .avatar-img {
        width: 38px;
        height: 38px;
        border-radius: 8px;
        background: #f2f2f2;
        overflow: hidden;
        > img {
          width: 100%;
          height: 100%;
        }
      }
      .user-name {
        margin-top: 6px;
        text-align: center;
        color: #fff;
        font-size: 18px;
        font-weight: 500;
      }
    }
  }
  .report-wrapper {
    position: relative;
    margin-top: -64px;
    padding: 15px;
    .item {
      margin-bottom: 12px;
      border-radius: 16px;
      overflow: hidden;
      background: #fff;
      h3 {
        line-height: 48px;
        position: relative;
        font-size: 18px;
        padding-left: 30px;
        color: #272c47;
        &:before {
          position: absolute;
          content: "";
          width: 8px;
          height: 8px;
          background: #fff;
          border-radius: 50%;
          border: 3px solid #1890ff;
          left: 10px;
          top: 50%;
          transform: translateY(-50%);
        }
        .time {
          position: absolute;
          right: 10px;
          font-size: 16px;
          color: #9395a3;
          font-weight: 400;
        }
        .select-time {
          position: absolute;
          right: 10px;
          font-size: 16px;
          font-weight: 400;
          color: #9395a3;
          .date-icon {
            font-size: 18px;
            vertical-align: text-bottom;
            margin-left: 5px;
          }
        }
      }
      .report-data {
        .xuetang-echart {
          height: 200px;
        }
      }
      .report-echart {
        position: relative;
        overflow: hidden;
        .echart-wrapper {
          margin-top: 20px;
          height: 260px;
        }
      }
    }
  }
}
</style>