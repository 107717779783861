<template>
  <div>
    <div class="label-health_wrapper">
      <div class="item" @click="handleClickDetail(item)" v-for="(item, index) in labelList" :key="index">
        <h3>{{ item.name }}</h3>
        <div :class="['level-tip', `color${item.color}`]"></div>
      </div>
    </div>
    <van-action-sheet v-model="isActionShow" :title="actionTitle">
      <div class="action-content">
        <div v-html="explain"></div>
        <h4 class="label-title">健康建议</h4>
        <div class="suggest">
            <p v-for="(item,index) in suggest" :key="index">{{index + 1}}、{{item}}</p>
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>


<script>
import baseConfig from "@/base";
import Vue from "vue";
import { Toast, ActionSheet } from "vant";

Vue.use(Toast);
Vue.use(ActionSheet);
export default {
  name: "LabelValue",
  data() {
    return {
      isActionShow: false,
      actionTitle: "",
      explain: "", //解释
      suggest: [], //建议
      labelList: [],
    };
  },
  methods: {
    //初始化 d为报告信息
    init(d) {
      if (!d.AbnormalLabel) {
        this.labele = [];
        return;
      }

      var lable = baseConfig.amExplainConfig.lable;

      var pu = [];

      for (let m = 0; m < d.AbnormalLabel.length; m++) {
        //var json={};
        var qq = d.AbnormalLabel[m];
        for (let i = 0; i < lable.length; i++) {
          var lb = lable[i];
          if (lb.name == qq) {
            //  json=lb;

            pu.push({
              color: lb.color,
              name: lb.name,
              Explain: lb.Explain,
              suggest: lb.suggest,
            });

            break;
          }
        }
      }

      this.labelList = pu; //d.AbnormalLabel
    },
    //action-sheet
    handleClickDetail(detail) {
      this.actionTitle = detail.name;
      this.explain = detail.Explain;
      this.suggest = detail.suggest;
      this.isActionShow = true;
    },
  },
};
</script>

<style lang="less" scoped>
.label-health_wrapper {
  margin-top: 20px;
  display: grid;
  grid-gap: 20px;
  justify-content: space-between;
  grid-template-columns: repeat(2, 1fr);
  .item {
    position: relative;
    background: url("~@/assets/images/day/block-bg.png") no-repeat center / 100%
      100%;
    height: 62px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    > h3 {
      padding: 0 34px;
      text-align: center;
      font-size: 14px;
      color: #579e7b;
      font-weight: 600;
    }
    .level-tip {
      position: absolute;
      width: 20px;
      height: 24px;
      top: 0;
      right: 8px;
      &.color1 {
        background: url("~@/assets/images/day/tag1.png") no-repeat center /
          contain;
      }
      &.color2 {
        background: url("~@/assets/images/day/tag2.png") no-repeat center /
          contain;
      }
      &.color3 {
        background: url("~@/assets/images/day/tag3.png") no-repeat center /
          contain;
      }
    }
  }
}
</style>