var INSIDE = 0;
var LEFT = 1;
var RIGHT = 2;
var BOTTOM = 4;
var TOP = 8;

var xMin;
var yMin;
var xMax;
var yMax;
function setClip(x, y, x1, y1) {
    xMin =x;
    yMin = y;
    xMax = x1;
    yMax = y1;
   
    //console.info("xMin=" + xMin + " yMin=" + yMin + "xMax=" + xMax + "yMax=" + yMax);
}
function getRegionCode(x, y) {
   // console.info("xMin=" + xMin + " yMin=" + yMin + "xMax=" + xMax + "yMax=" + yMax);
    var xcode = x < xMin ? LEFT : x > xMax ? RIGHT : INSIDE;
    var ycode = y < yMin ? BOTTOM : y > yMax ? TOP : INSIDE;
    
    return xcode | ycode;
}


/***
var line={"x1":0,"y1":0,"x2":0,"y2":0}
*/
function clip(line) {

    var p1x = line.x1, p1y = line.y1;
    var p2x = line.x2, p2y = line.y2;
    var qx = 0, qy = 0;
    var vertical = p1x == p2x;
    var slope = vertical ? 0 : (p2y - p1y) / (p2x - p1x);
  
    var code1 = getRegionCode(p1x, p1y);
    var code2 = getRegionCode(p2x, p2y);
   

    while (true) {
       // console.info("INSIDE=" + code1 + " INSIDE=" + code1);
        if (code1 == INSIDE & code2 == INSIDE) {
            break;
        }

        if ((code1 & code2) != INSIDE) {
            return false;
        }

        var codeout = code1 == INSIDE ? code2 : code1;

        if ((codeout & LEFT) != INSIDE) {
            qx = xMin;
            qy = (qx - p1x) * slope + p1y;
        } else if ((codeout & RIGHT) != INSIDE) {
            qx = xMax;
            qy = (qx - p1x) * slope + p1y;
        } else if ((codeout & BOTTOM) != INSIDE) {
            qy = yMin;
            qx = vertical ? p1x : (qy - p1y) / slope + p1x;
        } else if ((codeout & TOP) != INSIDE) {
            qy = yMax;
            qx = vertical ? p1x : (qy - p1y) / slope + p1x;
         
        }

        if (codeout == code1) {
            p1x = qx;
            p1y = qy;
            code1 = getRegionCode(p1x, p1y);
        } else {
            p2x = qx;
            p2y = qy;
            code2 = getRegionCode(p2x, p2y);
        }
    }
    //line.setLine(p1x, p1y, p2x, p2y);
    return { "x1": p1x, "y1": p1y, "x2": p2x, "y2": p2y };
}





export {
    clip,
    getRegionCode,
    setClip,
    
}