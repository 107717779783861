<template>
  <div class="sleep2Detail">
    <van-tabs v-model="activeName">
      <van-tab title="睡眠" name="睡眠">
        <div class="am_panel">
          <div v-if="sleepDetailsRedLength > 0" class="red">
            {{ sleepDetailsRedLength }}项特别注意指标
          </div>
          <div v-if="sleepDetailsYellowLength > 0" class="yellow">
            {{ sleepDetailsYellowLength }}项需要注意指标
          </div>
          <div
            v-for="(m, i) in sleepDetails"
            :key="'sleepDetails' + i"
            :class="sleepDetails.length - 1 > i ? `aammm` : ``"
          >
            <slider :Immunity="m.im" />
          </div>
        </div>
      </van-tab>
      <van-tab title="心率" name="心率">
        <div class="am_panel">
          <div v-if="heartRateDetailsRedLength > 0" class="red">
            {{ heartRateDetailsRedLength }}项特别注意指标
          </div>
          <div v-if="heartRateDetailsYellowLength > 0" class="yellow">
            {{ heartRateDetailsYellowLength }}项需要注意指标
          </div>
          <div
            v-for="(m, i) in heartRateDetails"
            :key="'heartRateDetails' + i"
            :class="heartRateDetails.length - 1 > i ? `aammm` : ``"
          >
            <slider :Immunity="m.im" />
          </div>
        </div>
      </van-tab>
      <van-tab title="呼吸" name="呼吸">
        <div class="am_panel">
          <div v-if="breathingDetailsRedLength > 0" class="red">
            {{ breathingDetailsRedLength }}项特别注意指标
          </div>
          <div v-if="breathingDetailsYellowLength > 0" class="yellow">
            {{ breathingDetailsYellowLength }}项需要注意指标
          </div>
          <div
            v-for="(m, i) in breathingDetails"
            :key="'breathingDetails' + i"
            :class="breathingDetails.length - 1 > i ? m.code : `aamm ${m.code}`"
          >
            <slider :Immunity="m.im" />
          </div>
        </div>
      </van-tab>
      <van-tab v-if="HRV" title="HRV" name="HRV">
        <div class="am_panel">
          <div v-if="hrvDetailsRedLength > 0" class="red">
            {{ hrvDetailsRedLength }}项特别注意指标
          </div>
          <div v-if="hrvDetailsYellowLength > 0" class="yellow">
            {{ hrvDetailsYellowLength }}项需要注意指标
          </div>
          <div
            v-for="(m, i) in hrvDetails"
            :key="'hrvDetails' + i"
            :class="
              hrvDetails.length - 1 > i
                ? `${m.code.replace('/', '_')} aammm`
                : ``
            "
          >
            <slider :Immunity="m.im" />
          </div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import Vue from "vue";
import { Tab, Tabs } from "vant";
Vue.use(Tab);
Vue.use(Tabs);
import dayjs from "dayjs";
import slider from "./slider.vue";
import { whereStr, quickSort, Sc_tst } from "@/utils/index.js";
export default {
  components: {
    slider,
  },
  data() {
    return {
      reportId: "",
      activeName: "睡眠",
      HRV: false,
      sleepDetailsRedLength: 0,
      sleepDetailsYellowLength: 0,
      heartRateDetailsRedLength: 0,
      heartRateDetailsYellowLength: 0,
      breathingDetailsRedLength: 0,
      breathingDetailsYellowLength: 0,
      hrvDetailsRedLength: 0,
      hrvDetailsYellowLength: 0,
      breathingDetails: [],
      sleepDetails: [],
      heartRateDetails: [],
      hrvDetails: [],
    };
  },
  mounted() {
    this.reportId = this.$route.query.id;
  },
  methods: {
    Sc_tst(x) {
      return Sc_tst(x);
    },

    sffcy2(x) {
      var q = Number(x + "").toFixed(2);
      var ch = q.split(".");
      if (ch.length > 1) {
        return `${ch[1]}%`;
      }
      return q;
    },
    slr(x) {
      return `${x}%`;
    },
    dpr(x) {
      return this.slr(x);
    },
    remr(x) {
      return this.slr(x);
    },
    SleepContinuity(x) {
      return `${parseInt(x * 100)}%`;
    },
    sct(x) {
      return `${x}个`;
    },
    st(x) {
      var h = Math.floor(x / (60 * 60));

      var hstr = ``;
      if (h > 0) {
        hstr = `${h}h`;
      }

      var fen = Math.floor((x - h * 60 * 60) / 60);

      var fenStr = `${fen}min`;

      var s = x - h * 60 * 60 - fen * 60;
      var sStr = ``;
      if (s > 0) {
        sStr = `${s}s`;
      }
      return `${hstr}${fenStr}${sStr}`;
    },
    AwakeTimes(x) {
      return `${x}次`;
    },
    ofbdc2(x) {
      return this.AwakeTimes(x);
    },
    mct(x) {
      return this.AwakeTimes(x);
    },
    "TP/BaseTP"(x, d) {
      return d.TP;
    },
    "LF/BaseLF"(x, d) {
      return d.LF;
    },
    "HF/BaseHF"(x, d) {
      return Number(d.HF + "").toFixed(0);
    },

    "hr/lhr"(x, d) {
      return Number(Number(x).toFixed(2));
    },
    AHI(x) {
      return Number(Number(x).toFixed(1));
    },

    init(reportData) {
      this.HRV = reportData.hrv == "1";
      this.get4G7ListReport(reportData);
    },
    //获取7条报告数据
    async get4G7ListReport(reportData) {
      let { result } = await this.$indexedDB.getSingle(
        "myReport",
        this.reportId
      );

      let reportPar = result.reportPar;
      this.$axios
        .get(
          "https://health.ruinetworks.com/report14/health/lite4g/devReportData",
          {
            params: {
              // id: "109339e2-c7b6-4ba7-9019-a6e8f3ebfabd",
              remark: "7",
              id: this.$route.query.id,
            },
            headers: {
              user_token: this.$route.query.token,
            },
          }
        )
        .then((res) => {
          if (res.data.success == "true") {
            var hutudata = res.data.data;
            var d = reportPar;
            if (hutudata.length == 0) {
              hutudata.push(reportData);
            }

            var pu = [];

            for (let di = 0; di < d.length; di++) {
              var x = d[di];

              if (["睡眠", "心率", "呼吸", "hrv"].indexOf(x.type) == -1) {
                continue;
              }

              if (x.title == "心脏总能量") {
              }

              var code = x.code;
              if (code == "SleepContinuity") {
              }
              if (this[code]) {
                x.v2 = this[code](x.v, hutudata[0]);
              }
              if (x.code == "hr") {
                x.v3 = `${x.v}次/min`;
              }
              if (x.code == "lhr") {
                x.v3 = `${x.v}次/min`;
              }
              if (x.code == "avg") {
                x.v3 = `${x.v}次/min`;
              }
              if (x.code == "br") {
                x.v3 = `${x.v}次/min`;
              }
              if (x.code == "lbr") {
                x.v3 = `${x.v}次/min`;
              }

              if (x.code == "bavg") {
                x.v3 = `${x.v}次/min`;
              }

              if (x.code == "AHI") {
                x.v2 = `${x.v2}次/h`;
              }
              if (x.code == "brz") {
                x.v3 = `${x.v}次`;
              }
              if (x.code == "ht") {
                x.v3 = `${x.v}次`;
              }

              var im = {};
              im.title = x.title;
              im.score = x.v2 ? x.v2 : x.v3 ? x.v3 : x.v;

              im.text = x.text;
              im.color = x.color;
              im.notInit = "sleep2DetailInit";
              im.leftIco = true;
              im.showRightIco = reportData.hrv == "1";
              im.canvas = true;
              im.code = code;
              im.data = [];
              var canvasData = {
                writeLeftbiao: false,
                writebiao: true,
                biao: 0,
                biaoColor: "#4391ec",
                footColor: "#000000",
                leftbiao: [],
                text: [],
                leftbiaoattr: [],
              };

              if (x.code == "Sc_tst") {
                //Sc_tst
                canvasData.writeLeftbiao = true;
                canvasData.leftbiaoattr = [];
              }
              if (x.code == "sffcy2") {
                canvasData.writeLeftbiao = true;
                canvasData.leftbiaoattr = [];
              }
              if (x.code == "AHI") {
              }

              if (x.code == "SleepContinuity") {
                canvasData.writeLeftbiao = true;
                canvasData.leftbiaoattr = [];
              }
              if (x.code == "sct") {
                canvasData.writeLeftbiao = true;
                canvasData.leftbiaoattr = [];
              }

              if (x.code == "dpr") {
                canvasData.writeLeftbiao = true;
                canvasData.leftbiaoattr = [];
              }

              if (x.code == "remr") {
                canvasData.writeLeftbiao = true;
                canvasData.leftbiaoattr = [];
              }

              if (x.code == "AwakeTimes") {
                canvasData.writeLeftbiao = true;
                canvasData.leftbiaoattr = [];
              }

              if (x.code == "mct") {
                canvasData.writeLeftbiao = true;
                canvasData.leftbiaoattr = [];
              }
              if (x.code == "TP/BaseTP") {
                canvasData.writeLeftbiao = true;

                canvasData.leftbiaoattr = [];
                for (var i = 0; i < 10; i++) {}
              }

              if (x.code == "LF/BaseLF") {
                canvasData.writeLeftbiao = true;
                canvasData.leftbiaoattr = [];
              }

              if (x.code == "HF/BaseHF") {
                canvasData.writeLeftbiao = true;
                canvasData.leftbiaoattr = [];
              }

              if (x.code == "LFHF") {
                canvasData.writeLeftbiao = true;
                canvasData.leftbiaoattr = [];
              }

              if (x.code == "dc") {
                canvasData.writeLeftbiao = true;
                canvasData.leftbiaoattr = [];
              }

              if (x.code == "hr") {
                canvasData.writeLeftbiao = true;
              }

              if (x.code == "lhr") {
                canvasData.writeLeftbiao = true;
                canvasData.leftbiaoattr = [];
              }

              if (x.code == "slr") {
                canvasData.writeLeftbiao = true;
                canvasData.leftbiaoattr = [];
              }

              if (x.code == "hr/lhr") {
                x.v = Number(Number(x.v).toFixed(2));
              }

              im.canvasData = canvasData;

              x.m.forEach((xm, xmi) => {
                var wher = xm.where.replace(")", "").replace("[", "");
                var mks = wher.split(",");
                var combet = wher.split(",");
                var imdata = {};
                if (x.code == "Sc_tst") {
                  //Sc_tst

                  if (mks[0] > 0) {
                    mks[0] = mks[0] / 60;
                  }
                  if (mks[1] > 0) {
                    mks[1] = mks[1] / 60;
                  }
                }

                if (x.code == "st") {
                  if (mks[0] > 0) {
                    mks[0] = mks[0] / 60;
                  }
                  if (mks[1] > 0) {
                    mks[1] = mks[1] / 60;
                  }
                }

                if (x.code == "TP/BaseTP") {
                  if (mks[0] > 0) {
                    mks[0] = mks[0] * hutudata[0].BaseTP;
                    mks[0] = mks[0].toFixed(0);
                  }
                  if (mks[1] > 0) {
                    mks[1] = mks[1] * hutudata[0].BaseTP;
                    mks[1] = mks[1].toFixed(0);
                  }
                }

                if (x.code == "HF/BaseHF") {
                  if (mks[0] > 0) {
                    mks[0] = mks[0] * hutudata[0].BaseHF;
                    mks[0] = mks[0].toFixed(0);
                  }
                  if (mks[1] > 0) {
                    mks[1] = mks[1] * hutudata[0].BaseHF;
                    mks[1] = mks[1].toFixed(0);
                  }
                }
                if (x.code == "LF/BaseLF") {
                  if (mks[0] > 0) {
                    mks[0] = mks[0] * hutudata[0].BaseLF;
                    mks[0] = mks[0].toFixed(0);
                  }
                  if (mks[1] > 0) {
                    mks[1] = mks[1] * hutudata[0].BaseLF;
                    mks[1] = mks[1].toFixed(0);
                  }
                }

                if (xmi === 0) {
                  imdata.num = mks[0];

                  imdata.num2 = mks[1];

                  canvasData.leftbiao.push(imdata.num);
                  canvasData.leftbiao.push(imdata.num2);
                } else {
                  imdata.num = "";
                  imdata.num2 = mks[1];
                  canvasData.leftbiao.push(imdata.num2);
                }

                if ("∞" == combet[1]) {
                  var fis = x.m.filter((filt) => filt.level == "3")[0];
                  var ch2combet = fis.where
                    .replace(")", "")
                    .replace("[", "")
                    .split(",");
                  if (x.code == "remr") {
                  }
                  combet[1] =
                    Number(combet[0]) +
                    Number(ch2combet[1]) -
                    Number(ch2combet[0]);
                }

                if (x.code == "hr") {
                }
                var left =
                  ((x.v - combet[0]) / (combet[1] - combet[0])) * 100 + "%";

                if (x.code == "TP/BaseTP") {
                  left =
                    ((x.v2 - combet[0]) / (combet[1] - combet[0])) * 100 + "%";
                }
                if (x.code == "Sc_tst") {
                  left =
                    ((x.v - combet[0]) / (combet[1] - combet[0])) * 100 + "%";
                }
                if (x.code == "st") {
                  left =
                    ((x.v - combet[0]) / (combet[1] - combet[0])) * 100 + "%";
                }
                if (x.code == "remr") {
                  left =
                    ((x.v - combet[0]) / (combet[1] - combet[0])) * 100 + "%";
                }
                if (Number(left.replace("%", "")) > 100) {
                  left = "100%";
                }
                if (code == "SleepContinuity") {
                  if (x.level == "3") {
                    left = ((x.v - combet[0]) / (1 - combet[0])) * 100 + "%";
                  } else {
                    left = (x.v - Number(combet[0])) * 100 + "%";
                  }
                }

                imdata.left = left;
                imdata.color = xm.color;
                imdata.text = xm.text;
                imdata.level = xm.level;
                im.data.push(imdata);

                hutudata.forEach((dic) => {
                  if (x.code == "TP/BaseTP") {
                    //dic.BaseTP = hutudata[0].BaseTP
                  }
                  if (x.code == "HF/BaseHF") {
                    // dic.BaseHF = hutudata[0].BaseHF
                  }

                  if (x.code == "LF/BaseLF") {
                    // dic.BaseLF = hutudata[0].BaseLF
                  }

                  let date2 = dayjs(dic.FinishTime).format("MM-DD");

                  whereStr(code, xm.where, dic, (dicvalue) => {
                    var numText = "";
                    var base = "";
                    if (this[code]) {
                      numText = this[code](dicvalue, dic);
                    }

                    if (x.code == "TP/BaseTP") {
                      dicvalue = numText;
                      base = dic.BaseTP;
                    }
                    if (x.code == "HF/BaseHF") {
                      dicvalue = numText;
                      base = dic.BaseHF;
                    }

                    if (x.code == "LF/BaseLF") {
                      dicvalue = numText;
                      base = dic.BaseLF;
                    }

                    if (x.code == "sdnn") {
                      base = dic.BaseSDNN;
                    }

                    if (x.code == "LFHF") {
                      base = dic.BaseLFHF;
                    }

                    if (x.code == "dc") {
                      base = dic.BaseDC;
                    }

                    if (dic.reportCount <= 7) {
                      base = -1;
                    }

                    let date = dayjs(dic.FinishTime).format("MM-DD");
                    canvasData.text.push({
                      FinishTime: dic.FinishTime,
                      color: xm.color,
                      date: date,
                      num: dicvalue,
                      numText: numText,
                      base: Number(base + ""),
                      gb: dic.ReportId.substr(0, 2).toLocaleLowerCase(),
                    });                    
                  });
                });
              });
              im.canvasData.text = quickSort(im.canvasData.text, "FinishTime");
              x.im = im;
              im.canvasData.reportCount = hutudata[0].reportCount;

              pu.push(x);
            }

            var fnim = (im) => {
              im.canvasData.leftbiaoattr = [];
              im.canvasData.leftbiao = [];
              var max = -1;
              im.canvasData.text.forEach((t) => {
                var numNumber = Number(t.num + "");
                if (numNumber > max) {
                  max = numNumber;
                }
              });

              var arg = Math.ceil(max / 7);

              for (var i = 0; i < 8; i++) {
                var v = i * arg;
                im.canvasData.leftbiaoattr.push({
                  text: `${v}`,
                  value: v,
                });
              }
              return im;
            };

            var sleepDetails = pu.filter((u) => u.type == "睡眠");

            sleepDetails.forEach((hrv) => {
              var im = hrv.im;

              im = fnim(im);
              if (im.code == "st") {
                im.canvasData.leftbiaoattr.forEach((lb) => {
                  var lbvalue = Math.ceil(lb.value / 60);
                  lb.value = lbvalue * 60;
                  lb.text = lbvalue + "";
                });
              }
            });

            this.sleepDetails = sleepDetails;

            var heartRateDetails = pu.filter((u) => u.type == "心率");

            heartRateDetails.forEach((hrv) => {
              var im = hrv.im;
              im = fnim(im);
            });

            this.heartRateDetails = heartRateDetails;

            var breathingDetails = pu.filter((u) => u.type == "呼吸");

            breathingDetails.forEach((hrv) => {
              var im = hrv.im;
              im = fnim(im);
            });

            this.breathingDetails = breathingDetails;

            var hrvDetails = pu.filter((u) => u.type == "hrv");

            hrvDetails.forEach((hrv) => {
              var im = hrv.im;

              if (im.code == "TP/BaseTP") {
                im = fnim(im);
              } else if (im.code == "HF/BaseHF") {
                im = fnim(im);
              } else if (im.code == "LF/BaseLF") {
                im = fnim(im);
              } else {
                im = fnim(im);
              }

              if (im.canvasData.reportCount > 7) {
                if (im.code == "sdnn") {
                  hrv.im.jxz = Math.round(
                    im.canvasData.text[im.canvasData.text.length - 1].base
                  ); //90
                }

                if (im.code == "LFHF") {
                  hrv.im.jxz =
                    im.canvasData.text[
                      im.canvasData.text.length - 1
                    ].base.toFixed(2); //1
                }
                if (im.code == "dc") {
                  hrv.im.jxz =
                    im.canvasData.text[
                      im.canvasData.text.length - 1
                    ].base.toFixed(2); //8
                }

                if (im.code == "TP/BaseTP") {
                  hrv.im.jxz = Math.round(
                    im.canvasData.text[im.canvasData.text.length - 1].base
                  ); //4000
                }
                if (im.code == "HF/BaseHF") {
                  hrv.im.jxz = Math.round(
                    im.canvasData.text[im.canvasData.text.length - 1].base
                  ); //900
                }
                if (im.code == "LF/BaseLF") {
                  hrv.im.jxz = Math.round(
                    im.canvasData.text[im.canvasData.text.length - 1].base
                  ); //900
                }
              } else {
                hrv.im.jxz = "--";
              }

              //jxz
            });

            this.hrvDetails = hrvDetails;

            this.sleepDetailsRedLength = this.sleepDetails.filter(
              (u) => u.level == "1"
            ).length;
            this.sleepDetailsYellowLength = this.sleepDetails.filter(
              (u) => u.level == "2"
            ).length;
            this.heartRateDetailsRedLength = this.heartRateDetails.filter(
              (u) => u.level == "1"
            ).length;
            this.heartRateDetailsYellowLength = this.heartRateDetails.filter(
              (u) => u.level == "2"
            ).length;
            this.breathingDetailsRedLength = this.breathingDetails.filter(
              (u) => u.level == "1"
            ).length;
            this.breathingDetailsYellowLength = this.breathingDetails.filter(
              (u) => u.level == "2"
            ).length;

            
            var sm = reportData.ReportId;
            var smStr = sm[0] + sm[1];

            if (smStr == "sm") {
              var puhrv = this.hrvDetails.filter((hd) => {
                return ["TP/BaseTP", "sdnn", "dc"].indexOf(hd.code) !== -1;
              });

              this.hrvDetailsRedLength = puhrv.filter(
                (u) => u.level == "1"
              ).length;
              this.hrvDetailsYellowLength = puhrv.filter(
                (u) => u.level == "2"
              ).length;
            } else {
              this.hrvDetailsRedLength = this.hrvDetails.filter(
                (u) => u.level == "1"
              ).length;
              this.hrvDetailsYellowLength = this.hrvDetails.filter(
                (u) => u.level == "2"
              ).length;
            }
          } else {
            Toast(res.data.data.msg);
          }
        });
    },
  },
};
</script>

<style lang="less">
.sleep2Detail {
  .wi150 {
    width: 110px;
  }
  .fontsize26 {
    font-size: 16px;
  }
  .width24 {
    display: flex;
    justify-content: flex-end;
  }

  .van-icon {
    color: #c7c7c7;
  }
  #click_show_li_show {
    display: none;
    padding-top: 10px;
  }

  .aammm {
    border-bottom: 1px solid #efefef;
  }
  .red {
    color: red;
  }
  .yellow {
    color: rgb(248, 178, 96);
  }

  .am_panel {
    font-size: 12px;
    background: #fff;
    padding: 15px;
    border-radius: 0 0 12px 12px;
  }

  .brz,
  .ht {
    ._flex {
      display: none;
    }
  }
}
</style>
