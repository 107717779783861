<template>
  <div class="main-container">
    <div class="banner-wrapper">
      <h2 class="title">健康日报</h2>
      <p class="report-time">{{ reportTime }}</p>
      <div class="user-info">
        <div class="avatar-img">
          <img :src="require('@/assets/images/logo.png')" alt="" />
        </div>
        <p class="user-name">{{ userName }}</p>
      </div>
    </div>
    <div class="report-wrapper">
      <div class="health-guide">-本次报告不作为疾病的专业临床诊断依据-</div>
      <!-- 睡眠情况 -->
      <div class="report-detail">
        <div class="title relative">
          <img
            :src="require('@/assets/images/sleep.png')"
            class="title-icon"
          />睡眠情况
          <h4 class="health-score">
            健康值：{{ reportDetail.reportInfo.totalScore }}分
          </h4>
        </div>
        <div style="position: relative">
          <div class="chart-wrapper" id="sleepEcharts"></div>
          <p class="time-range">
            <span
              >入睡：{{
                reportDetail.reportInfo.bedTime | formatSleepTime
              }}</span
            >
            <span
              >清醒：{{
                reportDetail.reportInfo.wakeTime | formatSleepTime
              }}</span
            >
          </p>
          <div class="chart-legend">
            <p class="legend-item">
              <span class="circle" style="background: #ffdf24"></span>
              <span>离枕</span>
            </p>
            <p class="legend-item">
              <span class="circle" style="background: #d8edff"></span>
              <span>清醒</span>
            </p>
            <p class="legend-item">
              <span class="circle" style="background: #a9ddfd"></span>
              <span>浅睡</span>
            </p>
            <p class="legend-item">
              <span class="circle" style="background: #4c6ce5"></span>
              <span>深睡</span>
            </p>
            <p class="legend-item">
              <span class="circle" style="background: #e6affd"></span>
              <span>REM</span>
            </p>
          </div>
        </div>
        <div class="item">
          <h3 class="sleep-time">
            <img
              :src="require('@/assets/images/time.png')"
              class="img-icon"
            />睡眠时长
            <span>{{ reportDetail.sleepInfo.totalTime }}</span>
          </h3>
        </div>
        <!-- 睡眠数据 -->
        <div class="item">
          <h3>
            <img
              :src="require('@/assets/images/shuimian.png')"
              class="img-icon"
            />睡眠数据
          </h3>
          <p>
            <span @click="handleClickDetail('实际睡眠时长')"
              ><van-icon
                size="12"
                class="mr4"
                color="#0c9f8b"
                name="info-o"
              />实际睡眠时长：</span
            >
            <span>{{ reportDetail.sleepInfo.totalTime }}</span>
            <i
              class="arrow"
              v-html="
                formatJudgment(reportDetail.sleepInfo.totalTime, '实际睡眠时长')
              "
            ></i>
          </p>
          <p>
            <span @click="handleClickDetail('深度睡眠')"
              ><van-icon
                size="12"
                class="mr4"
                color="#0c9f8b"
                name="info-o"
              />深度睡眠：</span
            >
            <span
              >{{ reportDetail.sleepInfo.deep }}({{
                reportDetail.sleepInfo.deepRatio
              }}%)</span
            >
            <i
              class="arrow"
              v-html="
                formatJudgment(reportDetail.sleepInfo.deepRatio, '深度睡眠')
              "
            ></i>
          </p>
          <p>
            <span @click="handleClickDetail('浅度睡眠')"
              ><van-icon
                size="12"
                class="mr4"
                color="#0c9f8b"
                name="info-o"
              />浅度睡眠：</span
            >
            <span
              >{{ reportDetail.sleepInfo.shallow }}({{
                reportDetail.sleepInfo.shallowRatio
              }}%)</span
            >
            <i
              class="arrow"
              v-html="
                formatJudgment(reportDetail.sleepInfo.shallowRatio, '浅度睡眠')
              "
            ></i>
          </p>
          <p>
            <span @click="handleClickDetail('REM睡眠')"
              ><van-icon size="12" class="mr4" color="#0c9f8b" name="info-o" />
              REM睡眠：</span
            >
            <span
              >{{ reportDetail.sleepInfo.rem }}({{
                reportDetail.sleepInfo.remRatio
              }}%)</span
            >
            <i
              class="arrow"
              v-html="
                formatJudgment(reportDetail.sleepInfo.remRatio, 'REM睡眠')
              "
            ></i>
          </p>
          <p>
            <span @click="handleClickDetail('体动次数')"
              ><van-icon
                size="12"
                class="mr4"
                color="#0c9f8b"
                name="info-o"
              />体动次数：</span
            >
            <span>{{ reportDetail.sleepInfo.moveCounts }}次</span>
            <i
              class="arrow"
              v-html="
                formatJudgment(reportDetail.sleepInfo.moveCounts, '体动次数')
              "
            ></i>
          </p>
        </div>
        <!-- 免疫力情况 -->
        <div class="item">
          <h3>
            <img
              :src="require('@/assets/images/mianyi.png')"
              class="img-icon"
            />免疫力情况
          </h3>
          <p>
            <span>感染风险：</span>
            <span>{{ mianyiDetail.ganran }}</span>
            <i
              class="arrow"
              v-html="
                formatJudgment(mianyiDetail.ganran, '感染风险')
              "
            ></i>
          </p>
          <p>
            <span>免疫力指数：</span>
            <span>{{ mianyiDetail.mianyili }}</span
            >
            <i
              class="arrow"
              v-html="
                formatJudgment(mianyiDetail.mianyili, '免疫力指数')
              "
            ></i>
          </p>
        </div>
      </div>
      <!-- 呼吸情况 -->
      <div class="report-detail">
        <div class="title">
          <img
            :src="require('@/assets/images/fei.png')"
            class="title-icon"
          />呼吸情况
        </div>
        <div class="chart-wrapper" id="breathChart"></div>
        <div class="item">
          <h3>
            <img
              :src="require('@/assets/images/xinzang.png')"
              class="img-icon"
            />呼吸数据
          </h3>
          <p>
            <span @click="handleClickDetail('当次基准呼吸')"
              ><van-icon
                size="12"
                class="mr4"
                color="#0c9f8b"
                name="info-o"
              />本次基准呼吸：</span
            >
            <span>{{ reportDetail.breathInfo.benchmark }}次/分钟</span>
            <i
              class="arrow"
              v-html="
                formatJudgment(
                  reportDetail.breathInfo.benchmark,
                  '当次基准呼吸'
                )
              "
            ></i>
          </p>
          <p>
            <span @click="handleClickDetail('呼吸暂停次数')"
              ><van-icon
                size="12"
                class="mr4"
                color="#0c9f8b"
                name="info-o"
              />呼吸暂停次数：</span
            >
            <span>{{ reportDetail.reportInfo.breathPauseCount }}次</span>
            <i
              class="arrow"
              v-html="
                formatJudgment(
                  reportDetail.reportInfo.breathPauseCount,
                  '呼吸暂停次数'
                )
              "
            ></i>
          </p>
          <p>
            <span @click="handleClickDetail('AHI指数')"
              ><van-icon
                size="12"
                class="mr4"
                color="#0c9f8b"
                name="info-o"
              />AHI指数：</span
            >
            <span>{{ reportDetail.breathInfo.AHI }}次/分钟</span>
            <i
              class="arrow"
              v-html="formatJudgment(reportDetail.breathInfo.AHI, 'AHI指数')"
            ></i>
          </p>
        </div>
      </div>
      <!-- 心率情况 -->
      <div class="report-detail">
        <div class="title">
          <img
            :src="require('@/assets/images/report.png')"
            class="title-icon"
          />心率情况
        </div>
        <div class="chart-wrapper" id="heartChart"></div>
        <div class="item">
          <h3>
            <img
              :src="require('@/assets/images/xinzang.png')"
              class="img-icon"
            />心率数据
          </h3>
          <p>
            <span @click="handleClickDetail('当次基准心率')"
              ><van-icon
                size="12"
                class="mr4"
                color="#0c9f8b"
                name="info-o"
              />本次基准心率：</span
            >
            <span>{{ reportDetail.heartBeatInfo.benchmark }}次/分钟</span>
            <i
              class="arrow"
              v-html="
                formatJudgment(
                  reportDetail.heartBeatInfo.benchmark,
                  '当次基准心率'
                )
              "
            ></i>
          </p>
          <p>
            <span @click="handleClickDetail('当次平均心率')"
              ><van-icon
                size="12"
                class="mr4"
                color="#0c9f8b"
                name="info-o"
              />本次平均心率：</span
            >
            <span>{{ reportDetail.heartBeatInfo.average }}次/分钟</span>
            <i
              class="arrow"
              v-html="
                formatJudgment(
                  reportDetail.heartBeatInfo.average,
                  '当次平均心率'
                )
              "
            ></i>
          </p>
        </div>
      </div>
      <!-- 报告详情 -->
      <div class="report-detail">
        <div class="title">
          <img
            :src="require('@/assets/images/report.png')"
            class="title-icon"
          />报告详情
        </div>
        <div class="item">
          <h3>
            <img
              :src="require('@/assets/images/huxi.png')"
              class="img-icon"
            />呼吸监测
          </h3>
          <p>
            <span>长期基准呼吸率：</span>
            <span>{{ reportDetail.breathInfo.longterm }}次/分钟</span>
          </p>
          <p>
            <span>本次基准呼吸率：</span>
            <span>{{ reportDetail.breathInfo.benchmark }}次/分钟</span>
          </p>
          <p>
            <span>本次最大呼吸率：</span>
            <span>{{ reportDetail.breathInfo.breathMax }}</span>
          </p>
          <p>
            <span>本次最小呼吸率：</span>
            <span>{{ reportDetail.breathInfo.breathMin }}</span>
          </p>
          <p>
            <span>平均呼吸：</span>
            <span>{{ reportDetail.breathInfo.average }}次/分钟</span>
          </p>
          <p>
            <span>单次呼吸过速时长：</span>
            <span>{{ reportDetail.breathInfo.higherCounts }}秒</span>
          </p>
          <p>
            <span>单次呼吸过缓时长：</span>
            <span>{{ reportDetail.breathInfo.lowerCounts }}秒</span>
          </p>
          <p>
            <span>低于10秒呼吸次数：</span>
            <span>{{ reportDetail.breathInfo.pause10Counts }}次</span>
          </p>
          <p>
            <span>高于30秒呼吸次数：</span>
            <span>{{ reportDetail.breathInfo.pause30Counts }}次</span>
          </p>
          <p>
            <span>AHI指数：</span>
            <span>{{ reportDetail.breathInfo.AHI }}次/分钟</span>
          </p>
        </div>
        <div class="item">
          <h3>
            <img
              :src="require('@/assets/images/xinlv.png')"
              class="img-icon"
            />心率监测
          </h3>
          <p>
            <span>长期基准心率：</span>
            <span>{{ reportDetail.heartBeatInfo.dayLongterm }}次/分钟</span>
          </p>
          <p>
            <span>本次基准心率：</span>
            <span>{{ reportDetail.heartBeatInfo.benchmark }}次/分钟</span>
          </p>
          <p>
            <span>本次最大心率：</span>
            <span>{{ reportDetail.heartBeatInfo.heartbeatMax }}</span>
          </p>
          <p>
            <span>本次最小心率：</span>
            <span>{{ reportDetail.heartBeatInfo.heartbeatMin }}</span>
          </p>
          <p>
            <span>平均心率：</span>
            <span>{{ reportDetail.heartBeatInfo.average }}次/分钟</span>
          </p>
          <p>
            <span>心率超过125：</span>
            <span>{{ reportDetail.heartBeatInfo.exceedBenchmark125 }}次</span>
          </p>
          <p>
            <span>心率超过115：</span>
            <span>{{ reportDetail.heartBeatInfo.exceedBenchmark115 }}次</span>
          </p>
          <p>
            <span>心率超过83：</span>
            <span>{{ reportDetail.heartBeatInfo.exceedBenchmark83 }}次</span>
          </p>
          <p>
            <span>心率不超过43：</span>
            <span>{{ reportDetail.heartBeatInfo.exceedBenchmark43 }}次</span>
          </p>
        </div>
        <div class="item">
          <h3>
            <img
              :src="require('@/assets/images/shuimian.png')"
              class="img-icon"
            />睡眠监测
          </h3>
          <p>
            <span>总睡眠时长：</span>
            <span>{{ reportDetail.sleepInfo.totalTime }}</span>
          </p>
          <p>
            <span>睡眠周期：</span>
            <span>{{ reportDetail.sleepInfo.sleepCounts }}</span>
          </p>
          <p>
            <span>入睡时长：</span>
            <span>{{ reportDetail.sleepInfo.sleepTime }}</span>
          </p>
          <p>
            <span>深度睡眠时长及比例：</span>
            <span
              >{{ reportDetail.sleepInfo.deep }}({{
                reportDetail.sleepInfo.deepRatio
              }}%)</span
            >
          </p>
          <p>
            <span>浅度睡眠时长及比例：</span>
            <span
              >{{ reportDetail.sleepInfo.shallow }}({{
                reportDetail.sleepInfo.shallowRatio
              }}%)</span
            >
          </p>
          <p>
            <span>REM睡眠时长及比例：</span>
            <span
              >{{ reportDetail.sleepInfo.rem }}({{
                reportDetail.sleepInfo.remRatio
              }}%)</span
            >
          </p>
          <p>
            <span>清醒时长及比例：</span>
            <span
              >{{ reportDetail.sleepInfo.sober }}({{
                reportDetail.sleepInfo.soberRatio
              }}%)</span
            >
          </p>
          <p>
            <span>离床时长及比例：</span>
            <span
              >{{ reportDetail.sleepInfo.offbed }}({{
                reportDetail.sleepInfo.offbedRatio
              }}%)</span
            >
          </p>
          <p>
            <span>体动次数：</span>
            <span>{{ reportDetail.sleepInfo.moveCounts }}次</span>
          </p>
          <p>
            <span>平均体动时长：</span>
            <span>{{ reportDetail.sleepInfo.movingAverageLength }}</span>
          </p>
          <p>
            <span>离床次数：</span>
            <span>{{ reportDetail.sleepInfo.offbedCounts }}次</span>
          </p>
        </div>
        <!-- hrv -->
        <div class="item hrv">
          <h3>
            <img
              :src="require('@/assets/images/xinlv.png')"
              class="img-icon"
            />HRV监测
          </h3>
          <p>
            <span>本次猝死风险指数：</span>
            <span>{{ reportDetail.HRV.dcValue | formatNumber(2) }}</span>
          </p>
          <p>
            <span>猝死风险指数基准值：</span>
            <span class="base-value">{{
              reportDetail.HRV.baseDC | formatNumber(2)
            }}</span>
          </p>
          <p>
            <span>本次心脏总能量：</span>
            <span>{{ reportDetail.HRV.heartIndex | formatNumber(0) }}</span>
          </p>
          <p>
            <span>基准心脏总能量：</span>
            <span class="base-value">{{
              reportDetail.HRV.baseTP | formatNumber(0)
            }}</span>
          </p>
          <p>
            <span>本次迷走神经张力指数：</span>
            <span>{{ reportDetail.HRV.HF | formatNumber(0) }}</span>
          </p>
          <p>
            <span>基准迷走神经张力指数：</span>
            <span class="base-value">{{
              reportDetail.HRV.baseHF | formatNumber(0)
            }}</span>
          </p>
          <p>
            <span>本次交感神经张力指数：</span>
            <span>{{ reportDetail.HRV.LF | formatNumber(0) }}</span>
          </p>
          <p>
            <span>基准交感神经张力指数：</span>
            <span class="base-value">{{
              reportDetail.HRV.baseLF | formatNumber(0)
            }}</span>
          </p>
          <p>
            <span>本次自主神经平衡指数：</span>
            <span>{{ reportDetail.HRV.LFHF | formatNumber(2) }}</span>
          </p>
          <p>
            <span>基准自主神经平衡指数：</span>
            <span class="base-value">{{
              reportDetail.HRV.baseLFHF | formatNumber(2)
            }}</span>
          </p>
          <p>
            <span>体温及血管舒缩指数：</span>
            <span>{{ reportDetail.HRV.temperature | formatNumber(0) }}</span>
          </p>
          <p>
            <span>基准体温/血管舒缩指数：</span>
            <span class="base-value">{{
              reportDetail.HRV.baseVLF | formatNumber(0)
            }}</span>
          </p>
        </div>
      </div>
    </div>
    <van-action-sheet v-model="isActionShow" :title="actionTitle">
      <div class="action-content" v-html="actionText"></div>
    </van-action-sheet>
  </div>
</template>

<script>
import dayjs from "dayjs";
import Highcharts from "highcharts/highstock";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartXrange from "highcharts/modules/xrange";
import baseConfig from "@/base";
HighchartXrange(Highcharts);
HighchartsMore(Highcharts);
import Vue from "vue";
import { Icon, Toast, ActionSheet } from "vant";
Vue.use(Icon);
Vue.use(Toast);
Vue.use(ActionSheet);
export default {
  name: "ReportDetail",
  data() {
    return {
      isActionShow: false,
      actionTitle: "",
      actionText: "",
      reportTime: "",
      userName: "",
      userAvatar: "",
      reportDetail: {
        breathInfo: {},
        heartBeatInfo: {},
        sleepInfo: {},
        reportInfo: {},
        HRV: {},
      },
      mianyiDetail: {
        ganran: 0,
        mianyili: 0
      },
      articleList: []
    };
  },
  filters: {
    formatSleepTime: function (stamp) {
      if (stamp) {
        let _stamp = stamp.replace(new RegExp(/\./gm), "/");
        return dayjs(_stamp).format("HH:mm");
      }
    },
    formatNumber: function (num, fix) {
      if (typeof num === "number" || typeof num === "string") {
        return Number(num).toFixed(fix);
      }
    },
  },
  computed: {
    formatJudgment: function () {
      return function (value, title) {
        if (value && title === "实际睡眠时长") {
          let sleepTime = value;
          if (value.indexOf("小时") == -1) {
            sleepTime = "0小时" + value;
          }
          if (value.indexOf("分") == -1) {
            sleepTime = value + "0分";
          }
          sleepTime = sleepTime.replace("小时", "|").replace("分", "|");
          const sleepArray = sleepTime.split("|");
          const sleepTimeValue =
            Number(sleepArray[0] * 60) + Number(sleepArray[1]);

          if (sleepTimeValue >= 0 * 60 && sleepTimeValue < 5 * 60) {
            return '<span style="color: #e60213">↓↓</span>';
          } else if (sleepTimeValue >= 5 * 60 && sleepTimeValue < 7 * 60) {
            return '<span style="color: #ff9e02">↓</span>';
          } else if (sleepTimeValue >= 7 * 60 && sleepTimeValue < 8 * 60) {
            return "";
          } else if (sleepTimeValue >= 8 * 60 && sleepTimeValue < 10 * 60) {
            return '<span style="color: #ff9d01">↑</span>';
          } else if (sleepTimeValue >= 10 * 60) {
            return '<span style="color: #e60213">↑↑</span>';
          }
        } else if (value && title === "深度睡眠") {
          if (value > 0 && value < 10) {
            return '<span style="color: #e60213">↓↓</span>';
          } else if (value >= 10 && value < 15) {
            return '<span style="color: #ff9e02">↓</span>';
          } else if (value >= 15 && value < 26) {
            return "";
          } else if (value >= 26 && value < 32) {
            return '<span style="color: #ff9d01">↑</span>';
          } else {
            return '<span style="color: #e60213">↑↑</span>';
          }
        } else if (value && title === "浅度睡眠") {
          if (value > 60) {
            return '<span style="color: #ff9d01">↑</span>';
          } else if (value < 50) {
            return '<span style="color: #ff9e02">↓</span>';
          }
        } else if (value && title === "REM睡眠") {
          if (value > 0 && value < 10) {
            return '<span style="color: #e60213">↓↓</span>';
          } else if (value >= 10 && value < 15) {
            return '<span style="color: #ff9e02">↓</span>';
          } else if (value >= 15 && value < 26) {
            return "";
          } else if (value >= 26 && value < 32) {
            return '<span style="color: #ff9d01">↑</span>';
          } else {
            return '<span style="color: #e60213">↑↑</span>';
          }
        } else if (value && title === "体动次数") {
          if (value <= 200) {
            return "";
          } else if (value >= 200 && value < 400) {
            return '<span style="color: #ff9d01">↑</span>';
          } else {
            return '<span style="color: #e60213">↑↑</span>';
          }
        } else if (value && title === "当次基准呼吸") {
          if (value > 0 && value < 9) {
            return '<span style="color: #e60213">↓↓</span>';
          } else if (value >= 9 && value < 12) {
            return '<span style="color: #ff9e02">↓</span>';
          } else if (value >= 12 && value < 18) {
            return "";
          } else if (value >= 18 && value < 21) {
            return '<span style="color: #ff9d01">↑</span>';
          } else {
            return '<span style="color: #e60213">↑↑</span>';
          }
        } else if (value && title === "呼吸暂停次数") {
          if (value > 0 && value < 15) {
            return "";
          } else if (value >= 15 && value < 30) {
            return '<span style="color: #ff9d01">↑</span>';
          } else {
            return '<span style="color: #e60213">↑↑</span>';
          }
        } else if (value && title === "AHI指数") {
          if (value > 0 && value < 5) {
            return "";
          } else if (value >= 5 && value < 15) {
            return '<span style="color: #02adff">轻度</span>';
          } else if (value >= 15 && value < 30) {
            return '<span style="color: #ff9e02">中度</span>';
          } else {
            return '<span style="color:#e60213">重度</span>';
          }
        } else if(value && title === "感染风险") {
          if (value > 0 && value < 40) {
            return "";
          } else if (value >= 40 && value < 70) {
            return '<span style="color: #ff9d01">↑</span>';
          } else {
            return '<span style="color: #e60213">↑↑</span>';
          }
        } else if(value && title === "免疫力指数") {
          if (value >= 70) {
            return "";
          } else if (value >= 50 && value < 70) {
            return '<span style="color: #ff9e02">↓</span>';
          } else {
            return '<span style="color: #e60213">↓↓</span>';
          }
        }  else if (
          value &&
          (title === "当次基准心率" || title === "当次平均心率")
        ) {
          if (value > 0 && value < 45) {
            return '<span style="color: #e60213">↓↓</span>';
          } else if (value >= 45 && value < 50) {
            return '<span style="color: #ff9e02">↓</span>';
          } else if (value >= 50 && value < 65) {
            return "";
          } else if (value >= 65 && value < 73) {
            return '<span style="color: #ff9d01">↑</span>';
          } else {
            return '<span style="color: #e60213">↑↑</span>';
          }
        }
      };
    },
  },
  methods: {
    //心率折线图
    initHeartLine(xData, data) {
      const that = this;
      let heartChart = this.$echarts.init(
        document.getElementById("heartChart")
      );
      let option = {
        color: ["#2CCBD6"],
        tooltip: {
          show: true,
          trigger: "axis",
          formatter: function (params) {
            const paramsValue = params[0];
            var str = `${paramsValue.axisValue}<br/>心率：${paramsValue.data}`;
            return str;
          },
        },
        grid: {
          top: 20,
          left: 5,
          right: 10,
          bottom: 20,
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: xData,
          axisLabel: {
            show: true,
            color: "#2CCBD6",
            formatter: function (param) {
              return dayjs(param).format("HH:mm");
            },
          },
          axisLine: {
            lineStyle: {
              color: "rgba(44, 203, 214, .4)",
            },
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: "value",
          axisTick: {
            show: false,
          },
          axisLabel: {
            color: "2CCBD6",
          },
          axisLine: {
            lineStyle: {
              color: "rgba(44, 203, 214, .4)",
            },
          },
          splitLine: {
            lineStyle: {
              type: "dashed",
              color: "#E9E9EC",
            },
          },
        },
        series: [
          {
            name: "心率",
            data: data,
            symbolSize: 0,
            smooth: true,
            lineStyle: {
              width: 1,
            },
            markPoint: {
              symbolSize: 30,
              label: {
                normal: {
                  textStyle: {
                    color: "#fff",
                  },
                },
              },
              data: [
                {
                  type: "max",
                  name: "最大值",
                },
              ],
            },
            areaStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(44, 203, 214, 0.8)",
                },
                {
                  offset: 1,
                  color: "rgba(44, 203, 214, 0)",
                },
              ]),
            },
            type: "line",
          },
        ],
      };
      heartChart.setOption(option);
    },
    //呼吸折线图
    initBreathLine(xData, data) {
      const that = this;
      let breathChart = this.$echarts.init(
        document.getElementById("breathChart")
      );
      let option = {
        color: ["#ED9807"],
        grid: {
          top: 20,
          left: 5,
          right: 10,
          bottom: 20,
          containLabel: true,
        },
        tooltip: {
          show: true,
          trigger: "axis",
          formatter: function (params) {
            const paramsValue = params[0];
            var str = `${paramsValue.axisValue}<br/>呼吸：${paramsValue.data}`;
            return str;
          },
        },
        xAxis: {
          type: "category",
          data: xData,
          axisLabel: {
            show: true,
            color: "#ED9807",
            formatter: function (param) {
              return dayjs(param).format("HH:mm");
            },
          },
          axisLine: {
            lineStyle: {
              color: "rgba(237, 152, 7, .4)",
            },
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: "value",
          axisTick: {
            show: false,
          },
          axisLabel: {
            color: "#ED9807",
          },
          axisLine: {
            lineStyle: {
              color: "rgba(237, 152, 7, .4)",
            },
          },
          splitLine: {
            lineStyle: {
              type: "dashed",
              color: "#E9E9EC",
            },
          },
        },
        series: [
          {
            name: "呼吸率",
            symbolSize: 0,
            smooth: true,
            lineStyle: {
              width: 1,
            },
            markPoint: {
              symbolSize: 30,
              label: {
                normal: {
                  textStyle: {
                    color: "#fff",
                  },
                },
              },
              data: [
                {
                  type: "max",
                  name: "最大值",
                },
              ],
            },
            areaStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(237, 152, 7, 0.4)",
                },
                {
                  offset: 1,
                  color: "rgba(237, 152, 7, 0)",
                },
              ]),
            },
            data: data,
            type: "line",
          },
        ],
      };
      breathChart.setOption(option);
    },
    //睡眠质量
    initSleep(data) {
      const that = this;
      Highcharts.chart("sleepEcharts", {
        chart: {
          type: "xrange",
          marginTop: "-60",
        },
        title: {
          text: null,
        },
        credits: {
          enabled: false,
        },
        xAxis: {
          visible: false,
        },
        yAxis: {
          title: {
            text: "",
          },
          labels: {
            enabled: false,
          },
        },
        tooltip: {
          show: false,
          formatter: function () {
            return (
              that.formatDate(this.x, "yyyy-MM-dd hh:mm:ss") +
              "-" +
              that.formatDate(this.x2, "yyyy-MM-dd hh:mm:ss")
            );
          },
        },
        series: [
          {
            name: "睡眠情况",
            borderColor: "transparent",
            borderRadius: 0,
            pointWidth: 20,
            data: data,
            dataLabels: {
              enabled: true,
            },
          },
        ],
      });
    },
    formatDate(time, format) {
      var dates = new Date(Number(time));
      var date = {
        "M+": dates.getMonth() + 1,
        "d+": dates.getDate(),
        "h+": dates.getHours(),
        "m+": dates.getMinutes(),
        "s+": dates.getSeconds(),
        "q+": Math.floor((dates.getMonth() + 3) / 3),
        "S+": dates.getMilliseconds(),
      };
      if (/(y+)/i.test(format)) {
        format = format.replace(
          RegExp.$1,
          (dates.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      for (var k in date) {
        if (new RegExp("(" + k + ")").test(format)) {
          format = format.replace(
            RegExp.$1,
            RegExp.$1.length == 1
              ? date[k]
              : ("00" + date[k]).substr(("" + date[k]).length)
          );
        }
      }
      return format;
    },
    //获取2g健康报告详情
    get2GReportDetail() {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      this.$axios
        .get(
          "https://health.ruinetworks.com/api/health/lite2g/reportSenvivData",
          {
            params: {
              reportId: this.$route.query.id,
            },
            headers: {
              user_token: this.$route.query.token,
            },
          }
        )
        .then((res) => {
          Toast.clear();
          if (res.data.success == "true") {
            const reportDetail = res.data.data;
            this.reportTime = reportDetail.createTime;
            this.userName = reportDetail.cwXm;
            this.userAvatar = reportDetail.wechatAvatarUrl;
            this.reportDetail.breathInfo =
              reportDetail.reportData.reportDetailInfo.breathInfo;
            this.reportDetail.HRV =
              reportDetail.reportData.reportDetailInfo.HRV;
            this.reportDetail.heartBeatInfo =
              reportDetail.reportData.reportDetailInfo.heartBeatInfo;
            this.reportDetail.sleepInfo =
              reportDetail.reportData.reportDetailInfo.sleepInfo;
            this.reportDetail.reportInfo = reportDetail.reportData.reportInfo;


            const analyseInfo = reportDetail.reportData.suggestInfo.analyseInfo;
            analyseInfo.forEach((el,index) => {
              if(el.type == '免疫力指数') {
                this.mianyiDetail.mianyili = el.score;
              }
              if(el.type == '感染风险') {
                this.mianyiDetail.ganran = el.score;
              }
            })
            

            //处理图表
            //睡眠情况
            let sleepData = reportDetail.reportData.reportInfo.sleepCharts;
            let sleepChartsData = [];
            sleepData.forEach((item) => {
              if (item.y == 3) {
                // 离床
                var _color = "#FFDF24";
              } else if (item.y == 0) {
                // 清醒
                var _color = "#D8EDFF";
              } else if (item.y == 4) {
                // rem
                var _color = "#E6AFFD";
              } else if (item.y == 1) {
                // 浅睡
                var _color = "#A9DDFD";
              } else if (item.y == 6) {
                var _color = "#A9DDFD";
              } else if (item.y == 5) {
                // 深睡
                var _color = "#4C6CE5";
              }
              sleepChartsData.push({
                x: item.x,
                x2: item.x2,
                y: 0,
                color: _color,
              });
            });
            this.initSleep(sleepChartsData);

            //呼吸情况
            const breathxData =
              reportDetail.reportData.reportInfo.breathCharts.time;
            const breathyData =
              reportDetail.reportData.reportInfo.breathCharts.value;
            this.initBreathLine(breathxData, breathyData);

            //心率情况
            const heartxData =
              reportDetail.reportData.reportInfo.heartBeatCharts.time;
            const heartyData =
              reportDetail.reportData.reportInfo.heartBeatCharts.value;
            this.initHeartLine(heartxData, heartyData);
          } else {
            Toast(res.data.data.msg);
          }
        });
    },
    //获取标注详情
    handleClickDetail(title) {
      this.actionTitle = title;
      this.isActionShow = true;
      this.actionText = baseConfig[title].text;
    },
  },
  mounted() {
    //获取报告详情
    this.get2GReportDetail();
    // this.getArticleList();
  },
};
</script>

<style lang="less" scoped>
.main-container {
  .banner-wrapper {
    position: relative;
    height: 120px;
    background: linear-gradient(180deg, #307B62 0%, #48C285 100%);
    border-radius: 0 0 30px 30px;
    padding: 10px 24px;
    .title {
      color: #fff;
      font-size: 22px;
      font-weight: 500;
    }
    .report-time {
      font-size: 14px;
      color: #fff;
      font-weight: 400;
      line-height: 36px;
    }
    .user-info {
      position: absolute;
      right: 20px;
      top: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .avatar-img {
        width: 38px;
        height: 38px;
        border-radius: 8px;
        background: #f2f2f2;
        overflow: hidden;
        > img {
          width: 100%;
          height: 100%;
        }
      }
      .user-name {
        margin-top: 6px;
        text-align: center;
        color: #fff;
        font-size: 18px;
        font-weight: 500;
      }
    }
  }
  .report-wrapper {
    position: relative;
    margin-top: -80px;
    padding: 15px;
    .health-guide {
      text-align: center;
      font-size: 12px;
      line-height: 36px;
      color: #fff;
    }
    .report-detail {
      padding: 10px;
      background: #fff;
      box-shadow: 0px 4px 12px 0px rgba(229, 229, 229, 0.5);
      border-radius: 12px;
      margin-bottom: 16px;
      .title {
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 16px;
        .title-icon {
          width: 24px;
          margin-right: 10px;
        }
        .health-score {
          position: absolute;
          right: 0;
          font-weight: 600;
          color: #3d854d;
        }
      }
      .chart-wrapper {
        height: 220px;
      }
      .time-range {
        position: absolute;
        width: 100%;
        top: 80px;
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        color: #9395a3;
      }
      .chart-legend {
        position: absolute;
        width: 100%;
        top: 150px;
        display: flex;
        justify-content: space-around;
        .legend-item {
          font-size: 12px;
          display: flex;
          align-items: center;
          .circle {
            width: 8px;
            height: 8px;
            border-radius: 4px;
            margin-right: 4px;
          }
        }
      }
      .item {
        // background: #effbfc;
        background: #f8f8f8;
        margin-bottom: 16px;
        border-radius: 10px;
        padding: 12px 12px 4px;
        &:last-child {
          margin-bottom: 0;
        }
        h3 {
          font-size: 18px;
          color: #272c47;
          font-weight: 500;
          line-height: 38px;
          display: flex;
          align-items: center;
          border-bottom: 2px solid #afeaee;
          .img-icon {
            width: 24px;
            margin-right: 6px;
          }
          &.sleep-time {
            border-bottom: 0;
            position: relative;
            > span {
              position: absolute;
              right: 10px;
              // color: #2ccbd6;
              color: #3d854d;
              font-size: 20px;
            }
          }
        }
        > p {
          display: flex;
          line-height: 36px;
          font-size: 16px;
          position: relative;
          > span {
            // color: #2ccbd6;
            color: #3d854d;
            &:first-child {
              color: #272c47;
              width: 180px;
              white-space: nowrap;
            }
            &.base-value {
              color: #f1621d;
              font-weight: 500;
            }
          }
          .arrow {
            font-style: normal;
            font-size: 12px;
            position: absolute;
            right: -2px;
          }
        }
        &.hrv {
          > p {
            > span {
              &:first-child {
                width: 200px;
              }
            }
          }
        }
      }
    }
  }
}
.mr4 {
  margin-right: 4px;
}
.action-content {
  font-size: 14px;
  padding: 10px;
  text-indent: 2em;
}
</style>
