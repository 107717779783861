<template>
  <div class="main-container">
    <!-- banner -->
    <div class="banner-wrapper">
      <h2 class="title">向日葵30天健康检测报告</h2>
      <p class="report-time">{{ reportTime }}</p>
      <div class="user-info">
        <div class="avatar-img">
          <img :src="require('@/assets/images/logo.png')" alt="" />
        </div>
        <p class="user-name">{{ reportData.userName }}</p>
      </div>
    </div>
    <div class="report-wrapper">
      <div class="health-guide">-本次报告不作为疾病的专业临床诊断依据-</div>
      <!-- 睡眠情况 -->
      <div class="report-detail">
        <div class="sleep-grid_wrapper">
          <div class="sleep-item">
            <p class="score">{{ reportData.healthValueAvg }}分</p>
            <div class="imgs">
              <img :src="require('@/assets/images/14Date/health.png')" alt="" />
            </div>
            <p class="label">平均健康值</p>
          </div>
          <div class="sleep-item">
            <p class="score">{{ reportData.sleepTimeAvg }}</p>
            <div class="imgs">
              <img :src="require('@/assets/images/14Date/sleep.png')" alt="" />
            </div>
            <p class="label">平均睡眠时长</p>
          </div>
          <div class="sleep-item">
            <p class="score">{{ reportData.referenceHeartRateAvg }}次/分</p>
            <div class="imgs">
              <img :src="require('@/assets/images/14Date/xinlv.png')" alt="" />
            </div>
            <p class="label">平均基准心率</p>
          </div>
        </div>
        <!-- chart -->
        <div id="chart1" class="health-chart"></div>
      </div>
      <!-- 睡眠结构 -->
      <div class="report-detail">
        <div class="title">
          <img
            :src="require('@/assets/images/14Date/sleep-icon.png')"
            class="title-icon"
          />睡眠结构
        </div>
        <div class="chart-wrapper">
          <div class="bar-chart" id="sleepChart"></div>
          <div class="legend">
            <p>
              <span class="circle" style="background: #93e6fd"></span>
              <span class="label">平均浅睡</span>
              <span class="count"
                >{{ reportData.sleepStructure.lightSleepAvg
                }}<i class="unit">%</i></span
              >
            </p>
            <p>
              <span class="circle" style="background: #d48eed"></span>
              <span class="label">平均REM</span>
              <span class="count"
                >{{ reportData.sleepStructure.REMAvg
                }}<i class="unit">%</i></span
              >
            </p>
            <p>
              <span class="circle" style="background: #1b90ff"></span>
              <span class="label">平均深睡</span>
              <span class="count"
                >{{ reportData.sleepStructure.deepSleepAvg
                }}<i class="unit">%</i></span
              >
            </p>
            <p>
              <span class="circle" style="background: #87e673"></span>
              <span class="label">平均清醒</span>
              <span class="count"
                >{{ reportData.sleepStructure.soberAvg
                }}<i class="unit">%</i></span
              >
            </p>
          </div>
        </div>
        <p class="review">
          - <span>监测周期睡眠平均值为{{ reportData.sleepValue }}分</span> -
        </p>
      </div>
      <!-- 情绪指标 -->
      <div class="report-detail">
        <div class="title">
          <img
            :src="require('@/assets/images/14Date/heart-icon.png')"
            class="title-icon"
          />情绪指标
        </div>
        <!-- 情绪焦虑 -->
        <div class="bar-progress">
          <p class="label">
            <span>情绪焦虑</span>
            <span>{{ reportData.emotionalIndicators.anxiousAvg }}</span>
          </p>
          <van-progress
            stroke-width="10"
            :show-pivot="false"
            :percentage="reportData.emotionalIndicators.anxiousAvg"
            :pivot-text="null"
            pivot-color="#7232dd"
            color="linear-gradient(to right, rgba(110, 152, 250, 0.40), rgba(110, 152, 250, 0.99))"
          />
        </div>
        <div class="bar-progress">
          <p class="label">
            <span>抗压能力</span>
            <span>{{ reportData.emotionalIndicators.compressiveAvg }}</span>
          </p>
          <van-progress
            stroke-width="10"
            :show-pivot="false"
            :percentage="reportData.emotionalIndicators.compressiveAvg"
            :pivot-text="null"
            pivot-color="#7232dd"
            color="linear-gradient(to right, rgba(99, 226, 253, 0.40), rgba(99, 226, 253, 1))"
          />
        </div>
        <p class="review">
          - <span>监测周期</span>
          <span
            v-if="
              reportData.emotionalIndicators.anxiousAvg1 >= 0 &&
              reportData.emotionalIndicators.anxiousAvg1 < 40
            "
            >轻度焦虑，</span
          >
          <span
            v-if="
              reportData.emotionalIndicators.anxiousAvg1 >= 40 &&
              reportData.emotionalIndicators.anxiousAvg1 < 70
            "
            >中度焦虑，</span
          >
          <span v-if="reportData.emotionalIndicators.anxiousAvg1 >= 70"
            >重度焦虑，</span
          >
          <span v-if="reportData.emotionalIndicators.compressiveAvg >= 70"
            >抗压正常</span
          >
          <span
            v-if="
              reportData.emotionalIndicators.compressiveAvg >= 30 &&
              reportData.emotionalIndicators.compressiveAvg < 70
            "
            >抗压异常</span
          >
          <span
            v-if="
              reportData.emotionalIndicators.compressiveAvg > 0 &&
              reportData.emotionalIndicators.compressiveAvg < 30
            "
            >抗压严重异常</span
          >
          -
        </p>
      </div>
      <!-- 呼吸指标 -->
      <div class="report-detail">
        <div class="title">
          <img
            :src="require('@/assets/images/14Date/breathe-icon.png')"
            class="title-icon"
          />呼吸指标
        </div>
        <div class="bar-progress">
          <p class="label">
            <span>低通气</span>
            <span>{{ reportData.respiratoryIndex.hypoventilationAvg }}</span>
          </p>
          <van-progress
            stroke-width="10"
            :show-pivot="false"
            :percentage="reportData.respiratoryIndex.hypoventilationAvg"
            :pivot-text="null"
            pivot-color="#7232dd"
            color="linear-gradient(to right, rgba(130, 88, 247, 0.40), rgba(130, 88, 247, 1))"
          />
        </div>
        <div class="bar-progress">
          <p class="label">
            <span>AHI</span>
            <span>{{ reportData.respiratoryIndex.ahiAvg }}</span>
          </p>
          <van-progress
            stroke-width="10"
            :show-pivot="false"
            :percentage="reportData.respiratoryIndex.ahiAvg"
            :pivot-text="null"
            pivot-color="#7232dd"
            color="linear-gradient(to right, rgba(53, 216, 231, 0.40), rgba(53, 216, 231, 1))"
          />
        </div>
        <div class="bar-progress">
          <p class="label">
            <span>呼吸暂停</span>
            <span>{{ reportData.respiratoryIndex.suspendAvg }}</span>
          </p>
          <van-progress
            stroke-width="10"
            :show-pivot="false"
            :percentage="reportData.respiratoryIndex.suspendAvg"
            :pivot-text="null"
            pivot-color="#7232dd"
            color="linear-gradient(to right, rgba(161, 230, 126, 0.40), rgba(161, 230, 126, 1))"
          />
        </div>
        <p class="review">
          -
          <span
            v-if="
              reportData.respiratoryIndex.ahiAvg > 0 &&
              reportData.respiratoryIndex.ahiAvg < 5
            "
            >监测周期呼吸正常</span
          >
          <span
            v-if="
              reportData.respiratoryIndex.ahiAvg >= 5 &&
              reportData.respiratoryIndex.ahiAvg < 15
            "
            >监测周期轻度呼吸暂停</span
          >
          <span
            v-if="
              reportData.respiratoryIndex.ahiAvg >= 15 &&
              reportData.respiratoryIndex.ahiAvg < 30
            "
            >监测周期中度呼吸暂停</span
          >
          <span v-if="reportData.respiratoryIndex.ahiAvg >= 30"
            >监测周期重度呼吸暂停</span
          >
          -
        </p>
      </div>
      <!-- 慢性管控数据及趋势变化 -->
      <div class="report-detail">
        <div class="title">
          <img
            :src="require('@/assets/images/14Date/chronic-icon.png')"
            class="title-icon"
          />慢病管控数据及趋势变化
        </div>
        <div class="chronic-chart" id="chronicEcharts"></div>
        <div class="warning-alert" v-if="errorNotice1.length != 5">
          {{ errorNotice1 }}
        </div>
      </div>
      <!-- 身体机能监测数据 -->
      <div class="report-detail">
        <div class="title">
          <img
            :src="require('@/assets/images/14Date/body-icon.png')"
            class="title-icon"
          />身体机能监测数据
        </div>
        <div class="body-chart" id="bodyEcharts"></div>
        <h3 class="sm-title">自主神经平衡指数监测数据</h3>
        <table class="main-table">
          <thead>
            <tr>
              <th>日期</th>
              <th>自主神经平衡</th>
              <th>基准值</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in reportData.data14" :key="index">
              <td>{{ item | formatDate }}</td>
              <td
                :class="{
                  fontBlod: true,
                  yellow:
                    (reportData.autonomicBalance[index] >= 0.5 &&
                      reportData.autonomicBalance[index] < 0.5) ||
                    (reportData.autonomicBalance[index] >= 1.2 &&
                      reportData.autonomicBalance[index] < 1.5),
                  red:
                    (reportData.autonomicBalance[index] > 0 &&
                      reportData.autonomicBalance[index] < 0.5) ||
                    reportData.autonomicBalance[index] >= 2.5,
                }"
              >
                {{ reportData.autonomicBalance[index] }}
              </td>
              <td>{{ reportData.autonomicBalanceBase[index] }}</td>
            </tr>
          </tbody>
        </table>
        <h3 class="sm-title">心率减速力指数监测数据</h3>
        <table class="main-table">
          <thead>
            <tr>
              <th>日期</th>
              <th>心率减速力</th>
              <th>基准值</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in reportData.data14" :key="index">
              <td>{{ item | formatDate }}</td>
              <td
                :class="{
                  fontBlod: true,
                  yellow:
                    (reportData.heartRateAvg >= 2.5 &&
                      reportData.heartRate[index] < 4.6) ||
                    (reportData.heartRate[index] >= 12 &&
                      reportData.heartRate[index] < 20),
                  red:
                    (reportData.heartRate[index] > 0 &&
                      reportData.heartRate[index] < 2.5) ||
                    reportData.heartRate[index] >= 20,
                }"
              >
                {{ reportData.heartRate[index] }}
              </td>
              <td>[4.6,12)</td>
            </tr>
          </tbody>
        </table>
        <div class="warning-alert" v-if="errorNotice2.length != 5">
          {{ errorNotice2 }}
        </div>
      </div>
      <!-- 报告提示 -->
      <div class="report-detail">
        <div class="title">
          <img
            :src="require('@/assets/images/14Date/report-icon.png')"
            class="title-icon"
          />报告提示
        </div>
        <div class="grid-content">
          <div class="column-item">
            <h4>异常提示</h4>
            <p
              class="flex-row"
              v-for="(item, index) in errorNoticeList"
              :key="index"
            >
              <span>{{ item.means }}</span>
              <span>{{ item.value }}</span>
            </p>
          </div>
          <div class="column-item">
            <h4>优秀提示</h4>
            <p
              class="flex-row"
              v-for="(item, index) in goodNoticeList"
              :key="index"
            >
              <span>{{ item.means }}</span>
              <span>{{ item.value }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script>
import dayjs from "dayjs";
import Vue from "vue";
import { Icon, Toast, ActionSheet, Progress } from "vant";

Vue.use(Icon);
Vue.use(Toast);
Vue.use(ActionSheet);
Vue.use(Progress);
export default {
  name: "Report30Detail",
  data() {
    return {
      errorNotice1: "",
      errorNotice2: "",
      reportTime: "",
      reportData: {
        userName: "",
        age: "",
        sex: "",
        fileNo: "", //档案号
        date: "", //日期
        basicCase: "", //基础病例
        healthValueAvg: "", //平均健康值
        sleepTimeAvg: "", //平均睡眠时间
        sleepValue: "", //睡眠值
        referenceHeartRateAvg: "", //平均基准心率
        healthValue14Date: [], //健康值
        sleepTime14Date: [], //睡眠时间
        referenceHeartRate14Date: [], //基准心率
        data14: [], //14天数据
        goodNotice: [],
        errorNotice: [],
        sleepStructure: {
          //睡眠结构占比
          deepSleepAvg: "", //深度睡眠14天百分比
          lightSleepAvg: "", //浅度睡眠14天百分比
          soberAvg: "", //清醒14天百分比
          REMAvg: "", //rem14天百分比
        },
        emotionalIndicators: {
          //情绪指标
          anxiousAvg: "", //情绪焦虑
          compressiveAvg: "", //抗压能力
        },
        respiratoryIndex: {
          //呼吸指标
          suspendAvg: "", //呼吸暂停
          hypoventilationAvg: "", //低通气
          ahiAvg: "", //AHI
        },
        immunityIndex: [], //免疫力指数14天数据
        immunityIndexAvg: "", //免疫力指数14天数据平均值
        riskOfInfection: [], //感染风险14天数据
        riskOfInfectionAvg: "", //感染风险14天数据平均值
        hypertensionControl: [], //高血压管控14天数据
        hypertensionControlAvg: "", //高血压管控14天数据平均值
        coronaryHeartDiseaseControl: [], //冠心病管控14天数据
        coronaryHeartDiseaseControlAvg: "", //冠心病管控14天数据平均值,
        arrhythmia: [], //心率失常14天数据
        arrhythmiaAvg: "", //心率失常14天数据平均值
        totalCardiacEnergy: [], //心脏总能量14天数据
        totalCardiacEnergyBase: [], //心脏总能量基准值14天数据
        totalCardiacEnergyAvg: "", //脏总能量14天数据平均值
        sympatheticTension: [], //交感神经张力14天数据
        sympatheticTensionBase: [], //交感神经张力基准值14天数据
        sympatheticTensionAvg: "", //交感神经张力14天数据平均值
        vagalTension: [], //迷走神经张力14天数据
        vagalTensionBase: [], //迷走神经张力基准值14天数据
        vagalTensionAvg: "", //迷走神经张力14天数据平均值
        autonomicBalance: [], //自主神经平衡14天数据
        autonomicBalanceBase: [], //自主神经平衡基准值14天数据
        autonomicBalanceAvg: "", //自主神经平衡14天数据平均值
        heartRate: [], //心率减速力14天数据
        heartRateAvg: "", //心率减速力14天数据平均值
      },
    };
  },
  filters: {
    formatDate: function (str) {
      return dayjs(str).format("MM.DD");
    },
  },
  computed: {
    errorNoticeList: function () {
      return this.reportData.errorNotice.filter((el) => el.value > 1);
    },
    goodNoticeList: function () {
      return this.reportData.goodNotice.filter((el) => el.value > 0);
    },
  },
  methods: {
    // 健康与睡眠折线图
    chart1() {
      let chart1 = this.$echarts.init(document.getElementById("chart1"));

      let option = {
        grid: {
          top: 40,
          left: 0,
          right: 0,
          bottom: 40,
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "line",
            label: {
              show: true,
            },
          },
        },
        legend: {
          data: ["健康值", "睡眠时间", "基准心率"],
          bottom: "10px",
          textStyle: {
            color: "#272C47",
            fontSize: 12,
          },
        },
        xAxis: {
          data: this.reportData.data14,
          axisLine: {
            show: true, //隐藏X轴轴线
            lineStyle: {
              color: "#E0E0E0",
              width: 2,
            },
          },
          axisTick: {
            show: false, //隐藏X轴刻度
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#81828F", //X轴文字颜色
            },
            formatter: function (params) {
              return dayjs(params).format("MM-DD");
            },
          },
        },
        yAxis: [
          {
            type: "value",
            name: "健康值/分",
            nameTextStyle: {
              fontSize: 11,
              color: "#8A8B99",
            },
            gridIndex: 0,
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                width: 1,
              },
            },
            axisTick: {
              show: true,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#C3CCCB",
                width: 1,
              },
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#81828F",
              },
            },
          },
          {
            type: "value",
            name: "睡眠时间(天)/时",
            nameTextStyle: {
              fontSize: 11,
              color: "#8A8B99",
              padding: [0, 10, 0, 0],
            },
            position: "right",
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#C3CCCB",
                width: 1,
              },
            },
            axisLabel: {
              show: true,
              formatter: "{value}:00", //右侧Y轴文字显示
              textStyle: {
                color: "#81828F",
              },
            },
          },
          {
            type: "value",

            min: 50,
            max: 100,
            splitNumber: 8,
            splitLine: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            splitArea: {
              show: true,
              areaStyle: {
                color: ["rgba(250,250,250,0.0)", "rgba(250,250,250,0.05)"],
              },
            },
          },
        ],
        series: [
          {
            name: "健康值",
            type: "line",
            yAxisIndex: 0, //使用的 y 轴的 index，在单个图表实例中存在多个 y轴的时候有用
            smooth: true, //平滑曲线显示
            symbolSize: 0, //标记的大小
            itemStyle: {
              show: false,
            },
            lineStyle: {
              color: "#2CCBD6",
            },
            color: "#2CCBD6",
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgba(44,203,214,0.7)",
                    },
                    {
                      offset: 0.4,
                      color: "rgba(44,203,214,0)",
                    },
                  ],
                  false
                ),
                // shadowColor: 'rgba(108,80,243, 0.9)',
                // shadowBlur: 20
              },
            },
            data: this.reportData.healthValue14Date,
          },
          {
            name: "睡眠时间",
            type: "bar",
            barWidth: 6,
            yAxisIndex: 1,
            itemStyle: {
              normal: {
                color: "#2DCBD6",
                // color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                //   {
                //     offset: 0,
                //     color: "#2DCBD6",
                //   },
                //   {
                //     offset: 1,
                //     color: "#E0FDFF",
                //   },
                // ]),
                barBorderRadius: [10, 10, 0, 0],
              },
            },
            data: this.reportData.sleepTime14Date,
          },
          {
            name: "基准心率",
            type: "line",
            yAxisIndex: 0, //使用的 y 轴的 index，在单个图表实例中存在多个 y轴的时候有用
            smooth: true, //平滑曲线显示
            symbolSize: 0, //标记的大小
            itemStyle: {
              show: false,
            },
            lineStyle: {
              color: "#FFB400",
            },
            color: "#FFB400",
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgba(255, 180, 0,0.7)",
                    },
                    {
                      offset: 0.4,
                      color: "rgba(255, 180, 0,0)",
                    },
                  ],
                  false
                ),
                // shadowColor: 'rgba(108,80,243, 0.9)',
                // shadowBlur: 20
              },
            },
            data: this.reportData.referenceHeartRate14Date,
          },
        ],
      };
      chart1.setOption(option);
    },
    // 睡眠结构
    initSleepQuality() {
      let barChart = this.$echarts.init(document.getElementById("sleepChart"));
      let option = {
        tooltip: {
          trigger: "item",
        },
        legend: {
          show: false,
        },
        series: [
          {
            name: "睡眠结构",
            type: "pie",
            center: ["45%", "50%"],
            radius: ["40%", "80%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "16",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              {
                value: this.reportData.sleepStructure.lightSleepAvg,
                name: "浅度睡眠",
                itemStyle: { color: "#93E6FD" },
              },
              {
                value: this.reportData.sleepStructure.deepSleepAvg,
                name: "深度睡眠",
                itemStyle: { color: "#1B90FF" },
              },
              {
                value: this.reportData.sleepStructure.soberAvg,
                name: "清醒",
                itemStyle: { color: "#87E673" },
              },
              {
                value: this.reportData.sleepStructure.REMAvg,
                name: "REM",
                itemStyle: { color: "#D48EED" },
              },
            ],
          },
        ],
      };
      barChart.setOption(option);
    },
    //慢病管控数据及趋势变化折线图哦
    initChronicEcharts() {
      let chronicEcharts = this.$echarts.init(
        document.getElementById("chronicEcharts")
      );
      let option = {
        legend: {
          show: true,
          bottom: 0,
        },
        grid: {
          top: 10,
          left: 0,
          right: 0,
          bottom: 60,
          containLabel: true,
        },
        tooltip: {
          show: true,
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          data: this.reportData.data14,
          axisTick: {
            show: false, //隐藏X轴刻度
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#55596B", //X轴文字颜色
            },
            formatter: function (params) {
              return dayjs(params).format("MM-DD");
            },
          },
          axisLine: {
            show: true, //隐藏X轴轴线
            lineStyle: {
              color: "#E0E0E0",
              width: 2,
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              width: 1,
            },
          },
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              width: 1,
            },
          },
          axisTick: {
            show: true,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#E0E0E0",
              width: 2,
            },
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#61616B",
            },
          },
        },
        series: [
          {
            name: "免疫力指数",
            color: "#F9A700",
            smooth: true,
            symbol: "circle",
            symbolSize: 8,
            markPoint: {
              label: {
                normal: {
                  textStyle: {
                    color: "#fff",
                  },
                },
              },
            },
            data: this.reportData.immunityIndex,
            type: "line",
          },
          {
            name: "感染风险",
            color: "#2DCBD6",
            smooth: true,
            symbol: "circle",
            symbolSize: 8,
            markPoint: {
              label: {
                normal: {
                  textStyle: {
                    color: "#fff",
                  },
                },
              },
            },
            data: this.reportData.riskOfInfection,
            type: "line",
          },
          {
            name: "高血压管控",
            color: "#FD0A6E",
            smooth: true,
            symbol: "circle",
            symbolSize: 8,
            markPoint: {
              label: {
                normal: {
                  textStyle: {
                    color: "#fff",
                  },
                },
              },
            },
            data: this.reportData.hypertensionControl,
            type: "line",
          },
          {
            name: "冠心病管控",
            color: "#5995F0",
            smooth: true,
            symbol: "circle",
            symbolSize: 8,
            markPoint: {
              label: {
                normal: {
                  textStyle: {
                    color: "#fff",
                  },
                },
              },
            },
            data: this.reportData.coronaryHeartDiseaseControl,
            type: "line",
          },
          {
            name: "心率失常",
            color: "#D784F5",
            smooth: true,
            symbol: "circle",
            symbolSize: 8,
            markPoint: {
              label: {
                normal: {
                  textStyle: {
                    color: "#fff",
                  },
                },
              },
            },
            data: this.reportData.arrhythmia,
            type: "line",
          },
        ],
      };
      chronicEcharts.setOption(option);
    },
    //身体机能监测数据
    initBodyChart() {
      let bodyEcharts = this.$echarts.init(
        document.getElementById("bodyEcharts")
      );
      let option = {
        legend: {
          show: true,
          bottom: 0,
        },
        grid: {
          top: 30,
          left: 0,
          right: 0,
          bottom: 50,
          containLabel: true,
        },
        tooltip: {
          show: true,
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          data: this.reportData.data14,
          axisTick: {
            show: false, //隐藏X轴刻度
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#55596B", //X轴文字颜色
            },
            formatter: function (params) {
              return dayjs(params).format("MM-DD");
            },
          },
          axisLine: {
            show: true, //隐藏X轴轴线
            lineStyle: {
              color: "#E0E0E0",
              width: 2,
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              width: 1,
            },
          },
        },
        yAxis: [
          {
            type: "value",
            name: "其他体征参数",
            min: -2400,
            gridIndex: 0,
            nameTextStyle: {
              fontSize: 11,
              color: "#8A8B99",
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                width: 1,
              },
            },
            axisTick: {
              show: false,
            },
            interval: 800,
            axisLine: {
              show: true,
              lineStyle: {
                color: "#E0E0E0",
                width: 2,
              },
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#61616B",
              },
              formatter: function (value) {
                return value >= 0 ? value : "";
              },
            },
          },
          {
            type: "value",
            name: "心脏总能量",
            nameTextStyle: {
              fontSize: 11,
              color: "#8A8B99",
            },
            // max:8400,
            position: "right",
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#E0E0E0",
                width: 2,
              },
            },
            interval: 400,
            axisLabel: {
              show: true,
              formatter: "{value}", //右侧Y轴文字显示
              textStyle: {
                color: "#61616B",
              },
            },
          },
        ],
        series: [
          {
            name: "心脏总能量",
            color: "#F9A700",
            smooth: true,
            yAxisIndex: 1,
            symbol: "circle",
            symbolSize: 8,
            markPoint: {
              label: {
                normal: {
                  textStyle: {
                    color: "#fff",
                  },
                },
              },
            },
            data: this.reportData.totalCardiacEnergy,
            type: "line",
          },
          {
            name: "交感神经张力",
            color: "#39CED8",
            smooth: true,
            symbol: "circle",
            symbolSize: 8,

            markPoint: {
              label: {
                normal: {
                  textStyle: {
                    color: "#fff",
                  },
                },
              },
            },
            data: this.reportData.sympatheticTension,
            type: "line",
          },
          {
            name: "迷走神经张力",
            color: "#7EE6AB",
            smooth: true,
            symbol: "circle",
            symbolSize: 8,

            markPoint: {
              label: {
                normal: {
                  textStyle: {
                    color: "#fff",
                  },
                },
              },
            },
            data: this.reportData.vagalTension,
            type: "line",
          },
        ],
      };
      bodyEcharts.setOption(option);
    },
    //获取4g健康报告30天详情
    get4GReportDetail() {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      this.$axios
        .get(
          "https://health.ruinetworks.com/report14/health/monthData/liteDetail",
          {
            params: {
              id: this.$route.query.id,
              // id: "368a0e34-6f8f-45a7-90c6-06ab40bcb56f"
            },
            headers: {
              user_token: this.$route.query.token,
            },
          }
        )
        .then((res) => {
          Toast.clear();
          if (res.data.success == "true") {
            const reportDetail = res.data.data;
            const reportDetailContent = JSON.parse(reportDetail.content);
            console.log(reportDetailContent);
            this.reportData = reportDetailContent;
            this.reportTime = `${dayjs(reportDetailContent.data14[0]).format(
              "YYYY年MM月DD日"
            )}-${dayjs(
              reportDetailContent.data14[reportDetailContent.data14.length - 1]
            ).format("YYYY年MM月DD日")}`;

            this.chart1();
            this.initSleepQuality();
            this.initChronicEcharts();
            this.initBodyChart();

            let mylzs = 0;
            this.reportData.immunityIndex.forEach((el) => {
              if (el > 0 && el < 50) {
                mylzs++;
              }
            });

            let grfx = 0;
            this.reportData.riskOfInfection.forEach((el) => {
              if (el >= 70) {
                grfx++;
              }
            });

            let gxygk = 0;
            this.reportData.hypertensionControl.forEach((el) => {
              if (el > 0 && el < 40) {
                gxygk++;
              }
            });

            let gxbgk = 0;
            this.reportData.coronaryHeartDiseaseControl.forEach((el) => {
              if (el > 0 && el < 40) {
                gxbgk++;
              }
            });

            let tnbgk = 0;
            this.reportData.coronaryHeartDiseaseControl.forEach((el) => {
              if (el > 0 && el < 40) {
                tnbgk++;
              }
            });

            let xlsc = 0;
            this.reportData.arrhythmia.forEach((el) => {
              if ((el > 0 && el < 30) || el >= 220) {
                xlsc++;
              }
            });

            let xzznl = 0;
            this.reportData.totalCardiacEnergy.forEach((el) => {
              if ((el > 0 && el < 1000) || el >= 10000) {
                xzznl++;
              }
            });

            let jgsjzl = 0;
            this.reportData.sympatheticTension.forEach((el) => {
              if ((el > 0 && el < 300) || el >= 3000) {
                jgsjzl++;
              }
            });

            let mzsjzl = 0;
            this.reportData.vagalTension.forEach((el) => {
              if ((el > 0 && el < 300) || el >= 3000) {
                mzsjzl++;
              }
            });

            let zzsjph = 0;
            this.reportData.autonomicBalance.forEach((el) => {
              if ((el > 0 && el < 0.5) || el >= 2.5) {
                zzsjph++;
              }
            });

            let xljsl = 0;
            this.reportData.heartRate.forEach((el) => {
              if ((el > 0 && el < 2.5) || el >= 20) {
                xljsl++;
              }
            });

            this.errorNotice1 = "异常提醒：";
            if (mylzs > 1)
              this.errorNotice1 += "免疫力指数异常" + mylzs + "次,";
            if (grfx > 1) this.errorNotice1 += "感染风险异常" + grfx + "次,";
            if (gxygk > 1)
              this.errorNotice1 += "高血压管控异常" + gxygk + "次,";
            if (gxbgk > 1)
              this.errorNotice1 += "冠心病管控异常" + gxbgk + "次,";
            if (tnbgk > 1)
              this.errorNotice1 += "糖尿病管控异常" + tnbgk + "次,";
            if (xlsc > 1) this.errorNotice1 += "心率失常异常" + xlsc + "次,";
            if (this.errorNotice1.length != 5)
              this.errorNotice1 = this.errorNotice1.substring(
                0,
                this.errorNotice1.length - 1
              );

            this.errorNotice2 = "异常提醒：";
            if (xzznl > 1)
              this.errorNotice2 += "心脏总能量异常" + xzznl + "次,";
            if (jgsjzl > 1)
              this.errorNotice2 += "交感神经张力异常" + jgsjzl + "次,";
            if (mzsjzl > 1)
              this.errorNotice2 += "迷走神经张力异常" + mzsjzl + "次,";
            if (zzsjph > 1)
              this.errorNotice2 += "自主神经平衡异常" + zzsjph + "次,";
            if (xljsl > 1)
              this.errorNotice2 += "心率减速力异常" + xljsl + "次,";
            if (this.errorNotice2.length != 5)
              this.errorNotice2 = this.errorNotice2.substring(
                0,
                this.errorNotice2.length - 1
              );
          } else {
            Toast(res.data.data.msg);
          }
        });
    },
  },
  mounted() {
    //获取4g健康报告详情
    this.get4GReportDetail();
  },
};
</script>
    
<style lang="less" scoped>
.main-container {
  .banner-wrapper {
    position: relative;
    height: 340px;
    background: url("~@/assets/images/14Date/banner.png") no-repeat center /
      100% 100%;
    border-radius: 0 0 30px 30px;
    padding: 10px 24px;

    .title {
      color: #fff;
      font-size: 20px;
      font-weight: 500;
    }

    .report-time {
      font-size: 14px;
      color: #fff;
      font-weight: 400;
      line-height: 36px;
    }

    .user-info {
      position: absolute;
      right: 20px;
      top: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .avatar-img {
        width: 38px;
        height: 38px;
        border-radius: 8px;
        background: #f2f2f2;
        overflow: hidden;

        > img {
          width: 100%;
          height: 100%;
        }
      }

      .user-name {
        margin-top: 6px;
        text-align: center;
        color: #fff;
        font-size: 18px;
        font-weight: 500;
      }
    }
  }

  .report-wrapper {
    position: relative;
    margin-top: -280px;
    padding: 15px;

    .health-guide {
      text-align: center;
      font-size: 12px;
      line-height: 36px;
      color: #fff;
    }

    .report-detail {
      padding: 10px;
      background: #fff;
      box-shadow: 0px 4px 12px 0px rgba(229, 229, 229, 0.5);
      border-radius: 12px;
      margin-bottom: 16px;

      .title {
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 16px;

        .title-icon {
          width: 24px;
          margin-right: 10px;
        }

        .health-score {
          position: absolute;
          right: 0;
          font-weight: 600;
          color: #3d854d;
        }
      }

      .sleep-grid_wrapper {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 10px;

        .sleep-item {
          background: #e9faee;
          padding: 6px;

          .score {
            height: 28px;
            line-height: 28px;
            color: #2d8c42;
            font-size: 16px;
            font-weight: 600;
          }

          .imgs {
            height: 50px;
            text-align: center;

            > img {
              width: 100%;
            }
          }

          .label {
            text-align: center;
            color: #579e7b;
            font-weight: 500;
            font-size: 14px;
          }
        }
      }

      .health-chart {
        margin-top: 16px;
        height: 300px;
      }

      .bar-progress {
        margin-bottom: 10px;

        .label {
          display: flex;
          justify-content: space-between;
          font-size: 14px;
          font-weight: 500;
          line-height: 32px;
          color: #272c47;
        }
      }

      .chart-wrapper {
        display: flex;

        .bar-chart {
          width: 180px;
          height: 180px;
        }

        .legend {
          height: 180px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          font-size: 14px;
          font-weight: 500;

          > p {
            display: flex;
            align-items: center;

            .circle {
              width: 8px;
              height: 8px;
              border-radius: 6px;
              margin-right: 4px;
            }

            .label {
              width: 70px;
              color: #272c47;
            }

            .count {
              color: #515566;
              font-weight: 500;
              font-size: 18px;

              .unit {
                font-size: 14px;
              }
            }
          }
        }
      }

      .chronic-chart {
        width: 100%;
        height: 300px;
      }

      .body-chart {
        width: 100%;
        height: 380px;
      }

      .review {
        margin-top: 20px;
        color: #515566;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
      }

      .warning-alert {
        margin-top: 10px;
        padding: 10px;
        font-size: 14px;
        font-weight: 400;
        color: #cc3939;
        background: rgba(229, 87, 87, 0.1);
        border-radius: 10px;
      }

      .sm-title {
        margin-top: 10px;
        line-height: 36px;
        color: #515566;
        font-weight: 600;
        font-size: 16px;
      }

      .main-table {
        table-layout: fixed;
        width: 100%;
        border-collapse: collapse;
        border-radius: 10px;
        overflow: hidden;

        thead {
          tr {
            th {
              padding: 10px;
              background: #e1f5ed;
              color: #177d53;
              font-size: 14px;
              font-weight: 500;
            }
          }
        }

        tbody {
          tr {
            td {
              text-align: center;
              padding: 10px;
              font-size: 14px;
              font-weight: 500;
              color: #5d6175;

              &.fontBlod {
                color: #272c47;
                font-weight: 700;
              }

              &.yellow {
                color: #fbad15;
                font-weight: 700;
              }

              &.red {
                color: #de4f46;
                font-weight: 700;
              }
            }

            &:nth-child(even) {
              td {
                background: #e1f5ed;
              }
            }
          }
        }
      }

      .grid-content {
        background: #f5faf8;
        padding: 10px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;

        .column-item {
          h4 {
            padding: 0 0 10px;
            color: #177d53;
            font-size: 16px;
            font-weight: 500;
          }

          .flex-row {
            line-height: 34px;
            font-size: 14px;
            color: #272c47;
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: space-between;

            > span {
              &:last-child {
                color: #177d53;
                font-weight: 600;
                font-size: 16px;
              }
            }
          }
        }
      }

      .remark-content {
        min-height: 30px;
        background: #f5faf8;
        padding: 10px;
        font-size: 14px !important;
      }
    }
  }
}

.mr4 {
  margin-right: 4px;
}

.action-content {
  font-size: 14px;
  padding: 10px;
  text-indent: 2em;
}
</style>
    